import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import ActionCreator from "../../../src/ActionCreator";
import Selectors from "../../../src/Selectors";
import MomBillingRecordDetailDialog from "./MomBillingRecordDetailDialog";
import ConfirmDialog from "./ConfirmDialog";

class MomBillingRecordList extends React.Component {
  constructor(props) {
    super(props);

    let { spareParts } = this.props;

    this.state = {
      showSpinner: false,
      records: null,
      spareParts: spareParts,
      selectedRecord: null,
      showDetailDialog: false,
      showConfirmDialog: false
    };
  }

  componentDidMount() {
    this._fetchRecords();
  }

  render() {
    let { appActions, mother } = this.props;
    let {
      showSpinner,
      records,
      spareParts,
      selectedRecord,
      showDetailDialog,
      showConfirmDialog
    } = this.state;

    return (
      <Wrapper>
        <Widget.Row>
          <div
            style={{
              display: "flex",
              flex: 1,
              color: "#777777",
              fontWeight: "500",
              padding: 10
            }}
          >
            搜尋結果
          </div>
          <div style={{ margin: 10, display: "flex" }}>
            <Widget.Clickable
              onClick={() =>
                this.setState({ showDetailDialog: true, selectedRecord: null })
              }
            >
              <Widget.Button>新增帳單紀錄</Widget.Button>
            </Widget.Clickable>

            <Widget.Clickable onClick={this._fetchRecords}>
              <Widget.Button className="white-button">重新整理</Widget.Button>
            </Widget.Clickable>
          </div>
        </Widget.Row>

        {!showSpinner && records != null && records.length === 0 && (
          <Widget.HintMessage>尚未建立此客戶相關紀錄!</Widget.HintMessage>
        )}

        {showSpinner ? (
          <Widget.Spinner />
        ) : (
          <Widget.Table>
            <Widget.TableContent>
              <Widget.Fields>
                <div className="field">標題</div>
                <div className="field">價格</div>
                <div className="field">備品名稱</div>
                <div className="field">備品數量</div>
                <div className="field">付款狀態</div>
                <div className="field">備註</div>
                <div className="field-padding" />
                <div className="field" />
                <div className="field" />
              </Widget.Fields>
              {records &&
                spareParts &&
                records.map((record, i) => (
                  <div className={i % 2 === 0 ? "row-even" : "row-odd"}>
                    <div className="row-item">{record.title}</div>
                    <div className="row-item">{record.price}</div>
                    <div className="row-item">
                      <select disabled="disabled">
                        {record.spare_part ? (
                          spareParts.map(sparePart => (
                            <option
                              key={sparePart.id}
                              selected={record.spare_part === sparePart.id}
                            >
                              {sparePart.name}
                            </option>
                          ))
                        ) : (
                          <option>{"-----"}</option>
                        )}
                      </select>
                    </div>
                    <div className="date-item">{record.spare_part_counts}</div>
                    <div className="date-item">
                      {record.is_paid ? "已付款" : "未付款"}
                    </div>
                    <div className="date-item">{record.note}</div>
                    <div className="row-item-padding" />
                    <div className="row-item">
                      <div
                        style={{ cursor: "pointer", color: "#4485D1" }}
                        onClick={() =>
                          this.setState({
                            selectedRecord: record,
                            showDetailDialog: true
                          })
                        }
                      >
                        <Icon.Edit color={"#4485D1"} />
                        編輯
                      </div>
                    </div>
                    <div className="row-item">
                      <div
                        style={{ cursor: "pointer", color: "#4485D1" }}
                        onClick={() =>
                          this.setState({
                            selectedRecord: record,
                            showConfirmDialog: true
                          })
                        }
                      >
                        <Icon.Delete color={"#4485D1"} />
                        刪除
                      </div>
                    </div>
                  </div>
                ))}
            </Widget.TableContent>
          </Widget.Table>
        )}

        {showDetailDialog && (
          <MomBillingRecordDetailDialog
            mother={mother}
            record={selectedRecord}
            requestClose={() => this.setState({ showDetailDialog: false })}
            requestRefresh={this._fetchRecords}
          />
        )}

        {showConfirmDialog && (
          <ConfirmDialog
            text="確定要刪除嗎？此動作無法還原。"
            requestClose={() => this.setState({ showConfirmDialog: false })}
            confirmAction={() =>
              appActions.momCenter
                .deleteMomBillingRecord(selectedRecord.id)
                .then(this._fetchRecords)
                .then(() => this.setState({ showConfirmDialog: false }))
                .catch(err => console.warn(err))
            }
          />
        )}
      </Wrapper>
    );
  }

  _fetchRecords = () => {
    let { appActions, mother } = this.props;

    this.setState({ showSpinner: true });
    appActions.momCenter
      .fetchMomBillingRecord(mother.id)
      .then(records => this.setState({ records }))
      .then(() => this.setState({ showSpinner: false }))
      .catch(err => console.warn(err));
  };
}

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
`;

export default connect(
  (state, ownProps) => ({
    spareParts: Selectors.momCenter.getSpareParts(state)
  }),
  ActionCreator
)(MomBillingRecordList);
