import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import ActionCreator from "../../../src/ActionCreator";
import Selectors from "../../../src/Selectors";
import * as Widget from "../../Components/Widget2";
import * as Icon from "../../Components/Icon";
import ConfirmDialog from "./ConfirmDialog";
import CheckoutElfDialog from "./CheckoutElfDialog";

const Wrapper = styled.div`
  padding: 20px;
  background-color: white;
  flex: 1;
`;

const StyledForm = styled.div`
  font-size: 13px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  & > .cell {
    width: 16.6%;
    min-width: 200px;
    min-height: 150px;
    margin: 5px;
    border: 1px solid #88add8;
  }
`;

const CheckButton = styled.div`
  margin-top: 10px;
  height: 40px;
  background-color: ${props => (props.checkin ? "#E4A5B3" : "#88ADD8")};
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  cursor: pointer;
  transition: transform 150ms ease;

  :hover {
    font-weight: 800;
  }

  :active {
    transform: scale(0.88);
  }
`;

class DailyRoomsArrangement extends React.Component {
  constructor(props) {
    super(props);
    let record = {
      type: null,
      mother: null
    };
    this.state = {
      record: record,
      records: null,

      showSpinner: false,
      showErrorMessage: false,
      showConfirmDialog: false,
      selectedMom: null,
      toCheckin: true,
      checkinStatus: null,

      date: new Date().toISOString().split("T")[0]
    };
  }

  componentDidMount() {
    this._search();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.date !== this.state.date) {
      this._search();
    }
  }

  render() {
    let { rooms, appActions } = this.props;
    let {
      showSpinner,
      records,
      record,
      showErrorMessage,
      showConfirmDialog,
      showCheckoutElfDialog,
      toCheckin,
      checkinStatus,
      date
    } = this.state;

    return (
      <Wrapper>
        <Widget.SearchBar>
          <div className="title">查詢日期</div>
          <div className="searchInput">
            <Icon.Search color="lightgray" />
            <input
              type="date"
              value={date}
              onChange={e => this.setState({ date: e.target.value })}
            />
          </div>
        </Widget.SearchBar>
        {(() => {
          if (showErrorMessage === true) {
            return (
              <div style={{ fontSize: 13, color: "#777777" }}>請輸入日期!</div>
            );
          } else if (showSpinner === true) {
            return (
              <Widget.Row justify="center">
                <Widget.Spinner />
              </Widget.Row>
            );
          } else {
            return (
              rooms &&
              records && (
                <StyledForm>
                  {rooms.map((room, i) => {
                    let checkedInRoomRecords = records.filter(
                      record => room.id === record.room.id
                    );

                    return (
                      <div className="cell" key={i}>
                        <div
                          style={{
                            padding: "5px 10px",
                            fontSize: 18,
                            backgroundColor: "#88ADD8",
                            color: "white"
                          }}
                        >
                          {room.name}
                        </div>

                        {checkedInRoomRecords.map((record, i) => {
                          return (
                            <div
                              key={i}
                              style={{
                                margin: "10px 15px",
                                fontSize: 14,
                                color: record.checkin ? "#777777" : "#E4A5B3"
                              }}
                            >
                              <div>
                                媽媽姓名：
                                {record.mother.name}
                              </div>
                              <div>
                                入住日期：
                                {record.from_date}
                              </div>
                              <div>
                                退房日期：
                                {record.end_date}
                              </div>
                              <div>
                                {checkinStatus &&
                                record &&
                                checkinStatus[record.mother.id] ? (
                                  <CheckButton
                                    checkin
                                    onClick={() =>
                                      this.setState({
                                        toCheckin: true,
                                        showCheckoutElfDialog: true,
                                        record: {
                                          mother: record.mother.id,
                                          type: "check-out"
                                        }
                                      })
                                    }
                                  >
                                    退房結帳
                                  </CheckButton>
                                ) : (
                                  <CheckButton
                                    onClick={() =>
                                      this.setState({
                                        toCheckin: true,
                                        showConfirmDialog: true,
                                        record: {
                                          mother: record.mother.id,
                                          type: "check-in"
                                        }
                                      })
                                    }
                                  >
                                    入房報到
                                  </CheckButton>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </StyledForm>
              )
            );
          }
        })()}
        {showConfirmDialog && (
          <ConfirmDialog
            text={
              toCheckin
                ? "確認要入房報到嗎?\n此動作無法還原"
                : "確認要退房結帳嗎？\n此動作無法還原"
            }
            requestClose={() => this.setState({ showConfirmDialog: false })}
            confirmAction={() =>
              appActions.momCenter
                .createMomCheckinoutRecord(record)
                .then(() =>
                  appActions.momCenter.searchMomLivingRecords({
                    mother: record.mother
                  })
                )
                .then(async records => {
                  let latestRecord = records[records.length - 1];
                  try {
                    await appActions.momCenter.updateMomLivingRecord({
                      ...latestRecord,
                      checkin: true
                    });
                  } catch (err) {
                    console.warn(err);
                  }
                })
                .then(() => this._search())
                .then(() => this.setState({ showConfirmDialog: false }))
                .catch(err => console.warn(err))
            }
          />
        )}

        {showCheckoutElfDialog && (
          <CheckoutElfDialog
            requestClose={() => this.setState({ showCheckoutElfDialog: false })}
            mother={record.mother}
          />
        )}
      </Wrapper>
    );
  }

  _search = () => {
    let { appActions } = this.props;
    let { date } = this.state;
    if (date === "") {
      return this.setState({ showErrorMessage: true });
    }

    this.setState({ showSpinner: true, showErrorMessage: false });
    appActions.momCenter
      .searchMomLivingRecordsByDate({ from_date: date, duration: 1 })
      .then(records => {
        this.setState({ records });
        appActions.momCenter
          .fetchMomsCheckinStatus(records)
          .then(checkinStatus => {
            this.setState({ checkinStatus });
          });
      })
      .then(() => this.setState({ showSpinner: false }))
      .catch(err => console.warn(err));
  };

  _updateSelectedMomLivingStatus = () => {
    let { appActions } = this.props;
    let { record } = this.state;

    appActions.momCenter
      .searchMomLivingRecords({ mother: record.mother })
      .then(records => {
        let latestRecord = records[records.length - 1];
        appActions.momCenter.updateMomLivingRecord({
          ...latestRecord,
          checkin: true
        });
      })
      .catch(err => console.warn(err));
  };
}

export default connect(
  (state, ownProps) => ({
    rooms: Selectors.momCenter.getRooms(state)
  }),
  ActionCreator
)(DailyRoomsArrangement);
