import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import ActionCreator from "../../../src/ActionCreator";
import Selectors from "../../../src/Selectors";
import * as Widget from "../../Components/Widget2";
import * as Icon from "../../Components/Icon";

const Wrapper = styled.div`
  padding: 20px;
  background-color: white;
  flex: 1;
`;

const StyledForm = styled.div`
  font-size: 13px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  & > .cell {
    width: 16.6%;
    min-width: 200px;
    min-height: 150px;
    margin: 5px;
    border: 1px solid #e4a5b3;
  }
`;

class DailyIncubatorsArrangement extends React.Component {
  constructor(props) {
    super(props);
    const date = new Date().toISOString().split("T")[0];
    this.state = {
      date,
      records: null,
      showSpinner: false,
      showErrorMessage: false,

      date: new Date().toISOString().split("T")[0]
    };
  }

  componentDidMount() {
    this._search();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.date !== this.state.date) {
      this._search();
    }
  }

  render() {
    let { incubators } = this.props;
    let { showSpinner, records, showErrorMessage, date } = this.state;

    return (
      <Wrapper>
        <Widget.SearchBar>
          <div className="title">查詢日期</div>
          <div className="searchInput">
            <Icon.Search color="lightgray" />
            <input
              type="date"
              value={date}
              onChange={e => this.setState({ date: e.target.value })}
            />
          </div>
        </Widget.SearchBar>
        {(() => {
          if (showErrorMessage === true) {
            return (
              <div style={{ fontSize: 13, color: "#777777" }}> 請輸入日期!</div>
            );
          } else if (showSpinner === true) {
            return (
              <Widget.Row justify="center">
                <Widget.Spinner />
              </Widget.Row>
            );
          } else {
            return (
              incubators &&
              records && (
                <StyledForm>
                  {incubators.map((incubator, i) => {
                    let checkedInIncubator = records.filter(
                      record => incubator.id === record.incubator.id
                    );

                    return (
                      <div className="cell" key={i}>
                        <div
                          style={{
                            padding: "5px 10px",
                            fontSize: 18,
                            backgroundColor: "#E4A5B3",
                            color: "white"
                          }}
                        >
                          {incubator.incubator_type.name} - {incubator.name}
                        </div>
                        {checkedInIncubator.map((record, i) => {
                          return (
                            <div
                              key={i}
                              style={{
                                margin: "10px 15px",
                                fontSize: 14,
                                color: "#777777"
                              }}
                            >
                              <div>
                                寶寶姓名:
                                {record.baby ? record.baby.name : "---"}
                              </div>
                              <div>
                                入住日期:
                                {record.from_date}
                              </div>
                              <div>
                                離館日期:
                                {record.end_date}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </StyledForm>
              )
            );
          }
        })()}
      </Wrapper>
    );
  }

  _search = () => {
    let { appActions } = this.props;
    let { date } = this.state;

    if (date === "") {
      return this.setState({ showErrorMessage: true });
    }

    this.setState({ showSpinner: true, showErrorMessage: false });
    appActions.momCenter
      .searchBabyLivingRecordsByDate({ from_date: date, duration: 1 })
      .then(records => this.setState({ records }))
      .then(() => this.setState({ showSpinner: false }))
      .catch(err => console.warn(err));
  };
}

export default connect(
  (state, ownProps) => ({
    incubators: Selectors.momCenter.getIncubators(state)
  }),
  ActionCreator
)(DailyIncubatorsArrangement);
