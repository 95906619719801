import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import ActionCreator from "../../../src/ActionCreator";
import MomLivingRecordDetailDialog from "./MomLivingRecordDetailDialog";
import ConfirmDialog from "./ConfirmDialog";
import ArrangementElfDialog from "./ArrangementElfDialog";

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
`;

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

class MomVisitList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records: null,
      selectedRecord: null,
      showSpinner: false,
      showDetailDialog: false,
      showConfirmDialog: false,
      showArrangementElfDialog: false,
      isExtendDaysView: false
    };

    let { mother, appActions } = this.props;
    this.listApi = appActions.momCenter.searchMomLivingRecords.bind(null, {
      mother: mother.id
    });
    this.deleteApi = appActions.momCenter.deleteMomLivingRecord;
  }

  componentDidMount() {
    this._fetchRecords();
  }

  _fetchRecords = () => {
    this.setState({ showSpinner: true });
    delay(500)
      .then(this.listApi)
      .then(records => {
        this.setState({ records, showSpinner: false });
      })
      .catch(err => console.warn(err));
  };

  render() {
    let { mother } = this.props;
    let {
      records,
      selectedRecord,
      showSpinner,
      showDetailDialog,
      showConfirmDialog,
      showArrangementElfDialog,
      isExtendDaysView
    } = this.state;

    return (
      <Wrapper>
        <Widget.Row>
          <div
            style={{
              display: "flex",
              flex: 1,
              color: "#777777",
              fontWeight: "500",
              padding: 10
            }}
          >
            搜尋結果
          </div>
          <div style={{ margin: 10, display: "flex" }}>
            <Widget.Clickable
              onClick={() => {
                this.setState({
                  selectedRecord: null,
                  showArrangementElfDialog: true
                });
              }}
            >
              <Widget.Button>新增排房紀錄</Widget.Button>
            </Widget.Clickable>
            <Widget.Clickable onClick={this._fetchRecords}>
              <Widget.Button className="white-button">重新整理</Widget.Button>
            </Widget.Clickable>
          </div>
        </Widget.Row>
        {showSpinner && <Widget.Spinner />}

        {!showSpinner && records != null && records.length === 0 && (
          <Widget.HintMessage>尚未建立此客戶相關紀錄!</Widget.HintMessage>
        )}

        {!showSpinner && records && (
          <Widget.Table>
            <Widget.TableContent>
              <Widget.Fields>
                <div className="date-field">起始日期</div>
                <div className="date-field">結束日期</div>
                <div className="field">天數</div>
                <div className="field">房間</div>
                <div className="field">生產方式</div>
                <div className="field">胎數</div>
                <div className="field">胎次</div>
                <div className="field" />
                <div className="field" />
              </Widget.Fields>
              {records.map((record, i) => (
                <div
                  className={i % 2 === 0 ? "row-even" : "row-odd"}
                  key={"mom-living-record" + i}
                >
                  <div className="date-item">{record.from_date}</div>
                  <div className="date-item">{record.end_date}</div>
                  <div className="row-item">{record.duration}</div>
                  <div className="row-item">{record.room.name}</div>
                  <div className="row-item">{record.delivery_method}</div>
                  <div className="row-item">{record.baby_count}</div>
                  <div className="row-item">{record.birth_index}</div>
                  <div className="row-item-padding" />
                  <div className="row-item">
                    <Widget.Clickable>
                      <Widget.Button
                        onClick={() => {
                          this.setState({
                            selectedRecord: record,
                            showArrangementElfDialog: true,
                            isExtendDaysView: false
                          });
                        }}
                        disabled={
                          new Date() - new Date(record.end_date) > 0 ||
                          new Date() - new Date(record.from_date) < 0
                        }
                      >
                        換房
                      </Widget.Button>
                    </Widget.Clickable>
                  </div>
                  <div className="row-item">
                    <Widget.Clickable>
                      <Widget.Button
                        onClick={() => {
                          this.setState({
                            selectedRecord: record,
                            showArrangementElfDialog: true,
                            isExtendDaysView: true
                          });
                        }}
                        disabled={new Date() - new Date(record.end_date) > 0}
                      >
                        延長天數
                      </Widget.Button>
                    </Widget.Clickable>
                  </div>
                  <div className="row-item">
                    <Widget.Clickable css={{ color: "#4485D1" }}>
                      <div
                        onClick={() => {
                          this.setState({
                            selectedRecord: record,
                            showDetailDialog: true
                          });
                        }}
                      >
                        <Icon.Edit color={"#4485D1"} />
                        編輯
                      </div>
                    </Widget.Clickable>
                  </div>
                  <div className="row-item">
                    <Widget.Clickable css={{ color: "#4485D1" }}>
                      <div
                        onClick={() => {
                          this.setState({
                            selectedRecord: record,
                            showConfirmDialog: true
                          });
                        }}
                      >
                        <Icon.Delete color={"#4485D1"} />
                        刪除
                      </div>
                    </Widget.Clickable>
                  </div>
                </div>
              ))}
            </Widget.TableContent>
          </Widget.Table>
        )}
        {showDetailDialog && (
          <MomLivingRecordDetailDialog
            mother={mother}
            record={selectedRecord}
            requestClose={() => this.setState({ showDetailDialog: false })}
            requestRefresh={this._fetchRecords}
          />
        )}

        {showConfirmDialog && (
          <ConfirmDialog
            text="確定要刪除嗎？此動作無法還原。"
            requestClose={() => this.setState({ showConfirmDialog: false })}
            confirmAction={() => {
              this.deleteApi(selectedRecord.id)
                .then(this._fetchRecords)
                .then(() => this.setState({ showConfirmDialog: false }))
                .catch(err => console.warn(err));
            }}
          />
        )}

        {showArrangementElfDialog && (
          <ArrangementElfDialog
            record={selectedRecord}
            mother={mother}
            isExtendDaysView={isExtendDaysView}
            requestClose={() =>
              this.setState({ showArrangementElfDialog: false })
            }
            requestRefresh={this._fetchRecords}
          />
        )}
      </Wrapper>
    );
  }
}

export default connect(
  state => ({}),
  ActionCreator
)(MomVisitList);
