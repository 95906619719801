import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../../../src/ActionCreator";
import * as Widget from "../../../src/Components/Widget2";
import RevCalendar from "../../../src/Components/RevCalendar";

class CalendarArrangement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      visitRecords: [],
      contractRecords: [],
      repairmentRecords: [],
      assignmentRecords: []
    };
  }

  async componentDidMount() {
    let { appActions } = this.props;
    try {
      let visitRecords = await appActions.momCenter.fetchVisit();
      let contractRecords = await appActions.momCenter.fetchContractList();
      let repairmentRecords = await appActions.momCenter.fetchRepairmentRecord();
      let assignmentRecords = await appActions.momCenter.fetchAssignment();

      this.setState({
        visitRecords,
        contractRecords,
        repairmentRecords,
        assignmentRecords
      });
    } catch (err) {
      console.warn(err);
    }
    this.setState({ fetching: false });
  }

  render() {
    let { fetching } = this.state;

    if (fetching) {
      return (
        <Wrapper css="padding: 20px;">
          <Widget.Row justify="center">
            <Widget.Spinner />
          </Widget.Row>
        </Wrapper>
      );
    }

    return <Wrapper>{this._renderCalendar()}</Wrapper>;
  }

  _renderCalendar = () => {
    let { appActions } = this.props;
    let {
      visitRecords,
      contractRecords,
      repairmentRecords,
      assignmentRecords
    } = this.state;
    let today = new Date();

    return (
      <RevCalendar
        defaultValue={today}
        selectedDt={today}
        disablePast={false}
        year={today.getFullYear()}
        month={today.getMonth()}
        minDate={new Date()}
        onSelect={dt => console.log("selected date", dt)}
        //data
        visitRecords={visitRecords}
        contractRecords={contractRecords}
        repairmentRecords={repairmentRecords}
        assignmentRecords={assignmentRecords}
        //refresh function
        onRefreshVisitRecords={async () => {
          this.setState({ fetching: true });
          let mothers = await appActions.momCenter.fetchMotherList();
          let visitRecords = await appActions.momCenter.fetchVisit();
          visitRecords = visitRecords.map(record => {
            let motherData = mothers.filter(
              mother => mother.id === record.mother
            )[0];
            return { ...record, mother: motherData };
          });
          this.setState({ mothers, visitRecords, fetching: false });
        }}
        onRefreshAssignmentRecords={async () => {
          this.setState({ fetching: true });
          let assignmentRecords = await appActions.momCenter.fetchAssignment();
          this.setState({ assignmentRecords, fetching: false });
        }}
      />
    );
  };
}

const Wrapper = styled.div`
  background-color: white;
  min-height: 100vh;
  ${props => props.css || ""};
`;

export default connect(
  null,
  ActionCreator
)(CalendarArrangement);
