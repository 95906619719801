import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as Widget from "../../../src/Components/Widget2";
import ActionCreator from "../../../src/ActionCreator";
import Dialog from "../../../src/Components/Dialog";

const Wrapper = styled.div`
  padding: 15px 64px;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  color: #777777;
`;

const ButtonSection = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

class RepairmentRecordDetailDialog extends React.Component {
  constructor(props) {
    super(props);
    let { record } = this.props;
    let isCreateView = false;
    if (!record) {
      isCreateView = true;
      record = {
        room: this.props.room.id,
        summary: "",
        estimated_date: new Date().toISOString().split("T")[0],
        estimated_time: new Date().toLocaleTimeString("en-US", {
          hour12: false
        }),
        status: "idle",
        detail: ""
      };
    }
    this.state = {
      record: record,
      showSpinner: false,
      isCreateView
    };
  }
  render() {
    let { requestClose } = this.props;
    let { record, isCreateView } = this.state;

    return (
      <Dialog>
        <Wrapper>
          {isCreateView ? (
            <Title>新增修繕事項</Title>
          ) : (
            <Title>修改修繕事項</Title>
          )}

          <Widget.Row>
            <Widget.FormField stacked>
              <label>修繕物品</label>
              <input
                value={record.summary}
                onChange={e =>
                  this.setState({
                    record: { ...record, summary: e.target.value }
                  })
                }
              />
            </Widget.FormField>

            <Widget.FormField stacked>
              <label>修繕日期</label>
              <input
                type="date"
                value={record.estimated_date}
                onChange={e =>
                  this.setState({
                    record: { ...record, estimated_date: e.target.value }
                  })
                }
              />
            </Widget.FormField>
          </Widget.Row>

          <Widget.Row>
            <Widget.FormField stacked>
              <label>修繕時間</label>
              <input
                type="time"
                value={record.estimated_time}
                onChange={e =>
                  this.setState({
                    record: { ...record, estimated_time: e.target.value }
                  })
                }
              />
            </Widget.FormField>

            <Widget.FormField stacked>
              <label>修繕進度</label>
              <select
                value={record.status}
                onChange={e =>
                  this.setState({
                    record: { ...record, status: e.target.value }
                  })
                }
              >
                <option value="idle">未完成</option>
                <option value="processing">處理中</option>
                <option value="completed">已完成</option>
              </select>
            </Widget.FormField>
          </Widget.Row>

          <Widget.FormField stacked>
            <label>修繕內容</label>
            <textarea
              value={record.detail}
              onChange={e =>
                this.setState({ record: { ...record, detail: e.target.value } })
              }
              style={{ width: 440, height: 100, border: "1px solid #e9eff4" }}
            />
          </Widget.FormField>

          {isCreateView ? (
            <ButtonSection>
              <Widget.Button
                className="white-button"
                css="width: 100px; margin: 0px 20px;"
                onClick={requestClose}
              >
                取消
              </Widget.Button>
              <Widget.Button
                css="width: 100px; margin: 0px 20px;"
                onClick={this._creatRecord}
              >
                新增
              </Widget.Button>
            </ButtonSection>
          ) : (
            <ButtonSection>
              <Widget.Button
                className="white-button"
                css="width: 100px; margin: 0px 20px;"
                onClick={requestClose}
              >
                取消
              </Widget.Button>
              <Widget.Button
                css="width: 100px; margin: 0px 20px;"
                onClick={this._confirmUpdate}
              >
                修改
              </Widget.Button>
            </ButtonSection>
          )}
        </Wrapper>
      </Dialog>
    );
  }

  _creatRecord = () => {
    let { appActions, requestRefresh, requestClose } = this.props;
    let { record } = this.state;

    this.setState({ showSpinner: true });
    appActions.momCenter
      .createRepairmentRecord(record)
      .then(requestRefresh)
      .then(() => this.setState({ showSpinner: false }))
      .then(requestClose)
      .catch(err => console.warn(err));
  };

  _confirmUpdate = () => {
    let { appActions, requestRefresh, requestClose } = this.props;
    let { record } = this.state;

    this.setState({ showSpinner: true });
    appActions.momCenter
      .updateRepairment(record)
      .then(requestRefresh)
      .then(() => this.setState({ showSpinner: false }))
      .then(requestClose)
      .catch(err => console.warn(err));
  };
}
export default connect(
  null,
  ActionCreator
)(RepairmentRecordDetailDialog);
