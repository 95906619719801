import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import ActionCreator from "../../../src/ActionCreator";
import { dateParser } from "../../../src/Utils/DateUtil";
import MomVisitDetailDialog from "./MomVisitDetailDialog";
import ConfirmDialog from "./ConfirmDialog";

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
`;

const Title = styled.h3`
  margin-bottom: 10px;
  color: #777777;
`;

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

class MomVisitList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records: null,
      selectedRecord: null,
      showSpinner: false,
      showDetailDialog: false,
      showConfirmDialog: false
    };

    let { mother, appActions } = this.props;
    this.listApi = appActions.momCenter.searchVisits.bind(null, {
      mother: mother.id
    });
    this.deleteApi = appActions.momCenter.deleteVisit;
  }

  componentDidMount() {
    this._fetchRecords();
  }

  _fetchRecords = () => {
    this.setState({ showSpinner: true });
    delay(500)
      .then(this.listApi)
      .then(records => {
        this.setState({ records, showSpinner: false });
      })
      .catch(err => console.warn(err));
  };

  render() {
    let { mother } = this.props;
    let {
      records,
      selectedRecord,
      showSpinner,
      showDetailDialog,
      showConfirmDialog
    } = this.state;

    return (
      <Wrapper>
        <Title>預約參觀</Title>
        <Widget.Row align="center">
          <div
            style={{
              display: "flex",
              flex: 1,
              fontSize: 16,
              color: "#777777",
              padding: 10
            }}
          >
            搜尋結果
          </div>
          <div style={{ margin: 10, display: "flex" }}>
            <Widget.Clickable
              css="margin: 5px;"
              onClick={() => {
                this.setState({
                  selectedRecord: null,
                  showDetailDialog: true
                });
              }}
            >
              <Widget.Button>新增預約參觀</Widget.Button>
            </Widget.Clickable>

            <Widget.Clickable css="margin: 5px;" onClick={this._fetchRecords}>
              <Widget.Button className="white-button">重新整理</Widget.Button>
            </Widget.Clickable>
          </div>
        </Widget.Row>

        {showSpinner && <Widget.Spinner />}

        {!showSpinner && records != null && records.length === 0 && (
          <Widget.HintMessage>尚未建立此客戶相關紀錄!</Widget.HintMessage>
        )}

        {!showSpinner && (
          <Widget.Table>
            <Widget.TableContent>
              <Widget.Fields>
                <div className="field">參觀日期</div>
                <div className="field">參觀時段</div>
                <div className="field">取消參觀</div>
                <div className="field">是否已試吃</div>
                <div className="field">參觀備註</div>
                <div className="field">帶參觀人</div>
                <div className="field">帶參觀人評估</div>
                <div className="field" />
                <div className="field" />
              </Widget.Fields>
              {records &&
                records.map((record, i) => (
                  <div
                    className={i % 2 === 0 ? "row-even" : "row-odd"}
                    key={"visit" + i}
                  >
                    <div className="row-item">
                      {dateParser(record.visit_time).slice(0, 10)}
                    </div>
                    <div className="row-item">
                      {dateParser(record.visit_time).slice(11, 16)}
                    </div>
                    <div className="row-item">
                      {record.cancel_visit ? "已取消" : "---"}
                    </div>
                    <div className="row-item">
                      {record.had_meal_trial ? "已試吃" : "---"}
                    </div>
                    <div className="row-item">{record.demand_note}</div>
                    <div className="row-item">{record.staff.user.username}</div>
                    <div className="row-item">{record.note}</div>
                    <div className="row-item">
                      <div
                        style={{ cursor: "pointer", color: "#4485D1" }}
                        onClick={() =>
                          this.setState({
                            showDetailDialog: true,
                            selectedRecord: record
                          })
                        }
                      >
                        <Icon.Edit color={"#4485D1"} />
                        編輯
                      </div>
                    </div>
                    <div className="row-item">
                      <div
                        style={{ cursor: "pointer", color: "#4485D1" }}
                        onClick={() =>
                          this.setState({
                            showConfirmDialog: true,
                            selectedRecord: record
                          })
                        }
                      >
                        <Icon.Delete color={"#4485D1"} />
                        刪除
                      </div>
                    </div>
                  </div>
                ))}
            </Widget.TableContent>
          </Widget.Table>
        )}

        {showDetailDialog && (
          <MomVisitDetailDialog
            mother={mother}
            record={selectedRecord}
            requestClose={() => this.setState({ showDetailDialog: false })}
            requestRefresh={this._fetchRecords}
          />
        )}

        {showConfirmDialog && (
          <ConfirmDialog
            text="確定要刪除嗎？此動作無法還原。"
            requestClose={() => this.setState({ showConfirmDialog: false })}
            confirmAction={() => {
              this.deleteApi(selectedRecord.id)
                .then(this._fetchRecords)
                .then(() => this.setState({ showConfirmDialog: false }))
                .catch(err => console.warn(err));
            }}
          />
        )}
      </Wrapper>
    );
  }
}

export default connect(
  state => ({}),
  ActionCreator
)(MomVisitList);
