import React from "react";
import moment from "moment";
import styled from "styled-components";
import * as Icon from "../../../src/Components/Icon";
import * as Widget from "../../../src/Components/Widget2";
import { connect } from "react-redux";
import Selectors from "../../../src/Selectors";
import ActionCreator from "../../../src/ActionCreator";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 20px 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;

  & > .close {
    top: 10px;
    right: 10px;
    cursor: pointer;
    position: absolute;
  }
`;

const Table = styled.div`
  margin-bottom: 22px;
  width: 97vw;
  display: flex;
`;

const CellHeight = 30;
const CellWidth = 45;
const CellFont = 12;
const HeaderHeight = CellHeight;
const HeaderWidth = 45;
const HeaderFont = CellFont;

const StaticCol = styled.div`
  flex: 0;
  flex-shrink: 0;
  flex-basis: ${HeaderWidth}px;
  width: ${HeaderWidth}px;

  & > .cell {
    border: 0.5px solid #c0c0c0;
    width: ${HeaderWidth}px;
    height: ${HeaderHeight}px;
    line-height: 28px;
    font-size: ${HeaderFont}px;
    color: #777777;
    text-align: center;
    background-color: #f3f3f3;
  }
`;

const ScrollableCol = styled.div`
  flex: 0;
  flex-shrink: 0;
  flex-basis: calc(97vw - ${HeaderWidth}px);
  width: calc(97vw - ${HeaderWidth}px);
  overflow-x: auto;
`;

const Row = styled.div`
  display: flex;
  height: ${CellHeight}px;

  & > .cell {
    flex: 0;
    flex-shrink: 0;
    flex-basis: ${CellWidth}px;
    width: ${CellWidth}px;
    border: 0.5px solid #c0c0c0;
    height: ${CellHeight}px;
    line-height: 28px;
    font-size: ${CellFont}px;
    color: #777777;
    text-align: center;
    background-color: ${props => props.bgColor || ""};
  }
`;

const Title = styled.div`
  border-bottom: 1px solid #e7e7e7;
  padding: 15px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: #777777;
`;

const Text = styled.div`
  font-size: 14px;
  color: #777777;
  ${props => props.css || ""};
`;

const Status = styled.div`
  display: flex;
  align-items: center;

  & > .round {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: ${props => props.roundColor || "gray"};
  }

  & > .label {
    font-size: 14px;
    color: #777777;
    margin-right: ${props => (props.last ? "5" : "30")}px;
  }
`;

function generateDaysInRange(from_date, duration) {
  const ONE_DAY_MS = 24 * 3600 * 1000;
  let date = new Date(from_date);
  let daysInRange = [];
  for (let i = 0; i < duration; i++) {
    daysInRange.push(new Date(date.getTime() + i * ONE_DAY_MS));
  }
  return daysInRange;
}

class LivingRecord {
  constructor(record) {
    this.record = record;
    this.fromDate = new Date(record.from_date);
    this.endDate = new Date(record.end_date);
  }

  getMother() {
    return this.record.mother;
  }

  getMotherName() {
    return this.record.mother.name;
  }

  isFirstDate(date) {
    return date.getTime() === this.fromDate.getTime();
  }

  isLastDate(date) {
    return date.getTime() === this.endDate.getTime();
  }

  isContain(date) {
    return this.fromDate <= date && date < this.endDate;
  }
}

const UiSearchView = {
  roomView: "roomView",
  roomType: "roomType"
};

class RoomSchedulingPanel extends React.Component {
  constructor(props) {
    super(props);
    const from_date = new Date().toISOString().split("T")[0];
    const duration = 30;

    this.state = {
      from_date,
      duration,
      records: null,
      showSpinner: false,
      showErrorMessage: false,
      daysInRange: generateDaysInRange(from_date, duration),
      uiSearchView: UiSearchView.roomView
    };
  }

  render() {
    let { requestClose, rooms, roomTypes, selectMother } = this.props;
    let {
      from_date,
      daysInRange,
      records,
      showSpinner,
      showErrorMessage,
      uiSearchView
    } = this.state;

    return (
      <Wrapper>
        <Container>
          <div className="close" onClick={requestClose}>
            <Icon.Close color={"#777777"} />
          </div>

          <Title>排房詳情</Title>

          <Widget.Row
            justify="center"
            align="center"
            css="padding: 20px; width: 100%;"
          >
            <Widget.FormField inputHeight="40px;" css="margin: 0px;">
              <label>起始日期</label>
              <input
                type="date"
                value={from_date}
                onChange={e => this.setState({ from_date: e.target.value })}
              />
            </Widget.FormField>

            <Widget.Button
              css="height: 40px;"
              onClick={() => {
                this.setState({ uiSearchView: UiSearchView.roomView });
                this._searchRecords();
              }}
            >
              全館排房檢視
            </Widget.Button>

            <Widget.Button
              css="height: 40px;"
              onClick={() => {
                this.setState({ uiSearchView: UiSearchView.roomType });
                this._searchRecords();
              }}
            >
              房型數量檢視
            </Widget.Button>

            <Text css="margin-left: 20px;">（最多顯示 30 天）</Text>
          </Widget.Row>

          {showSpinner && (
            <Widget.Row justify="center">
              <Widget.Spinner />
            </Widget.Row>
          )}

          {showErrorMessage ? (
            <div>請輸入日期！</div>
          ) : (
            (() => {
              if (!records) {
                return null;
              }

              if (uiSearchView === UiSearchView.roomView) {
                return (
                  <>
                    <Widget.Row
                      justify="space-between"
                      css="width: 97vw; padding: 5px;"
                    >
                      <Text css="font-weight: bold; font-size: 16px;">
                        全館排房
                      </Text>

                      <Widget.Row>
                        <Status roundColor="#f3eeca" last>
                          <div className="round" />
                          <div className="label">已排房</div>
                        </Status>
                      </Widget.Row>
                    </Widget.Row>

                    <Table>
                      <StaticCol>
                        <div className="cell">---</div>

                        {rooms.map(room => (
                          <div key={room.id} className="cell">
                            {room.name}
                          </div>
                        ))}
                      </StaticCol>

                      <ScrollableCol>
                        <Row bgColor="#f3f3f3">
                          {daysInRange.map(day => {
                            let dayStr = `${day.getMonth() +
                              1}/${day.getDate()}`;
                            return (
                              <div key={dayStr} className="cell">
                                {moment(dayStr).format("MM/DD")}
                              </div>
                            );
                          })}
                        </Row>

                        {rooms.map(room => {
                          let relatedLivingRecords = records
                            .filter(record => record.room.id === room.id)
                            .map(record => new LivingRecord(record));

                          return (
                            <Row key={room.id}>
                              {daysInRange.map((day, i) => {
                                let hitRecord = relatedLivingRecords.find(
                                  record => record.isContain(day)
                                );

                                if (!hitRecord) {
                                  return <div key={i} className="cell" />;
                                }

                                return (
                                  <div
                                    key={i}
                                    className="cell"
                                    style={{
                                      backgroundColor: "#F3EECA",
                                      cursor: "pointer"
                                    }}
                                    onClick={() => {
                                      requestClose();
                                      selectMother(hitRecord.getMother());
                                    }}
                                  >
                                    {hitRecord.getMotherName()}
                                  </div>
                                );
                              })}
                            </Row>
                          );
                        })}
                      </ScrollableCol>
                    </Table>
                  </>
                );
              }

              if (uiSearchView === UiSearchView.roomType) {
                return (
                  <>
                    <Text css="padding: 5px; width: 97vw; font-weight: bold; font-size: 16px;">
                      房型數量
                    </Text>
                    <Table>
                      <StaticCol>
                        <div className="cell">---</div>

                        {roomTypes.map(roomType => (
                          <div key={roomType.id} className="cell">
                            {roomType.name}
                          </div>
                        ))}
                      </StaticCol>

                      <ScrollableCol>
                        <Row bgColor="#f3f3f3">
                          {daysInRange.map(day => {
                            let dayStr = `${day.getMonth() +
                              1}/${day.getDate()}`;
                            return (
                              <div key={dayStr} className="cell">
                                {moment(dayStr).format("MM/DD")}
                              </div>
                            );
                          })}
                        </Row>

                        {roomTypes.map(roomType => {
                          let totalRooms = rooms.filter(
                            room => room.room_type.id === roomType.id
                          ).length;
                          let relatedLivingRecords = records
                            .filter(
                              record => record.room.room_type.id === roomType.id
                            )
                            .map(record => new LivingRecord(record));

                          return (
                            <Row key={roomType.id}>
                              {daysInRange.map((day, i) => {
                                let used = relatedLivingRecords.filter(record =>
                                  record.isContain(day)
                                ).length;
                                return (
                                  <div key={i} className="cell">
                                    {totalRooms - used}
                                  </div>
                                );
                              })}
                            </Row>
                          );
                        })}
                      </ScrollableCol>
                    </Table>
                  </>
                );
              }
            })()
          )}
        </Container>
      </Wrapper>
    );
  }

  _searchRecords = () => {
    let { appActions } = this.props;
    let { from_date, duration } = this.state;

    if (from_date === "") {
      return this.setState({ showErrorMessage: true });
    }

    this.setState({
      showSpinner: true,
      showErrorMessage: false,
      records: null,
      daysInRange: generateDaysInRange(from_date, duration)
    });
    appActions.momCenter
      .searchMomLivingRecordsByDate({ from_date, duration })
      .then(records => {
        console.log(records);
        this.setState({ records, showSpinner: false });
      })
      .catch(err => {
        console.warn(err);
        this.setState({ showSpinner: false });
      });
  };
}

export default connect(
  (state, ownProps) => ({
    rooms: Selectors.momCenter.getRooms(state),
    roomTypes: Selectors.momCenter.getRoomTypes(state)
  }),
  ActionCreator
)(RoomSchedulingPanel);
