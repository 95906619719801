import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Selectors from "../../../src/Selectors";
import ActionCreator from "../../../src/ActionCreator";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import MomCaringRecordDetailDialog from "./MomCaringRecordDetailDialog";
import ConfirmDialog from "./ConfirmDialog";

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
`;

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

class MomCaringRecordList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records: null,
      livingRecords: null,
      showSpinner: false,
      selectedRecord: null,
      showDetailDialog: false,
      showConfirmDialog: false
    };
    let { mother, appActions } = this.props;
    this.listApi = appActions.momCenter.searchMomCaringRecord.bind(null, {
      mother: mother.id
    });
    this.livingApi = appActions.momCenter.searchMomLivingRecords.bind(null, {
      mother: mother.id
    });
    this.deleteApi = appActions.momCenter.deleteMomCaringRecord;
  }
  componentDidMount() {
    this._fetchRecords();
  }

  render() {
    let {
      records,
      showSpinner,
      selectedRecord,
      showDetailDialog,
      showConfirmDialog
    } = this.state;
    let { mother, appActions, users } = this.props;

    return (
      <Wrapper>
        <Widget.Row>
          <div
            style={{
              display: "flex",
              flex: 1,
              color: "#777777",
              fontWeight: "500",
              padding: 10
            }}
          >
            搜尋結果
          </div>
          <div style={{ margin: 10, display: "flex" }}>
            <Widget.Clickable
              onClick={() =>
                this.setState({ showDetailDialog: true, selectedRecord: null })
              }
            >
              <Widget.Button>新增關懷紀錄</Widget.Button>
            </Widget.Clickable>

            <Widget.Clickable onClick={this._fetchRecords}>
              <Widget.Button className="white-button">重新整理</Widget.Button>
            </Widget.Clickable>
          </div>
        </Widget.Row>

        {!showSpinner && records != null && records.length === 0 && (
          <Widget.HintMessage>尚未建立此客戶相關紀錄!</Widget.HintMessage>
        )}

        {showSpinner ? (
          <Widget.Spinner />
        ) : (
          <Widget.Table>
            <Widget.TableContent lessTable>
              <Widget.Fields lessTable>
                <div className="date-field">關懷日期</div>
                <div className="field">關懷人</div>
                <div className="field">入住天數</div>
                <div className="field">備註</div>
                <div className="field-padding" />
                <div className="field" />
                <div className="field" />
              </Widget.Fields>
              {records &&
                records.map((record, i) => (
                  <div
                    className={i % 2 === 0 ? "row-even" : "row-odd"}
                    key={"record" + i}
                  >
                    <div className="date-item">{record.care_date}</div>
                    <div className="row-item">
                      <select disabled>
                        <option>
                          {users.map(user => {
                            if (user.id === record.staff) {
                              return user.user.username;
                            }
                          })}
                        </option>
                      </select>
                    </div>
                    <div className="row-item">{record.living_days}</div>
                    <div className="row-item">{record.note}</div>
                    <div className="row-item-padding" />
                    <div className="row-item">
                      <div
                        style={{ cursor: "pointer", color: "#4485D1" }}
                        onClick={() =>
                          this.setState({
                            selectedRecord: record,
                            showDetailDialog: true
                          })
                        }
                      >
                        <Icon.Edit color={"#4485D1"} />
                        編輯
                      </div>
                    </div>
                    <div className="row-item">
                      <div
                        style={{ cursor: "pointer", color: "#4485D1" }}
                        onClick={() =>
                          this.setState({
                            selectedRecord: record,
                            showConfirmDialog: true
                          })
                        }
                      >
                        <Icon.Delete color={"#4485D1"} />
                        刪除
                      </div>
                    </div>
                  </div>
                ))}
            </Widget.TableContent>
          </Widget.Table>
        )}

        {showDetailDialog && (
          <MomCaringRecordDetailDialog
            mother={mother}
            record={selectedRecord}
            requestClose={() => this.setState({ showDetailDialog: false })}
            requestRefresh={this._fetchRecords}
          />
        )}

        {showConfirmDialog && (
          <ConfirmDialog
            text="確定要刪除嗎？此動作無法還原。"
            requestClose={() => this.setState({ showConfirmDialog: false })}
            confirmAction={() => {
              appActions.momCenter
                .deleteMomCaringRecord(selectedRecord.id)
                .then(this._fetchRecords)
                .then(() => this.setState({ showConfirmDialog: false }))
                .catch(err => console.warn(err));
            }}
          />
        )}
      </Wrapper>
    );
  }

  _fetchRecords = () => {
    this.setState({ showSpinner: true });
    delay(500)
      .then(this.listApi)
      .then(records => this.setState({ records }))
      .then(this.livingApi)
      .then(livingRecords =>
        this.setState({ livingRecords, showSpinner: false })
      )
      .catch(err => console.warn(err));
  };
}
export default connect(
  (state, ownProps) => ({
    users: Selectors.momCenter.getUsers(state)
  }),
  ActionCreator
)(MomCaringRecordList);
