import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Dialog from "../../../src/Components/Dialog";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import ActionCreator from "../../../src/ActionCreator";

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

class BabyDetailDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: false,
      baby: {
        girl: false,
        boy: false,
        birthday: "",
        weight: "",
        mother: this.props.selectMother.id
      },
      errorMsg: ""
    };
  }

  render() {
    let { selectMother, requestClose } = this.props;
    let { baby, showSpinner, errorMsg } = this.state;

    return (
      <Dialog>
        <Wrapper>
          <Title>新增寶寶</Title>
          <Widget.FormField inputWidth={100}>
            <label>媽媽姓名: </label>
            <input value={selectMother.name} disabled />
            <div>
              <label>
                <input
                  type="checkbox"
                  value={baby.girl}
                  onChange={e =>
                    this.setState({ baby: { ...baby, girl: e.target.checked } })
                  }
                />
                女寶寶
              </label>
              <label>
                <input
                  type="checkbox"
                  value={baby.boy}
                  onChange={e =>
                    this.setState({
                      baby: { ...baby, boy: e.target.checked }
                    })
                  }
                />
                男寶寶
              </label>
            </div>
          </Widget.FormField>

          <Widget.FormField>
            <label>寶寶生日: </label>
            <input
              type="date"
              value={baby.birthday}
              onChange={e =>
                this.setState({ baby: { ...baby, birthday: e.target.value } })
              }
            />
          </Widget.FormField>

          <Widget.FormField>
            <label>寶寶出生體重: </label>
            <input
              value={baby.weight}
              onChange={e =>
                this.setState({ baby: { ...baby, weight: e.target.value } })
              }
            />
          </Widget.FormField>

          {errorMsg && (
            <div style={{ margin: 15, color: "red" }}>{errorMsg}</div>
          )}

          {showSpinner && <Widget.Spinner />}

          <Widget.Button onClick={this._creatBaby}>確認新增</Widget.Button>

          <Close onClick={requestClose}>
            <Icon.Close />
          </Close>
        </Wrapper>
      </Dialog>
    );
  }

  _creatBaby = () => {
    let { appActions, requestClose, fetchMother, selectMother } = this.props;
    let { baby } = this.state;

    if (baby.girl === true && baby.boy === true) {
      return this.setState({ errorMsg: "生理性別只能擇一！" });
    }

    if (baby.girl === false && baby.boy === false) {
      return this.setState({ errorMsg: "請選擇生理性別！" });
    }

    if (baby.birthday === "") {
      return this.setState({ errorMsg: "請填寫寶寶生日！" });
    }

    if (baby.weight === "") {
      return this.setState({ errorMsg: "請填寫寶寶出生體重！" });
    }

    if (baby.girl === true) {
      baby.name = `${selectMother.name}之女`;
    }

    if (baby.boy === true) {
      baby.name = `${selectMother.name}之子`;
    }

    this.setState({ showSpinner: true });
    delay(500)
      .then(appActions.momCenter.createBaby(baby))
      .then(() => this.setState({ baby: baby }))
      .then(fetchMother)
      .then(() => this.setState({ showSpinner: false }))
      .then(requestClose)
      .catch(err => console.warn(err));
  };
}

const Wrapper = styled.div`
  padding: 10px;
`;

const Title = styled.h2``;

const Close = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

export default connect(
  null,
  ActionCreator
)(BabyDetailDialog);
