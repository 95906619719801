import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux';
import * as Widget from '../../../src/Components/Widget2';
import * as Icon from '../../../src/Components/Icon'
import ActionCreator from '../../../src/ActionCreator';
import Selectors from '../../../src/Selectors';
import Dialog from '../../../src/Components/Dialog'

const Wrapper = styled.div`
  padding: 10px;
  position: relative;
`

const Close = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`

const Error =styled.h3`
  color: red;
`

class MomHealthRecordDetailDialog extends React.Component {
  constructor(props){
    super(props);

    let{ mother, record, appActions} = this.props;
    let isCreatView = false;
    if(!record) {
      isCreatView = true;
      record = {
        mother: mother.id,
        note:'',
      }
    }
    this.state = {
      record: record,
      showSpinner: false,
      isCreatView,
    }
  }
  render() {
    let { requestClose } = this.props;
    let { record, isCreatView } = this.state;
    return (
      <Dialog>
        <Wrapper>
          <Widget.FormField>
            <label>備註:</label>
            <input value={record.note} onChange={e => this.setState({record: {...record, note: e.target.value}})}/>
          </Widget.FormField>
          {
            isCreatView ? (
              <Widget.Row>
                <Widget.Button onClick={this._confirmCreate}>確認新增</Widget.Button>
              </Widget.Row>
            ):(
              <Widget.Row>
                <Widget.Button onClick={this._confirmUpdate}>確認修改</Widget.Button>
              </Widget.Row>
            )
          }
          <Close onClick={requestClose}>
            <Icon.Close />
          </Close>
        </Wrapper>
      </Dialog>
    )
  }

  _confirmCreate = () => {
    let { appActions, requestClose, requestRefresh } = this.props;
    let { record } = this.state;

    this.setState({showSpinner: true})
    appActions.momCenter.creatMomHealthRecord(record)
      .then(() => this.setState({showSpinner: false}))
      .then(requestClose)
      .then(requestRefresh)
      .catch(err => console.warn(err))
  }

  _confirmUpdate = () => {
    let { appActions, requestClose, requestRefresh } = this.props;
    let { record } = this.state;

    this.setState({showSpinner: true})
    appActions.momCenter.updateMomHealthRecord(record)
    .then(() => this.setState({showSpinner: false}))
      .then(requestClose)
      .then(requestRefresh)
      .catch(err => console.warn(err))
  }
}
export default connect(
  null,ActionCreator
)(MomHealthRecordDetailDialog);