import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import ActionCreator from "../../../src/ActionCreator";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import MomMealMenuDetailDialog from "./MomMealMenuDetailDialog";
import ConfirmDialog from "./ConfirmDialog";

class MomMealMenuList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: true,
      mealMenu: null,
      selectedmealMenu: null,
      showDetailDialog: false,
      showConfirmDialog: false
    };
  }

  componentDidMount() {
    this._updateMealMenu();
  }

  render() {
    let { mother } = this.props;
    let {
      showSpinner,
      mealMenu,
      selectedmealMenu,
      showDetailDialog,
      showConfirmDialog
    } = this.state;
    console.log("mealMenu:", mealMenu);
    return (
      <Wrapper>
        <Widget.Row>
          <div
            style={{
              display: "flex",
              flex: 1,
              color: "#777777",
              fontWeight: "500",
              padding: 10
            }}
          >
            搜尋結果
          </div>
          <div style={{ margin: 10, display: "flex" }}>
            <Widget.Clickable
              onClick={() =>
                this.setState({
                  showDetailDialog: true,
                  selectedmealMenu: null
                })
              }
            >
              <Widget.Button>新增膳食紀錄</Widget.Button>
            </Widget.Clickable>

            <Widget.Clickable onClick={() => this._updateMealMenuList()}>
              <Widget.Button className="white-button">重新整理</Widget.Button>
            </Widget.Clickable>
          </div>
        </Widget.Row>

        {!showSpinner && mealMenu != null && mealMenu.length === 0 && (
          <Widget.HintMessage>尚未建立此客戶相關紀錄!</Widget.HintMessage>
        )}

        {showSpinner ? (
          <Widget.Row>
            <Widget.Spinner />
          </Widget.Row>
        ) : (
          <Widget.Table>
            <Widget.TableContent>
              <Widget.Fields>
                <div className="date-field">預計關懷日期</div>
                <div className="date-field">日期</div>
                <div className="field">早餐</div>
                <div className="field">午餐</div>
                <div className="field">晚餐</div>
                <div className="field">午點心</div>
                <div className="field">晚點心</div>
                <div className="field">關懷內容</div>
                <div className="field">備註</div>
                <div className="field-padding" />
                <div className="field" />
                <div className="field" />
              </Widget.Fields>
              {mealMenu.map((item, i) => (
                <div className={i % 2 === 0 ? "row-even" : "row-odd"}>
                  <div className="date-item">{item.expect_care_date}</div>
                  <div className="date-item">{item.date}</div>
                  <div className="row-item">{item.breakfast}</div>
                  <div className="row-item">{item.lunch}</div>
                  <div className="row-item">{item.dinner}</div>
                  <div className="row-item">{item.snack_lunch}</div>
                  <div className="row-item">{item.snack_dinner}</div>
                  <div className="row-item">{item.meal_caring_content}</div>
                  <div className="row-item">{item.note}</div>
                  <div className="row-item-padding" />
                  <div className="row-item">
                    <div
                      style={{ cursor: "pointer", color: "#4485D1" }}
                      onClick={() =>
                        this.setState({
                          showDetailDialog: true,
                          selectedmealMenu: item
                        })
                      }
                    >
                      <Icon.Edit color={"#4485D1"} />
                      編輯
                    </div>
                  </div>
                  <div className="row-item">
                    <div
                      style={{ cursor: "pointer", color: "#4485D1" }}
                      onClick={() =>
                        this.setState({
                          showConfirmDialog: true,
                          selectedmealMenu: item
                        })
                      }
                    >
                      <Icon.Delete color={"#4485D1"} />
                      刪除
                    </div>
                  </div>
                </div>
              ))}
            </Widget.TableContent>
          </Widget.Table>
        )}

        {showDetailDialog && (
          <MomMealMenuDetailDialog
            mother={mother}
            record={selectedmealMenu}
            requestUpdate={this._updateMealMenu}
            onClose={() => this.setState({ showDetailDialog: false })}
          />
        )}

        {showConfirmDialog && (
          <ConfirmDialog
            text="確定要刪除嗎？此動作無法還原。"
            confirmAction={this._deleteMealMenu}
            requestClose={() => this.setState({ showConfirmDialog: false })}
          />
        )}
      </Wrapper>
    );
  }

  _updateMealMenu = () => {
    let { mother, appActions } = this.props;
    console.log("mother.id", mother.id);
    this.setState({ showSpinner: true });
    return appActions.momCenter
      .fetchMomMealMenu(mother.id)
      .then(mealMenu => this.setState({ mealMenu }))
      .then(() => this.setState({ showSpinner: false }))
      .catch(err => console.warn(err));
  };

  _deleteMealMenu = () => {
    let { appActions } = this.props;
    let { selectedmealMenu } = this.state;

    return appActions.momCenter
      .deleteMomMomMealMenu(selectedmealMenu.id)
      .then(() => this.setState({ showConfirmDialog: false }))
      .then(() => this._updateMealMenu())
      .catch(err => console.warn(err));
  };
}

const Wrapper = styled.div``;

export default connect(
  null,
  ActionCreator
)(MomMealMenuList);
