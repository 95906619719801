import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import ActionCreator from "../../../src/ActionCreator";
import * as Widget from "../../Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import SparePartAddRecordDialog from "./SparePartAddRecordDialog";

class SparePartIdRecord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sparePart: null,
      sparePartRecords: null,
      showSpinner: true,
      showAddRecordDialog: false
    };
  }

  componentDidMount() {
    this._updateRecords();
  }

  render() {
    let { id, goBack } = this.props;
    let {
      sparePart,
      sparePartRecords,
      showSpinner,
      showAddRecordDialog
    } = this.state;

    if (showSpinner) {
      return (
        <Wrapper>
          <Widget.Row>
            <Widget.Spinner />
          </Widget.Row>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <Button onClick={goBack}>
          <Icon.ArrowBack />
          <Text>返回備品列表</Text>
        </Button>
        <Title>
          <div style={{ display: "flex" }}>
            {sparePart.name}
            備品管理
            <DetailText>單價： {sparePart.unit_price}</DetailText>
            <DetailText>目前總數： {sparePart.total}</DetailText>
          </div>
          <div
            style={{ margin: 10, display: "flex", justifyContent: "flex-end" }}
          >
            <Widget.Button
              css="margin: 0px 20px 0px 0px; width: 100px;"
              onClick={() => this.setState({ showAddRecordDialog: true })}
            >
              新增備品
            </Widget.Button>
            <Widget.Button
              className="white-button"
              css="margin: 0px; width: 100px;"
            >
              重新整理
            </Widget.Button>
          </div>
        </Title>
        <Widget.Table>
          <Widget.TableContent>
            <Widget.Fields>
              <div className="field">核銷</div>
              <div className="field">數量</div>
              <div className="field">原因</div>
            </Widget.Fields>
            {sparePartRecords.map((record, i) => (
              <div
                key={"spare-part-record" + i}
                className={i % 2 === 0 ? "row-even" : "row-odd"}
              >
                <div className="row-item">{record.operation}</div>
                <div className="row-item">{record.quantity}</div>
                <div className="row-item">{record.reason}</div>
              </div>
            ))}
          </Widget.TableContent>
        </Widget.Table>

        {showAddRecordDialog && (
          <SparePartAddRecordDialog
            id={id}
            onClose={() => {
              this.setState({ showAddRecordDialog: false });
              return this._updateRecords();
            }}
          />
        )}
      </Wrapper>
    );
  }

  _updateRecords = () => {
    let { appActions, id } = this.props;

    this.setState({ showSpinner: true });
    appActions.momCenter
      .fetchSparePartId(id)
      .then(sparePart => this.setState({ sparePart }))
      .then(() => appActions.momCenter.fetchSparePartRecord(id))
      .then(sparePartRecords => this.setState({ sparePartRecords }))
      .then(() => this.setState({ showSpinner: false }))
      .catch(err => console.warn(err));
  };
}

const Wrapper = styled.div``;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #777777;
  padding: 30px;
`;

const DetailText = styled.div`
  margin-left: 20px;
  font-size: 14px;
  font-weight: lighter;
  color: #777777;
`;

const Button = styled.div`
  margin-bottom: 10px;
  display: flex;
  cursor: pointer;
`;

const Text = styled.div``;

export default connect(
  null,
  ActionCreator
)(SparePartIdRecord);
