import React from "react";
import { connect } from "react-redux";
import ActionCreator from "../../../src/ActionCreator";
import styled from "styled-components";
import Dialog from "../../../src/Components/Dialog";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import MomVisitDetailDialog from "./MomVisitDetailDialog";

const Wrapper = styled.div``;

const MotherList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 50vh;
  overflow: auto;

  & > .header {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: 538px;
    height: 40px;
    font-size: 14px;
    color: #777777;
  }

  .item:hover {
    box-shadow: 2px 2px 2px lightgrey;
    transform: translate(-3px, -3px);
    transition: box-shadow 200ms, transform 200ms;
  }

  & > .item {
    background-color: #f3f3f3;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: 538px;
    height: 40px;
    font-weight: thin;
    font-size: 14px;
    color: #777777;
    cursor: pointer;
  }

  & > .item:nth-child(even) {
    background-color: #ffffff;
  }

  ${props => props.css || ""}
`;

const Hint = styled.div`
  padding: 10px;
  text-align: center;
  font-size: 14px;
  color: #777777;
  ${props => props.css || ""}
`;

const SearchArea = styled.div``;

const SearchBar = styled.div`
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 19px;
  display: flex;
  align-items: center;

  & > .title {
    margin-right: 30px;
    border-right: 1px solid #eaeaea;
    padding: 10px;
    max-width: 58px;
    text-align: center;
    font-size: 14px;
    color: #777777;
  }

  & > .searchInput {
    display: flex;
    align-items: center;
    border: 1px solid #e9eff4;
    border-radius: 4px;
    margin-right: 24px;
    padding-left: 10px;
    width: 220px;
    height: 40px;
  }

  & > div > input {
    border: 0px;
    padding-left: 10px;
    font-size: 14px;
    color: #777777;

    ::placeholder {
      color: lightgray;
    }

    :focus {
      outline: none;
    }
  }
`;

const Form = styled.div`
  padding: 15px;

  & > .fields {
    margin-bottom: 20px;
  }

  & > div > label {
    margin-right: 10px;
    font-size: 14px;
    color: #777777;
  }

  & > div > input {
    border: 1px solid #e9eff4;
    border-radius: 4px;
    margin-right: 20px;
    padding-left: 15px;
    width: 220px;
    height: 40px;

    :focus {
      outline: none;
    }
  }

  & > .error {
    margin-bottom: 5px;
    font-size: 14px;
    color: red;
  }

  & > .buttons {
    text-align: right;
  }
`;

const Container = styled.div`
  padding: 15px;

  & > .title {
    margin: 5px 0px 10px 0px;
    text-align: center;
    font-size: 14px;
    color: #777777;
  }

  & > .icon {
    display: block;
    margin: auto auto 20px;
    width: 120px;
  }
`;

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

class MomListingDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        name: this.props.name || "",
        phone: this.props.phone || ""
      },
      mothers: [],
      mother: null,
      showSpinner: false,
      showSuccessDialog: false,
      showMomVisitDailog: false,
      error: null,
      errorMessage: ""
    };
  }

  componentDidMount() {
    let { create } = this.props; // whether used to create new mother record

    if (!create) {
      let { values } = this.state;
      if (values.phone || values.name) {
        this._searchMothers();
      }
    }
  }

  render() {
    let {
      requestClose,
      selectMother,
      create,
      from,
      date,
      onRefreshVisitRecords
    } = this.props;
    let {
      values,
      mothers,
      mother,
      showSpinner,
      showSuccessDialog,
      showMomVisitDailog,
      errorMessage
    } = this.state;

    if (create) {
      return (
        <Dialog>
          <Wrapper>
            {showSuccessDialog ? (
              <Container>
                <div className="title">客戶新增成功！</div>
                <img className="icon" src="/images/pages/icon-success.png" />
                <Widget.Button
                  css="margin: 0 auto; display: block; width: 100px;"
                  onClick={
                    from !== "calendar"
                      ? this._confirmCreate()
                      : () => this.setState({ showMomVisitDailog: true })
                  }
                >
                  確認
                </Widget.Button>
              </Container>
            ) : (
              <Form>
                <Widget.FormField>
                  <label>姓名</label>
                  <input
                    value={values.name}
                    onChange={e =>
                      this.setState({
                        values: { ...values, name: e.target.value }
                      })
                    }
                  />
                </Widget.FormField>

                <Widget.FormField>
                  <label>電話</label>
                  <input
                    value={values.phone}
                    onChange={e =>
                      this.setState({
                        values: { ...values, phone: e.target.value }
                      })
                    }
                  />
                </Widget.FormField>

                {errorMessage && <div className="error">{errorMessage}</div>}

                <div className="buttons">
                  {(showSpinner && (
                    <Widget.Spinner css="margin-top: 15px;" />
                  )) || (
                    <>
                      <Widget.Button
                        className="white-button"
                        css="margin: 0px 20px 0px 0px; width: 100px;"
                        onClick={requestClose}
                      >
                        取消
                      </Widget.Button>
                      <Widget.Button
                        css="margin-left: 0px; width: 100px;"
                        onClick={this._createMother}
                      >
                        新增客戶
                      </Widget.Button>
                    </>
                  )}
                </div>
              </Form>
            )}
            {showMomVisitDailog && (
              <MomVisitDetailDialog
                expand={false}
                from={from}
                date={date}
                mother={mother}
                requestClose={() => {
                  this.setState({ showMomVisitDailog: false });
                  requestClose();
                }}
                requestRefresh={onRefreshVisitRecords}
              />
            )}
          </Wrapper>
        </Dialog>
      );
    }

    return (
      <Dialog>
        <Wrapper>
          <SearchArea>
            <SearchBar>
              <div className="title">搜尋客戶</div>
              <div className="searchInput">
                <Icon.Search color="lightgray" />
                <input
                  placeholder={"姓名或電話"}
                  value={values.name || values.phone}
                  onChange={e => {
                    if (isNaN(e.target.value)) {
                      return this.setState({
                        values: { ...values, name: e.target.value }
                      });
                    }
                    return this.setState({
                      values: { name: "", phone: e.target.value }
                    });
                  }}
                />
              </div>
              <Widget.Button
                css="margin: 0px 20px 0px 0px; width: 100px;"
                onClick={this._searchMothers}
              >
                搜尋客戶
              </Widget.Button>
              <Widget.Button
                className="white-button"
                css="margin: 0px; width: 100px;"
                onClick={requestClose}
              >
                取消
              </Widget.Button>
            </SearchBar>
          </SearchArea>

          <div
            style={{
              margin: "0px 0px 10px 15px",
              fontSize: "14px",
              color: "#777777"
            }}
          >
            搜尋結果
          </div>

          {showSpinner && (
            <Widget.Center css="padding-bottom: 20px;">
              <Widget.Spinner />
            </Widget.Center>
          )}

          {!showSpinner && mothers.length === 0 && (
            <Hint>尚無資料，請搜尋客戶</Hint>
          )}

          {!showSpinner && mothers.length !== 0 && (
            <MotherList>
              <div className="header hover">
                <div style={{ width: "170px", marginLeft: "14px" }}>姓名</div>
                <div style={{ width: "170px" }}>電話</div>
              </div>

              {mothers.map(mother => (
                <div
                  key={mother.id}
                  className="item"
                  onClick={
                    from !== "calendar"
                      ? () => selectMother(mother)
                      : () =>
                          this.setState({ mother, showMomVisitDailog: true })
                  }
                >
                  <div style={{ width: "170px", marginLeft: "14px" }}>
                    {mother.name}
                  </div>
                  <div style={{ width: "170px" }}>{mother.phone}</div>
                </div>
              ))}
            </MotherList>
          )}
          {showMomVisitDailog && (
            <MomVisitDetailDialog
              from={from}
              date={date}
              mother={mother}
              requestClose={() => {
                this.setState({ showMomVisitDailog: false });
                requestClose();
              }}
              requestRefresh={onRefreshVisitRecords}
            />
          )}
        </Wrapper>
      </Dialog>
    );
  }

  _createMother = () => {
    let { appActions } = this.props;
    let {
      values: { name, phone }
    } = this.state;

    if (!name || !phone) {
      return this.setState({ errorMessage: "客戶姓名及電話為必填！" });
    }

    this.setState({ showSpinner: true, error: null });
    delay(500)
      .then(() => appActions.momCenter.createMother({ name, phone }))
      .then(mother => this.setState({ mother, showSuccessDialog: true }))
      .catch(err => {
        console.warn(err);

        if (err.status === 406) {
          this.setState({
            errorMessage: "該位客戶已建立！",
            showSpinner: false
          });
        } else {
          this.setState({
            showSpinner: false
          });
        }
      });
  };

  _confirmCreate = () => {
    let { mother } = this.state;
    let { selectMother } = this.props;
    selectMother(mother);
  };

  _searchMothers = () => {
    let { appActions } = this.props;
    let {
      values: { name, phone }
    } = this.state;
    if (!name && !phone) {
      return;
    }

    this.setState({ showSpinner: true, error: null });
    delay(500)
      .then(() => appActions.momCenter.searchMothers({ name, phone }))
      .then(({ count, results }) => {
        this.setState({
          showSpinner: false,
          mothers: results
        });
      })
      .catch(err => {
        console.warn(err);
        this.setState({
          error: err,
          showSpinner: false,
          mothers: []
        });
      });
  };
}

export default connect(
  state => ({}),
  ActionCreator
)(MomListingDialog);
