import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import ActionCreator from "../../../src/ActionCreator";
import Dialog from "../../../src/Components/Dialog";
import Selectors from "../../../src/Selectors";
import moment from "moment";

class AssignmentDetailDialog extends React.Component {
  constructor(props) {
    super(props);
    let { record, appActions, date } = this.props;
    let isCreateView = false;

    if (!record) {
      isCreateView = true;
      record = {
        note: "",
        estimated_date: date,
        estimated_time: "",
        status: false
      };
    }

    this.state = {
      record: record && { ...record },
      isEditing: true,
      isUpdating: false,
      error: null,
      isCreateView
    };

    this.createApi = appActions.momCenter.createAssignment;
    this.updateApi = appActions.momCenter.updateAssignment;
    this.originalRecord = { ...record };
  }

  render() {
    let { requestClose } = this.props;
    let { record, isEditing, isUpdating, error, isCreateView } = this.state;

    return (
      <Dialog>
        <Widget.DialogWrapper>
          {record ? (
            <Widget.FieldContainer css="flex-direction: column;">
              <Widget.FormField>
                <label>預期處理日期</label>
                <input
                  type="date"
                  value={record.estimated_date}
                  onChange={e =>
                    this.setState({
                      record: { ...record, estimated_date: e.target.value }
                    })
                  }
                />
              </Widget.FormField>

              <Widget.FormField>
                <label>預期處理時間</label>
                <input
                  type="time"
                  value={record.estimated_time}
                  onChange={e =>
                    this.setState({
                      record: { ...record, estimated_time: e.target.value }
                    })
                  }
                />
              </Widget.FormField>

              <Widget.FormField>
                <label>交辦事項</label>
                <input
                  value={record.note}
                  onChange={e =>
                    this.setState({
                      record: { ...record, note: e.target.value }
                    })
                  }
                  disabled={!isEditing || isUpdating}
                />
              </Widget.FormField>

              <Widget.FormField>
                <label>已處理</label>
                <input
                  type="checkbox"
                  value={record.status}
                  checked={record.status ? true : false}
                  onChange={e =>
                    this.setState({
                      record: { ...record, status: e.target.checked }
                    })
                  }
                />
              </Widget.FormField>
            </Widget.FieldContainer>
          ) : (
            <h1>fetch record failed</h1>
          )}

          {error && <Error>{error}</Error>}

          {isEditing &&
            (isUpdating ? (
              <Widget.Spinner />
            ) : isCreateView ? (
              <Widget.Row>
                <Widget.Button onClick={this._confirmCreate}>
                  確認新增
                </Widget.Button>
              </Widget.Row>
            ) : (
              <Widget.Row>
                <Widget.Button onClick={this._confirmUpdate}>
                  確認修改
                </Widget.Button>
              </Widget.Row>
            ))}

          <Close onClick={requestClose}>
            <Icon.Close />
          </Close>
        </Widget.DialogWrapper>
      </Dialog>
    );
  }

  _confirmCreate = () => {
    let { requestRefresh, requestClose = () => 0 } = this.props;
    let { record } = this.state;
    record.estimated_time = `${record.estimated_date} ${
      record.estimated_time
    }:00`;

    this.setState({ isUpdating: true });
    this.createApi(record)
      .then(() => {
        this.setState({ isUpdating: false });
        requestRefresh();
        requestClose();
      })
      .catch(() => {
        this.setState({ isUpdating: false });
        this._showErrorToast("API錯誤, 請稍後再試", 2000);
      });
  };

  _confirmUpdate = () => {
    let { requestRefresh, requestClose = () => 0 } = this.props;
    let { record } = this.state;
    record.estimated_time = `${record.estimated_date} ${
      record.estimated_time
    }:00`;

    this.setState({ isUpdating: true });
    this.updateApi(record)
      .then(() => {
        this.setState({ isUpdating: false });
        requestRefresh();
        requestClose();
      })
      .catch(() => {
        this.setState({ isUpdating: false });
        this._showErrorToast("API錯誤, 請稍後再試", 2000);
      });
  };

  _showErrorToast = (message, ms) => {
    this.setState({ error: message });
    setTimeout(() => {
      this.setState({ error: null });
    }, ms);
  };
}

const Close = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

const Error = styled.h3`
  color: red;
`;

export default connect(
  null,
  ActionCreator
)(AssignmentDetailDialog);
