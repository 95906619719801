import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import Selectors from "../../../src/Selectors";
import ActionCreator from "../../../src/ActionCreator";
import Dialog from "../../../src/Components/Dialog";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";

class MomBorrowRecordDetailDialog extends React.Component {
  constructor(props) {
    super(props);

    let isCreateView = false;
    let { record, mother, users } = this.props;

    if (!record) {
      isCreateView = true;
      record = {
        note: "",
        borrow_staff: "",
        borrow_date: moment(new Date()).format("YYYY-MM-DD"),
        borrow_time: moment(new Date(), "hmm").format("HH:mm"),
        returned: false,
        returned_staff: null,
        returned_date: moment(new Date()).format("YYYY-MM-DD"),
        returned_time: moment(new Date(), "hmm").format("HH:mm"),
        mother: mother.id
      };
    }

    this.state = {
      record: record,
      isCreateView,
      errorMsg: ""
    };
  }

  render() {
    let { requestClose, users } = this.props;
    let { record, isCreateView, errorMsg } = this.state;

    return (
      <Dialog>
        <Wrapper>
          {isCreateView ? <h2>新增借物紀錄</h2> : <h2>修改借物紀錄</h2>}

          <Widget.FormField stacked inputWidth="400px">
            <label>借物內容（字數限30字）: </label>
            <input
              maxLength={30}
              value={record.note}
              onChange={e =>
                this.setState({ record: { ...record, note: e.target.value } })
              }
            />
          </Widget.FormField>

          <Widget.FormField>
            <label>出借員工：</label>
            <select
              value={record.borrow_staff}
              onChange={e =>
                this.setState({
                  record: { ...record, borrow_staff: e.target.value }
                })
              }
            >
              <option value="">---</option>
              {users.map((user, i) => (
                <option key={"user" + i} value={user.id}>
                  {user.user.username}
                </option>
              ))}
            </select>
          </Widget.FormField>

          <Widget.FormField>
            <label>出借日期: </label>
            <input
              type="date"
              value={record.borrow_date}
              onChange={e =>
                this.setState({
                  record: { ...record, borrow_date: e.target.value }
                })
              }
            />
          </Widget.FormField>

          <Widget.FormField>
            <label>出借時間: </label>
            <input
              type="time"
              value={record.borrow_time}
              onChange={e =>
                this.setState({
                  record: { ...record, borrow_time: e.target.value }
                })
              }
            />
          </Widget.FormField>

          <Widget.FormField>
            <label>是否歸還：</label>
            <input
              type="checkbox"
              checked={record.returned}
              onChange={e =>
                this.setState({
                  record: { ...record, returned: e.target.checked }
                })
              }
            />
          </Widget.FormField>

          <Widget.FormField>
            <label>歸還員工：</label>
            <select
              value={record.returned_staff}
              onChange={e =>
                this.setState({
                  record: { ...record, returned_staff: e.target.value }
                })
              }
            >
              <option value="">---</option>
              {users.map((user, i) => (
                <option key={user.id + i} value={user.id}>
                  {user.user.username}
                </option>
              ))}
            </select>
          </Widget.FormField>

          <Widget.FormField>
            <label>歸還日期: </label>
            <input
              type="date"
              value={record.returned_date}
              onChange={e =>
                this.setState({
                  record: { ...record, returned_date: e.target.value }
                })
              }
            />
          </Widget.FormField>

          <Widget.FormField>
            <label>歸還時間: </label>
            <input
              type="time"
              value={record.returned_time}
              onChange={e =>
                this.setState({
                  record: { ...record, returned_time: e.target.value }
                })
              }
            />
          </Widget.FormField>

          {errorMsg && (
            <div style={{ margin: 15, color: "red" }}>{errorMsg}</div>
          )}

          {isCreateView ? (
            <Widget.Button onClick={this._confirmCreate}>
              確認新增
            </Widget.Button>
          ) : (
            <Widget.Button onClick={this._confirmUpdate}>
              確認修改
            </Widget.Button>
          )}

          <Close onClick={requestClose}>
            <Icon.Close />
          </Close>
        </Wrapper>
      </Dialog>
    );
  }

  _confirmCreate = () => {
    let { appActions, requestClose, requestRefresh } = this.props;
    let { record } = this.state;

    if (record.note === "") {
      return this.setState({ errorMsg: "請填寫借物內容！" });
    }

    if (record.borrow_staff === "") {
      return this.setState({ errorMsg: "請填寫出借員工！" });
    }

    if (record.borrow_date === "" || record.borrow_time === "") {
      return this.setState({ errorMsg: "請填寫出借日期及時間！" });
    }

    appActions.momCenter
      .createMomBorrowRecord(record)
      .then(requestClose)
      .then(requestRefresh)
      .catch(err => console.warn(err));
  };

  _confirmUpdate = () => {
    let { appActions, requestClose, requestRefresh } = this.props;
    let { record } = this.state;

    appActions.momCenter
      .updateMomBorrowRecord(record)
      .then(requestClose)
      .then(requestRefresh)
      .catch(err => console.warn(err));
  };
}

const Wrapper = styled.div`
  padding: 10px;
  position: relative;
`;

const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

export default connect(
  (state, ownProps) => ({
    users: Selectors.momCenter.getUsers(state)
  }),
  ActionCreator
)(MomBorrowRecordDetailDialog);
