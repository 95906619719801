import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as Widget from "../../../src/Components/Widget2";
import Dialog from "../../../src/Components/Dialog";
import * as Icon from "../../../src/Components/Icon";
import ActionCreator from "../../../src/ActionCreator";

const Wrapper = styled.div`
  padding: 10px;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #777777;
  padding: 10px;
`;

class AddSparePartDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sparePartName: "",
      sparePartUnitPrice: 0,
      sparePartTotal: "",
      isCreating: false
    };
  }

  render() {
    let {
      isCreating,
      sparePartName,
      sparePartUnitPrice,
      sparePartTotal,
      textAfterCreate
    } = this.state;
    let { toClose } = this.props;

    return (
      <Dialog>
        <Widget.DialogWrapper>
          <Title>新增品項</Title>
          <Widget.FieldContainer>
            <Widget.FormField stacked>
              <label>品項名稱</label>
              <input
                value={sparePartName}
                onChange={e => this.setState({ sparePartName: e.target.value })}
              />
            </Widget.FormField>

            <Widget.FormField stacked>
              <label>單價(項/元)</label>
              <input
                value={sparePartUnitPrice}
                onChange={e =>
                  this.setState({ sparePartUnitPrice: e.target.value })
                }
              />
            </Widget.FormField>

            <Widget.FormField stacked>
              <label>數量</label>
              <input
                value={sparePartTotal}
                onChange={e =>
                  this.setState({ sparePartTotal: e.target.value })
                }
              />
            </Widget.FormField>
          </Widget.FieldContainer>
          <div
            style={{ margin: 10, display: "flex", justifyContent: "flex-end" }}
          >
            {textAfterCreate && (
              <p style={{ color: "red", margin: 6 }}>{textAfterCreate}</p>
            )}
            {isCreating ? (
              <Widget.Center>
                <Widget.Spinner />
              </Widget.Center>
            ) : (
              <Widget.Row justify="center">
                <Widget.Button className="white-button" onClick={toClose}>
                  取消
                </Widget.Button>
                <Widget.Button onClick={this._CreateASparePartItem}>
                  新增
                </Widget.Button>
              </Widget.Row>
            )}
          </div>
        </Widget.DialogWrapper>
      </Dialog>
    );
  }

  _CreateASparePartItem = () => {
    let { appActions } = this.props;
    let { sparePartName, sparePartUnitPrice, sparePartTotal } = this.state;

    this.setState({ isCreating: true });
    appActions.momCenter
      .createSparePartItem(sparePartName, sparePartUnitPrice, sparePartTotal)
      .then(() =>
        this.setState({ isCreating: false, textAfterCreate: "新增成功" })
      )
      .catch(() =>
        this.setState({ isCreating: true, textAfterCreate: "新增失敗" })
      );
  };
}
export default connect(
  null,
  ActionCreator
)(AddSparePartDialog);
