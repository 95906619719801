import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import ActionCreator from "../../../src/ActionCreator";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import MomPrecheckinEvalDetailDialog from "./MomPrecheckinEvalDetailDialog";
import ConfirmDialog from "./ConfirmDialog";

class MomPrecheckinEvalList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: true,
      precheckinEvalList: null,
      precheckinEval: null,
      showDetailDialog: false,
      showConfirmDialog: false
    };
  }

  componentDidMount() {
    this._updatePrecheckinEvalList();
  }

  render() {
    let { mother } = this.props;
    let {
      showSpinner,
      precheckinEvalList,
      precheckinEval,
      showDetailDialog,
      showConfirmDialog
    } = this.state;

    return (
      <Wrapper>
        <Widget.Row>
          <div
            style={{
              display: "flex",
              flex: 1,
              color: "#777777",
              fontWeight: "500",
              padding: 10
            }}
          >
            搜尋結果
          </div>
          <div style={{ margin: 10, display: "flex" }}>
            <Widget.Clickable
              onClick={() =>
                this.setState({ showDetailDialog: true, precheckinEval: null })
              }
            >
              <Widget.Button>新增評估表</Widget.Button>
            </Widget.Clickable>

            <Widget.Clickable onClick={() => this._updatePrecheckinEvalList()}>
              <Widget.Button className="white-button">重新整理</Widget.Button>
            </Widget.Clickable>
          </div>
        </Widget.Row>

        {!showSpinner &&
          precheckinEvalList != null &&
          precheckinEvalList.length === 0 && (
            <Widget.HintMessage>尚未建立此客戶相關紀錄!</Widget.HintMessage>
          )}

        {showSpinner ? (
          <Widget.Row>
            <Widget.Spinner />
          </Widget.Row>
        ) : (
          <Widget.Table>
            <Widget.TableContent lessTable>
              <Widget.Fields lessTable>
                <div className="field">編號</div>
                <div className="date-field">建立日期</div>
                <div className="field">入住評估表</div>
                <div className="field-padding" />
                <div className="field" />
              </Widget.Fields>

              {precheckinEvalList.map((item, i) => (
                <div className={i % 2 === 0 ? "row-even" : "row-odd"}>
                  <div className="row-item">{this._numberFormat(i + 1)}</div>
                  <div className="date-item">
                    {moment(item.created).format("YYYY-MM-DD")}
                  </div>
                  <div
                    className="row-item"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      cursor: "pointer"
                    }}
                    onClick={() =>
                      this.setState({
                        showDetailDialog: true,
                        precheckinEval: item
                      })
                    }
                  >
                    <img
                      src={"/images/pages/icon-check-detail.png"}
                      style={{ width: 20, height: 20 }}
                    />
                    <span style={{ color: "#4485D1", fontSize: 14 }}>
                      查看表單
                    </span>
                  </div>
                  <div className="row-item-padding" />
                  <div className="row-item">
                    <div
                      style={{ cursor: "pointer", color: "#4485D1" }}
                      onClick={() =>
                        this.setState({
                          showConfirmDialog: true,
                          precheckinEval: item
                        })
                      }
                    >
                      <Icon.Delete color={"#4485D1"} />
                      刪除
                    </div>
                  </div>
                </div>
              ))}
            </Widget.TableContent>
          </Widget.Table>
        )}

        {showDetailDialog && (
          <MomPrecheckinEvalDetailDialog
            mother={mother}
            record={precheckinEval}
            requestUpdate={this._updatePrecheckinEvalList}
            onClose={() => this.setState({ showDetailDialog: false })}
          />
        )}

        {showConfirmDialog && (
          <ConfirmDialog
            text="確定要刪除嗎？此動作無法還原。"
            confirmAction={this._deletePrecheckinEval}
            requestClose={() => this.setState({ showConfirmDialog: false })}
          />
        )}
      </Wrapper>
    );
  }

  _numberFormat = n => {
    if (n < 10) {
      n = "00" + n;
    }

    if (n > 9 && n < 100) {
      n = "0" + n;
    }

    return n;
  };

  _updatePrecheckinEvalList = () => {
    let { mother, appActions } = this.props;

    this.setState({ showSpinner: true });
    return appActions.momCenter
      .fetchPrecheckinEvalList({ mother: mother.id })
      .then(precheckinEvalList => this.setState({ precheckinEvalList }))
      .then(() => this.setState({ showSpinner: false }))
      .catch(err => console.warn(err));
  };

  _deletePrecheckinEval = () => {
    let { appActions } = this.props;
    let { precheckinEval } = this.state;
    console.log("precheckinEval", precheckinEval);

    return appActions.momCenter
      .deletePrecheckinEval(precheckinEval.id)
      .then(() => this.setState({ showConfirmDialog: false }))
      .then(() => this._updatePrecheckinEvalList())
      .catch(err => console.warn(err));
  };
}

const Wrapper = styled.div``;

export default connect(
  null,
  ActionCreator
)(MomPrecheckinEvalList);
