import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import ActionCreator from "../../../src/ActionCreator";
import * as Widget from "../../Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import CalendarArrangement from "./CalendarArrangement";
import DailyRoomsArrangement from "./DailyRoomsArrangement";
import DailyIncubatorsArrangement from "./DailyIncubatorsArrangement";
import ConcernDialog from "./ConcernDialog";

const Wrapper = styled.div`
  height: 100vh;
  overflow: auto;
  background-color: #e8e8e8;
  display: flex;
  flex-direction: column;
`;

class DailyArrangementDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCalendar: true,
      showRoomsArrangement: false,
      showIncubatorsArrangement: false,
      openConcernDialog: true
    };
  }

  render() {
    let {
      showCalendar,
      showIncubatorsArrangement,
      showRoomsArrangement,
      openConcernDialog,
      date
    } = this.state;

    return (
      <Wrapper>
        <Widget.TabContainer>
          <Widget.Row
            align="flex-start"
            justify="space-between"
            css="flex-wrap: wrap-reverse;"
          >
            <Widget.Container>
              <Widget.TabItem
                selected={showCalendar == true}
                onClick={() =>
                  this.setState({
                    showCalendar: true,
                    showRoomsArrangement: false,
                    showIncubatorsArrangement: false
                  })
                }
              >
                日曆總覽
              </Widget.TabItem>
              <Widget.TabItem
                selected={showRoomsArrangement == true}
                onClick={() =>
                  this.setState({
                    showCalendar: false,
                    showRoomsArrangement: true,
                    showIncubatorsArrangement: false
                  })
                }
              >
                館內房況
              </Widget.TabItem>
              <Widget.TabItem
                selected={showIncubatorsArrangement == true}
                onClick={() =>
                  this.setState({
                    showCalendar: false,
                    showRoomsArrangement: false,
                    showIncubatorsArrangement: true
                  })
                }
              >
                館內保溫箱
              </Widget.TabItem>
            </Widget.Container>

            <Widget.Row align="center">
              <Widget.Button
                className="white-button"
                onClick={() => this.setState({ openConcernDialog: true })}
                css="margin-bottom: 10px;"
              >
                每日關懷
              </Widget.Button>
            </Widget.Row>
          </Widget.Row>

          {showCalendar && <CalendarArrangement />}
          {showRoomsArrangement && <DailyRoomsArrangement />}
          {showIncubatorsArrangement && <DailyIncubatorsArrangement />}
          {openConcernDialog && (
            <ConcernDialog
              onClose={() => this.setState({ openConcernDialog: false })}
            />
          )}
        </Widget.TabContainer>
      </Wrapper>
    );
  }
}

export default connect(
  null,
  ActionCreator
)(DailyArrangementDashboard);
