import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import styled from "styled-components";
import ActionCreator from "../../../src/ActionCreator";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import BabyHealthRecordDetailDialog from "./BabyHealthRecordDetailDialog";
import ConfirmDialog from "./ConfirmDialog";

class BabyHealthRecordList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records: null,
      selectedRecord: null,
      showSpinner: false,
      showDetailDialog: false,
      showConfirmDialog: false
    };
  }

  componentDidMount() {
    this._fetchRecords();
  }

  render() {
    let { appActions, selectedBaby } = this.props;
    let {
      records,
      selectedRecord,
      showSpinner,
      showDetailDialog,
      showConfirmDialog
    } = this.state;

    return (
      <Wrapper>
        <Widget.Row>
          <div
            style={{
              display: "flex",
              flex: 1,
              color: "#777777",
              fontWeight: "500",
              padding: 10
            }}
          >
            搜尋結果
          </div>

          <div style={{ margin: 10, display: "flex" }}>
            <Widget.Clickable
              onClick={() =>
                this.setState({ showDetailDialog: true, selectedRecord: null })
              }
            >
              <Widget.Button>新增寶寶健康紀錄</Widget.Button>
            </Widget.Clickable>
            <Widget.Clickable onClick={this._fetchRecords}>
              <Widget.Button className="white-button">重新整理</Widget.Button>
            </Widget.Clickable>
          </div>
        </Widget.Row>

        {!showSpinner && records != null && records.length === 0 && (
          <Widget.HintMessage>尚未建立該寶寶相關紀錄!</Widget.HintMessage>
        )}

        {showSpinner ? (
          <Widget.Spinner />
        ) : (
          <Widget.Table>
            <Widget.TableContent lessTable>
              <Widget.Fields lessTable>
                <div className="field">編號</div>
                <div className="date-field">建立日期</div>
                <div className="field">健康紀錄</div>
                <div className="field-padding" />
                <div className="field" />
              </Widget.Fields>

              {records &&
                records.map((record, i) => (
                  <div
                    className={i % 2 === 0 ? "row-even" : "row-odd"}
                    key={"record" + i}
                  >
                    <div className="row-item">{this._numberFormat(i + 1)}</div>
                    <div className="date-item">
                      {moment(record.created).format("YYYY-MM-DD")}
                    </div>
                    <div
                      className="row-item"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        cursor: "pointer"
                      }}
                      onClick={() =>
                        this.setState({
                          showDetailDialog: true,
                          selectedRecord: record
                        })
                      }
                    >
                      <img
                        src={"/images/pages/icon-check-detail.png"}
                        style={{ width: 20, height: 20 }}
                      />
                      <span style={{ color: "#4485D1", fontSize: 14 }}>
                        查看表單
                      </span>
                    </div>
                    <div className="row-item-padding" />
                    <div
                      className="row-item"
                      style={{ cursor: "pointer", color: "#4485D1" }}
                      onClick={() =>
                        this.setState({
                          showConfirmDialog: true,
                          selectedRecord: record
                        })
                      }
                    >
                      <Icon.Delete color={"#4485D1"} />
                      刪除
                    </div>
                  </div>
                ))}
            </Widget.TableContent>
          </Widget.Table>
        )}

        {showDetailDialog && (
          <BabyHealthRecordDetailDialog
            selectedBaby={selectedBaby}
            record={selectedRecord}
            requestClose={() => {
              this.setState({ showDetailDialog: false });
              this._fetchRecords();
            }}
          />
        )}

        {showConfirmDialog && (
          <ConfirmDialog
            text="確定要刪除嗎？此動作無法還原。"
            requestClose={() => this.setState({ showConfirmDialog: false })}
            confirmAction={() =>
              appActions.momCenter
                .deleteBabyHealthRecord(selectedRecord.id)
                .then(this._fetchRecords)
                .then(() => this.setState({ showConfirmDialog: false }))
                .catch(err => console.warn(err))
            }
          />
        )}
      </Wrapper>
    );
  }

  _numberFormat = n => {
    if (n < 10) {
      n = "00" + n;
    }

    if (n > 9 && n < 100) {
      n = "0" + n;
    }

    return n;
  };

  _fetchRecords = () => {
    let { appActions, selectedBaby } = this.props;

    this.setState({ showSpinner: true });
    appActions.momCenter
      .fetchBabyHealthRecord(selectedBaby.id)
      .then(records => this.setState({ records }))
      .then(() => this.setState({ showSpinner: false }))
      .catch(err => console.warn(err));
  };
}

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
`;

const Clickable = styled.div`
  cursor: pointer;
  transition: transform 0.2s;

  & :hover {
    transform: scale(0.9);
  }

  ${props => props.css || ""};
`;

const ListItem = styled.div`
  padding: 15px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-bottom: 10px;
  max-width: 400px;
`;

export default connect(
  null,
  ActionCreator
)(BabyHealthRecordList);
