import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../../../src/ActionCreator";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";

const Wrapper = styled.div`
  position: relative;
`;

const Container = styled.div`
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 20px;
  padding-bottom: 40px;
`;

const Title = styled.div`
  margin: 10px;
  width: 100px;
  font-weight: bold;
  font-size: 16px;
  color: #777777;
`;

const UpdateTime = styled.div`
  font-size: 12px;
  color: #dbdbdb;
`;

const Text = styled.div`
  font-size: 14px;
  color: #777777;
  ${props => props.css};
`;

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

class BabyInfoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      baby: { ...this.props.baby },
      isEditing: false,
      prevBabyData: null,
      showSpinner: false,
      errorMsg: ""
    };
  }

  render() {
    let { mother } = this.props;
    let { baby, isEditing, prevBabyData, showSpinner, errorMsg } = this.state;

    return (
      <Wrapper>
        <Container>
          <Widget.Row
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Title>個人資料</Title>
            {isEditing ? (
              <div>
                <Widget.Button
                  className="white-button"
                  css="width: 100px;"
                  onClick={() => {
                    this.setState({
                      isEditing: false,
                      baby: { ...prevBabyData }
                    });
                  }}
                >
                  取消
                </Widget.Button>
                <Widget.Button css="width: 100px;" onClick={this._updateBaby}>
                  儲存
                </Widget.Button>
              </div>
            ) : (
              <Widget.Button
                className="white-button"
                css="width: 100px;"
                onClick={() =>
                  this.setState({
                    isEditing: !isEditing,
                    prevBabyData: { ...baby }
                  })
                }
              >
                修改
              </Widget.Button>
            )}
          </Widget.Row>

          <Widget.Row wrap="true">
            <Widget.FormField stacked>
              <label>媽媽姓名</label>
              <input value={mother.name} disabled />
            </Widget.FormField>

            <Widget.FormField>
              {isEditing ? (
                <>
                  <Widget.Row
                    align="center"
                    value={baby.girl}
                    onClick={e =>
                      this.setState({
                        baby: {
                          ...baby,
                          girl: !baby.girl,
                          boy: false,
                          name: ""
                        },
                        errorMsg: ""
                      })
                    }
                  >
                    {baby.girl === true ? (
                      <Icon.RadioButtonChecked color="#777777" />
                    ) : (
                      <Icon.RadioButtonUnchecked color="#e9eff4" />
                    )}
                    <Text css="margin-left: 5px;">女寶寶</Text>
                  </Widget.Row>

                  <Widget.Row
                    align="center"
                    value={baby.boy}
                    onClick={e =>
                      this.setState({
                        baby: {
                          ...baby,
                          boy: !baby.boy,
                          girl: false,
                          name: ""
                        },
                        errorMsg: ""
                      })
                    }
                  >
                    {baby.boy === true ? (
                      <Icon.RadioButtonChecked color="#777777" />
                    ) : (
                      <Icon.RadioButtonUnchecked color="#e9eff4" />
                    )}
                    <Text css="margin-left: 5px;">男寶寶</Text>
                  </Widget.Row>
                </>
              ) : (
                <>
                  <Widget.Row align="center">
                    {baby.girl === true ? (
                      <Icon.RadioButtonChecked color="lightgray" />
                    ) : (
                      <Icon.RadioButtonUnchecked color="#e9eff4" />
                    )}
                    <Text css="margin-left: 5px;">女寶寶</Text>
                  </Widget.Row>

                  <Widget.Row align="center">
                    {baby.boy === true ? (
                      <Icon.RadioButtonChecked color="lightgray" />
                    ) : (
                      <Icon.RadioButtonUnchecked color="#e9eff4" />
                    )}
                    <Text css="margin-left: 5px;">男寶寶</Text>
                  </Widget.Row>
                </>
              )}
            </Widget.FormField>

            <Widget.FormField stacked>
              <label>寶寶生日</label>
              <input
                value={baby.birthday}
                onChange={e =>
                  this.setState({ baby: { ...baby, birthday: e.target.value } })
                }
                disabled={!isEditing}
              />
            </Widget.FormField>

            <Widget.FormField stacked>
              <label>寶寶出生體重</label>
              <input
                value={baby.weight}
                onChange={e =>
                  this.setState({ baby: { ...baby, weight: e.target.value } })
                }
                disabled={!isEditing}
              />
            </Widget.FormField>

            <Widget.FormField stacked>
              <label>生產方式</label>
              <select
                value={baby.delivery_method}
                onChange={e =>
                  this.setState({
                    baby: { ...baby, delivery_method: e.target.value }
                  })
                }
                disabled={!isEditing}
              >
                <option value="">---</option>
                <option value="CS">CS</option>
                <option value="NSD">NSD</option>
              </select>
            </Widget.FormField>

            <Widget.FormField stacked>
              <label>生產醫院</label>
              <input
                value={baby.born_hospitial}
                onChange={e =>
                  this.setState({
                    baby: { ...baby, born_hospitial: e.target.value }
                  })
                }
                disabled={!isEditing}
              />
            </Widget.FormField>
          </Widget.Row>
          {isEditing && (
            <div style={{ fontSize: 14, color: "red", marginLeft: 10 }}>
              {errorMsg}
            </div>
          )}
        </Container>

        <Widget.Row justify="flex-end">
          <UpdateTime>
            上次更新時間：
            {baby.updated.split("T")[0] +
              " " +
              baby.updated
                .split("T")[1]
                .split(".")[0]
                .slice(0, 5)}
          </UpdateTime>

          {showSpinner && <Widget.Spinner />}
        </Widget.Row>
      </Wrapper>
    );
  }

  _updateBaby = () => {
    let { appActions, onChange, mother, babyListRefresh } = this.props;
    let { baby } = this.state;

    if (baby.girl === true && baby.boy === true) {
      return this.setState({ errorMsg: "生理性別只能擇一！" });
    }

    if (baby.girl === false && baby.boy === false) {
      return this.setState({ errorMsg: "請選擇生理性別！" });
    }

    if (baby.birthday === "") {
      return this.setState({ errorMsg: "請填寫寶寶生日！" });
    }

    if (baby.weight === "") {
      return this.setState({ errorMsg: "請填寫寶寶出生體重！" });
    }

    if (baby.girl === true) {
      baby.name = `${mother.name}之女`;
    }

    if (baby.boy === true) {
      baby.name = `${mother.name}之子`;
    }

    this.setState({ showSpinner: true });
    delay(500)
      .then(() => appActions.momCenter.updateBaby(baby))
      .then(baby =>
        this.setState({ baby, showSpinner: false, isEditing: false })
      )
      .then(onChange(baby))
      .then(babyListRefresh)
      .catch(err => console.warn(err));
  };
}

export default connect(
  null,
  ActionCreator
)(BabyInfoForm);
