import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Selectors from "../../../src/Selectors";
import ActionCreator from "../../../src/ActionCreator";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import MomBorrowRecordDetailDialog from "./MomBorrowRecordDetailDialog";
import ConfirmDialog from "./ConfirmDialog";

class MomBorrowRecordList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records: null,
      selectedRecord: null,
      showSpinner: false,
      showDetailDialog: false,
      showConfirmDialog: false
    };
  }

  componentDidMount() {
    this._fetchRecords();
  }

  render() {
    let { appActions, mother, users } = this.props;
    let {
      records,
      selectedRecord,
      showSpinner,
      showDetailDialog,
      showConfirmDialog
    } = this.state;

    return (
      <Wrapper>
        <Widget.Row>
          <div
            style={{
              display: "flex",
              flex: 1,
              color: "#777777",
              fontWeight: "500",
              padding: 10
            }}
          >
            搜尋結果
          </div>
          <div style={{ margin: 10, display: "flex" }}>
            <Widget.Clickable
              onClick={() =>
                this.setState({ showDetailDialog: true, selectedRecord: null })
              }
            >
              <Widget.Button>新增借物紀錄</Widget.Button>
            </Widget.Clickable>

            <Widget.Clickable onClick={this._fetchRecords}>
              <Widget.Button className="white-button">重新整理</Widget.Button>
            </Widget.Clickable>
          </div>
        </Widget.Row>

        {!showSpinner && records != null && records.length === 0 && (
          <Widget.HintMessage>尚未建立此客戶相關紀錄!</Widget.HintMessage>
        )}

        {showSpinner ? (
          <Widget.Spinner />
        ) : (
          <Widget.Table>
            <Widget.TableContent>
              <Widget.Fields>
                <div className="field">借物內容</div>
                <div className="field">出借員工</div>
                <div className="field">出借日期</div>
                <div className="field">歸還狀態</div>
                <div className="field">歸還員工</div>
                <div className="field">歸還日期</div>
                <div className="field-padding" />
                <div className="field" />
                <div className="field" />
              </Widget.Fields>
              {records &&
                records.map((record, i) => (
                  <div
                    className={i % 2 === 0 ? "row-even" : "row-odd"}
                    key={"record" + i}
                  >
                    <div className="row-item">{record.note}</div>
                    <div className="row-item">
                      <select disabled>
                        <option>
                          {users.map((user, i) => {
                            if (user.id === record.borrow_staff) {
                              return user.user.username;
                            }
                          })}
                        </option>
                      </select>
                    </div>
                    <div className="row-item">{record.borrow_date}</div>
                    <div className="row-item">
                      {record.returned ? "已歸還" : "未歸還"}
                    </div>
                    <div className="row-item">
                      <select disabled>
                        <option>
                          {users.map((user, i) => {
                            if (user.id === record.returned_staff) {
                              return user.user.username;
                            }
                          })}
                        </option>
                      </select>
                    </div>
                    <div className="row-item">{record.returned_date}</div>
                    <div className="row-item-padding" />
                    <div className="row-item">
                      <div
                        style={{ cursor: "pointer", color: "#4485D1" }}
                        onClick={() =>
                          this.setState({
                            selectedRecord: record,
                            showDetailDialog: true
                          })
                        }
                      >
                        <Icon.Edit color={"#4485D1"} />
                        編輯
                      </div>
                    </div>
                    <div className="row-item">
                      <div
                        style={{ cursor: "pointer", color: "#4485D1" }}
                        onClick={() =>
                          this.setState({
                            selectedRecord: record,
                            showConfirmDialog: true
                          })
                        }
                      >
                        <Icon.Delete color={"#4485D1"} />
                        刪除
                      </div>
                    </div>
                  </div>
                ))}
            </Widget.TableContent>
          </Widget.Table>
        )}
        {showDetailDialog && (
          <MomBorrowRecordDetailDialog
            mother={mother}
            record={selectedRecord}
            requestClose={() => this.setState({ showDetailDialog: false })}
            requestRefresh={this._fetchRecords}
          />
        )}

        {showConfirmDialog && (
          <ConfirmDialog
            text="確定要刪除嗎？此動作無法還原。"
            requestClose={() => this.setState({ showConfirmDialog: false })}
            confirmAction={() =>
              appActions.momCenter
                .deleteMomBorrowRecord(selectedRecord.id)
                .then(this._fetchRecords)
                .then(() => this.setState({ showConfirmDialog: false }))
                .catch(err => console.warn(err))
            }
          />
        )}
      </Wrapper>
    );
  }

  _fetchRecords = () => {
    let { appActions, mother } = this.props;
    let { records } = this.state;

    this.setState({ showSpinner: true });
    appActions.momCenter
      .fetchMomBorrowRecord(mother.id)
      .then(records => this.setState({ records }))
      .then(() => this.setState({ showSpinner: false }))
      .catch(err => console.warn(err));
  };
}

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
`;

export default connect(
  (state, ownProps) => ({
    users: Selectors.momCenter.getUsers(state)
  }),
  ActionCreator
)(MomBorrowRecordList);
