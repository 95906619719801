import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../../../src/ActionCreator";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import MomListingDialog from "./MomListingDialog";
import MomVisitDetailDialog from "./MomVisitDetailDialog";
import AssignmentDetailDialog from "./AssignmentDetailDailog";
import ConfirmDialog from "./ConfirmDialog";

class CalendarArrangementGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createMother: true,
      openMomListingDialog: false,
      openMomVisitDetailDialog: false,
      openAssignmentDetailDialog: false,
      openConfirmDialog: false,

      visitRecord: null,
      assignmentRecord: null
    };
  }

  componentDidMount() {}

  render() {
    let {
      appActions,
      title,
      today,
      type,
      mothers,
      momLivingRecords = [],
      momExpectedDateRecords = [],
      babyLivingRecords = [],
      babyBornRecords = [],
      momEnterRoomRecords = [],
      momExitRoomRecords = [],
      momChangeRoomRecords = [],
      visitRecords = [],
      contractRecords = [],
      repairmentRecords = [],
      assignmentRecords = [],
      onRefreshVisitRecords,
      onRefreshAssignmentRecords,
      css
    } = this.props;
    let {
      createMother,
      openMomListingDialog,
      openMomVisitDetailDialog,
      openAssignmentDetailDialog,
      openConfirmDialog,
      visitRecord,
      assignmentRecord
    } = this.state;

    return (
      <Wrapper css={css}>
        <div className="title">
          {title}
          {type === "visit" && (
            <div
              className="add"
              onClick={() =>
                this.setState({
                  openMomListingDialog: true,
                  createMother: true
                })
              }
            >
              <Icon.Add style={{ width: 20, height: 20 }} color="#88add9" />
            </div>
          )}
          {type === "assignment" && (
            <div
              onClick={() =>
                this.setState({ openAssignmentDetailDialog: true })
              }
            >
              <Icon.Add style={{ width: 20, height: 20 }} color="#88add9" />
            </div>
          )}
        </div>

        {type === "visit" &&
          (visitRecords &&
            visitRecords
              .sort(
                (a, b) =>
                  new Date(a.visit_time).getTime() -
                  new Date(b.visit_time).getTime()
              )
              .map((record, i) => (
                <RecordItem
                  key={"visit-record-item" + i}
                  css={`
                    :hover {
                      border-radius: 10px;
                      background: #e8e8e8;
                    }
                  `}
                >
                  <Widget.Row align="center" justify="space-between">
                    <div
                      style={{ flex: 1 }}
                      onClick={() => {
                        appActions.momCenter.setTopNavState("mom");
                        appActions.momCenter.setMomDashboardState({
                          uiState: "selected",
                          selectedTab: "mother",
                          selectedMother: record.mother_data
                        });
                      }}
                    >
                      {record.visit_time.split("T")[1].slice(0, 5)}{" "}
                      {record.mother_data.name} 預約
                    </div>
                    <div
                      onClick={() =>
                        this.setState({
                          visitRecord: record,
                          openConfirmDialog: true
                        })
                      }
                    >
                      <Icon.Close
                        style={{ width: 15, height: 15 }}
                        color="black"
                      />
                    </div>
                  </Widget.Row>
                </RecordItem>
              )))}

        {type === "expected" &&
          (momExpectedDateRecords &&
            momExpectedDateRecords.map((record, i) => (
              <RecordItem key={"mom-expected-date-record-item" + i}>
                {record.expected_date} {record.mother_data.name} 預產
              </RecordItem>
            )))}

        {type === "actual" &&
          (babyBornRecords &&
            babyBornRecords
              .filter(record => record.birthday === today)
              .map((record, i) => (
                <RecordItem key={"baby-born-record-item" + i}>
                  {record.name} 報囍
                </RecordItem>
              )))}

        {type === "inside" && (
          <RecordItem>
            <div>媽媽人數：{this._getHitRecordLength(momLivingRecords)} 人</div>
            <div>
              寶寶人數：{this._getHitRecordLength(babyLivingRecords)} 人
            </div>
          </RecordItem>
        )}

        {type === "living" && (
          <>
            {momEnterRoomRecords &&
              momEnterRoomRecords
                .filter(record => record.from_date === today)
                .map((record, i) => (
                  <RecordItem
                    key={"mom-enter-room-item" + i}
                    needHighlight={!record.checkin}
                  >
                    {record.mother.name} 入住 {record.room.name}
                  </RecordItem>
                ))}
            {momExitRoomRecords &&
              momExitRoomRecords
                .filter(record => record.end_date === today)
                .map((record, i) => (
                  <RecordItem key={"mom-exit-room-item" + i}>
                    {record.mother.name} 出住 {record.room.name}
                  </RecordItem>
                ))}
          </>
        )}

        {type === "change" &&
          (momChangeRoomRecords &&
            momChangeRoomRecords
              .filter(record => record.from_date === today)
              .map((record, i) => (
                <RecordItem key={"mom-change-room-item" + i}>
                  {record.mother.name} 換房 {record.room.name}
                </RecordItem>
              )))}

        {type === "reapir" &&
          repairmentRecords &&
          repairmentRecords.map((record, i) => (
            <RecordItem key={"repair-record-item" + i}>
              <div>
                {record.room.name} {record.estimated_time.slice(0, 5)}{" "}
                {record.summary}
              </div>
              <div />
            </RecordItem>
          ))}

        {type === "assignment" &&
          (assignmentRecords &&
            assignmentRecords.map((record, i) => (
              <RecordItem
                key={"assignment-record-item" + i}
                css={`
                  :hover {
                    border-radius: 10px;
                    background: #e8e8e8;
                  }
                `}
              >
                <Widget.Row align="center" justify="space-between">
                  <div
                    style={{
                      flex: 1,
                      color: record.status ? "#777777" : "#e4a5b3"
                    }}
                    onClick={() =>
                      this.setState({
                        assignmentRecord: record,
                        openAssignmentDetailDialog: true
                      })
                    }
                  >
                    {record.estimated_time} {record.note}
                  </div>
                  <div
                    onClick={() =>
                      this.setState({
                        assignmentRecord: record,
                        openConfirmDialog: true
                      })
                    }
                  >
                    <Icon.Close
                      style={{ width: 15, height: 15 }}
                      color="black"
                    />
                  </div>
                </Widget.Row>
              </RecordItem>
            )))}

        {openMomListingDialog && (
          <MomListingDialog
            from="calendar"
            create={createMother}
            date={today}
            onRefreshVisitRecords={onRefreshVisitRecords}
            requestClose={() => this.setState({ openMomListingDialog: false })}
          />
        )}

        {openMomVisitDetailDialog && (
          <MomVisitDetailDialog
            record={visitRecord}
            mother={visitRecord.mother.id}
            requestClose={() =>
              this.setState({ openMomVisitDetailDialog: false })
            }
            requestRefresh={onRefreshVisitRecords}
          />
        )}

        {openAssignmentDetailDialog && (
          <AssignmentDetailDialog
            date={today}
            record={assignmentRecord}
            requestClose={() =>
              this.setState({ openAssignmentDetailDialog: false })
            }
            requestRefresh={onRefreshAssignmentRecords}
          />
        )}

        {openConfirmDialog && (
          <ConfirmDialog
            text="確定要刪除嗎？此動作無法還原。"
            requestClose={() => this.setState({ openConfirmDialog: false })}
            confirmAction={() => {
              if (this.state.assignmentRecord) {
                appActions.momCenter
                  .deleteAssignment(assignmentRecord.id)
                  .then(() => onRefreshAssignmentRecords())
                  .then(() => this.setState({ showConfirmDialog: false }))
                  .catch(err => console.warn(err));
              } else if (this.state.visitRecord) {
                appActions.momCenter
                  .deleteVisit(visitRecord.id)
                  .then(() => onRefreshVisitRecords())
                  .then(() => this.setState({ showConfirmDialog: false }))
                  .catch(err => console.warn(err));
              }
            }}
          />
        )}
      </Wrapper>
    );
  }

  _getHitRecordLength = records => {
    let { today } = this.props;
    let hitRecords = [];
    let todayMS = new Date(today).getTime();

    hitRecords = records
      .filter(record => record.checkin === true)
      .filter(record => {
        let fromDateMS = new Date(record.from_date).getTime();
        let endDateMS = new Date(record.end_date).getTime();

        if (todayMS >= fromDateMS && todayMS < endDateMS) {
          return true;
        } else {
          return false;
        }
      });

    return hitRecords.length;
  };
}

const Wrapper = styled.div`
  border-bottom: 1px solid lightgray;
  height: 70px;
  overflow: auto;
  padding: 5px;
  color: #777777;

  & .title {
    border-radius: 5px;
    padding: 2px 5px 0px;
    display: flex;
  }

  & .add {
    transition: transform 150ms ease;
  }

  & .add:active {
    transform: scale(0.88);
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }

  ${props => props.css || ""};
`;

const RecordItem = styled.div`
  padding: 2px 5px;
  font-size: 10px;
  color: ${props => (props.needHighlight ? "#E4A5B3" : "#777777")};

  ${props => props.css || ""};
`;

export default connect(
  null,
  ActionCreator
)(CalendarArrangementGrid);
