import React from "react";
import moment from "moment";
import styled from "styled-components";
import { connect } from "react-redux";
import * as Widget from "../../Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import ActionCreator from "../../../src/ActionCreator";
import Dialog from "../../../src/Components/Dialog";
import Selectors from "../../../src/Selectors";

const Wrapper = styled.div`
  padding: 10px;
  position: relative;
`;

const Close = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

class ArrangementElfDialog extends React.Component {
  constructor(props) {
    super(props);

    let { record, mother, isExtendDaysView } = this.props;
    let isCreatingView = false;
    let from_date = new Date().toISOString().split("T")[0];

    if (!record) {
      isCreatingView = true;
      record = {
        mother: mother.id,
        from_date,
        duration: "",
        enter: "",
        room: "",
        delivery_method: "",
        baby_count: "",
        birth_index: "",
        concern_checkin: false,
        checkin: false
      };
    }

    if (isExtendDaysView) {
      record = {
        id: record.id,
        mother: mother.id,
        from_date: record.end_date,
        duration: record.duration,
        room: record.room.id,
        enter: "expand"
      };
    } else if (!isCreatingView && !isExtendDaysView) {
      //換房
      record = {
        ...record,
        change_date: record.from_date,
        enter: "change"
      };
    }

    this.state = {
      record: record,
      usedRoomsRecords: null,
      emptyRooms: null,
      isCreatingView,
      showSpinner: false,
      selectedRoom: null,
      originalRoom: record.room.id,
      extendsDays: 0,
      isExtendDaysView,
      errorMessage: ""
    };
  }

  render() {
    let { requestClose } = this.props;
    let {
      record,
      emptyRooms,
      isCreatingView,
      isExtendDaysView,
      selectedRoom,
      originalRoom,
      extendsDays,
      errorMessage
    } = this.state;

    return (
      <Dialog>
        <Wrapper>
          {isCreatingView ? (
            <Widget.DialogTitle>新增排房</Widget.DialogTitle>
          ) : isExtendDaysView ? (
            <Widget.DialogTitle>延長天數</Widget.DialogTitle>
          ) : (
            <Widget.DialogTitle>換房</Widget.DialogTitle>
          )}
          {isCreatingView ? (
            <Widget.Row wrap={"true"}>
              <Widget.FormField stacked>
                <label>起始日期</label>
                <input
                  type="date"
                  value={record.from_date}
                  onChange={e =>
                    this.setState({
                      record: {
                        ...record,
                        from_date: e.target.value,
                        room: ""
                      },
                      emptyRooms: null
                    })
                  }
                />
              </Widget.FormField>
              <Widget.FormField stacked>
                <label>天數</label>
                <input
                  type="number"
                  value={record.duration}
                  onChange={e =>
                    this.setState({
                      record: {
                        ...record,
                        duration: e.target.value,
                        room: ""
                      },
                      emptyRooms: null
                    })
                  }
                />
              </Widget.FormField>
              <Widget.FormField stacked>
                <label>入住原因</label>
                <select
                  value={record.enter}
                  onChange={e =>
                    this.setState({
                      record: {
                        ...record,
                        enter: e.target.value,
                        room: ""
                      },
                      emptyRooms: null
                    })
                  }
                >
                  <option value="">---</option>
                  <option value="new">新入住</option>
                  <option value="change">換房</option>
                  <option value="expand">延長天數</option>
                </select>
              </Widget.FormField>

              <Widget.FormField stacked>
                <label>生產方式</label>
                <select
                  value={record.delivery_method}
                  onChange={e =>
                    this.setState({
                      record: {
                        ...record,
                        delivery_method: e.target.value,
                        room: ""
                      },
                      emptyRooms: null
                    })
                  }
                >
                  <option value="">---</option>
                  <option value="CS">CS</option>
                  <option value="NSD">NSD</option>
                </select>
              </Widget.FormField>

              <Widget.FormField stacked>
                <label>胎數</label>
                <input
                  type="number"
                  value={record.baby_count}
                  onChange={e =>
                    this.setState({
                      record: {
                        ...record,
                        baby_count: e.target.value,
                        room: ""
                      },
                      emptyRooms: null
                    })
                  }
                />
              </Widget.FormField>

              <Widget.FormField stacked>
                <label>胎次</label>
                <input
                  type={record.birth_index}
                  onChange={e =>
                    this.setState({
                      record: {
                        ...record,
                        birth_index: e.target.value,
                        room: ""
                      },
                      emptyRooms: null
                    })
                  }
                />
              </Widget.FormField>

              <Widget.FormField inputWidth="30px">
                <input
                  type="checkbox"
                  checked={record.concern_checkin}
                  onChange={e =>
                    this.setState({
                      record: {
                        ...record,
                        concern_checkin: e.target.checked,
                        room: ""
                      },
                      emptyRooms: null
                    })
                  }
                />
                <label>入住關懷提醒</label>
              </Widget.FormField>

              <Widget.FormField inputWidth="30px">
                <input
                  type="checkbox"
                  checked={record.checkin}
                  onChange={e =>
                    this.setState({
                      record: {
                        ...record,
                        checkin: e.target.checked,
                        room: ""
                      },
                      emptyRooms: null
                    })
                  }
                />
                <label>已入住</label>
              </Widget.FormField>
            </Widget.Row>
          ) : isExtendDaysView ? (
            <>
              <Widget.FormField>
                <label>延長天數</label>
                <input
                  value={extendsDays}
                  onChange={e => this.setState({ extendsDays: e.target.value })}
                />
              </Widget.FormField>
              <Widget.FormField>
                <label>入住原因</label>
                <select value={record.enter}>
                  <option value="expand">延長天數</option>
                </select>
              </Widget.FormField>
            </>
          ) : (
            //換房
            <Widget.Col>
              <Widget.FormField>
                <label>換房日期</label>
                <input
                  type="date"
                  value={record.change_date}
                  onChange={e =>
                    this.setState({
                      record: { ...record, change_date: e.target.value },
                      errorMessage: "",
                      emptyRooms: null
                    })
                  }
                />
              </Widget.FormField>
              <div style={{ marginLeft: 15, fontSize: 10 }}>
                可設定換房日期為：{moment(new Date()).format("YYYY-MM-DD")} ~{" "}
                {record.end_date}
              </div>
              {new Date() - new Date(record.from_date) > 0 &&
                new Date() - new Date(record.end_date) < 0 && (
                  <Widget.FormField>
                    <label style={{ color: "orange" }}>目前剩餘天數：</label>
                    <input value={this._getRemainingDays()} disabled={true} />
                  </Widget.FormField>
                )}
              <Widget.FormField>
                <label>目前居住房間: </label>
                <input value={record.room.name} disabled={true} />
              </Widget.FormField>
              <Widget.FormField>
                <label>入住原因</label>
                <select value={record.enter}>
                  <option value="change">換房</option>
                </select>
              </Widget.FormField>
            </Widget.Col>
          )}

          {errorMessage && (
            <div style={{ marginLeft: 10, color: "red", paddingBottom: 10 }}>
              {errorMessage}
            </div>
          )}

          <Widget.Button onClick={this._getValidRooms}>搜尋空房</Widget.Button>

          <Widget.Row wrap css="padding: 10px;">
            {record.duration &&
              emptyRooms &&
              emptyRooms.map((emptyRoom, i) => (
                <div key={"emptyRoom" + i}>
                  <Widget.Button
                    css="margin-left: 0px; margin-bottom: 10px; margin-right: 10px; width: 60px;"
                    color={emptyRoom.id == record.room ? "lightblue" : "grey"}
                    key={emptyRoom.id}
                    value={emptyRoom.id}
                    onClick={e =>
                      this.setState({
                        record: { ...record, room: e.target.value },
                        selectedRoom: emptyRoom.id
                      })
                    }
                  >
                    {emptyRoom.name}
                  </Widget.Button>
                </div>
              ))}
          </Widget.Row>

          <Widget.Button
            onClick={() => {
              if (isExtendDaysView) {
                if (originalRoom == selectedRoom) {
                  return this._confirmUpdate(
                    record.room,
                    record.duration + parseInt(extendsDays)
                  );
                } else {
                  return this._confirmCreate(record.from_date, extendsDays);
                }
              }

              if (isCreatingView) {
                return this._confirmCreate(record.from_date, record.duration);
              } else {
                //換房
                this._confirmUpdate(
                  originalRoom,
                  Math.floor(
                    (new Date(record.change_date) -
                      new Date(record.from_date)) /
                      (3600 * 24 * 1000)
                  )
                );
                this._confirmCreate(
                  new Date(record.change_date).toISOString().split("T")[0],
                  Math.floor(
                    (new Date(record.end_date) - new Date(record.change_date)) /
                      (3600 * 24 * 1000)
                  )
                );
              }
            }}
          >
            確認新增
          </Widget.Button>
          <Close onClick={requestClose}>
            <Icon.Close />
          </Close>
        </Wrapper>
      </Dialog>
    );
  }

  _getRemainingDays = () => {
    let { record } = this.state;
    let changeDateMS = new Date(record.change_date).getTime();
    let endDateMS = new Date(record.end_date).getTime();

    if (record.change_date === record.from_date) {
      let result = Math.floor(
        (new Date(record.end_date) - new Date()) / (3600 * 24 * 1000)
      );
      if (isNaN(result) || result < 0) {
        return "---";
      }
      return result;
    } else {
      let result =
        Math.floor(endDateMS - changeDateMS - 86400000) / (3600 * 24 * 1000);
      if (isNaN(result) || result < 0) {
        return "---";
      } else {
        return result;
      }
    }
  };

  _confirmCreate = (fr_date, dur) => {
    let { appActions, requestClose, requestRefresh } = this.props;
    let { record } = this.state;

    this.setState({ showSpinner: true });
    appActions.momCenter
      .createMomLivingRecord({ ...record, from_date: fr_date, duration: dur })
      .then(() => {
        this.setState({ showSpinner: false });
        requestClose();
        requestRefresh();
      })
      .catch(err => {
        console.warn(err);
        this.setState({ showSpinner: false });
      });
  };

  _confirmUpdate = (origin_room, dur) => {
    let { appActions, requestClose, requestRefresh } = this.props;
    let { record } = this.state;
    this.setState({ showSpinner: true });

    appActions.momCenter
      .updateMomLivingRecord({ ...record, room: origin_room, duration: dur })
      .then(() => {
        this.setState({ showSpinner: false });
        requestClose();
        requestRefresh();
      })
      .catch(err => {
        console.warn(err);
        this.setState({ showSpinner: false });
      });
  };

  _getValidRooms = () => {
    let { appActions } = this.props;
    let { isCreatingView, record } = this.state;

    if (
      (isCreatingView === true && record.from_date === "") ||
      record.duration === ""
    ) {
      return this.setState({ errorMessage: "請輸入起始日期及天數！" });
    } else if (record.enter === "") {
      return this.setState({ errorMessage: "請輸入入住原因！" });
    } else if (this._getRemainingDays() < 0) {
      return this.setState({ errorMessage: "換房日期不可大於結束日期！" });
    } else if (this._getRemainingDays() >= record.duration) {
      return this.setState({ errorMessage: "換房日期不可小於起始日期！" });
    }

    this.setState({ showSpinner: true });
    appActions.momCenter
      .searchMomLivingRecordsByDate({
        from_date: record.from_date,
        duration: record.duration
      })
      .then(usedRoomsRecords =>
        this.setState({ usedRoomsRecords, showSpinner: false })
      )
      .then(this._filterLivingRooms)
      .then(emptyRooms => this.setState({ emptyRooms }))
      .catch(err => {
        console.warn(err);
        this.setState({ showSpinner: false });
      });
  };

  _filterLivingRooms = () => {
    let { rooms } = this.props;
    let { usedRoomsRecords } = this.state;

    return rooms.filter(room => {
      let used = usedRoomsRecords.find(record => record.room.id === room.id);
      return !used;
    });
  };
}

export default connect(
  (state, ownProps) => ({
    rooms: Selectors.momCenter.getRooms(state)
  }),
  ActionCreator
)(ArrangementElfDialog);
