import React, { Component } from "react";
import styled from "styled-components";
import CalendarArrangementGrid from "./CalenderArrangementtGrid";

class CalendarArrangementCell extends Component {
  render() {
    let {
      year,
      month,
      date,
      mothers,
      momLivingRecords,
      momExpectedDateRecords,
      babyLivingRecords,
      babyBornRecords,
      momEnterRoomRecords,
      momExitRoomRecords,
      momChangeRoomRecords,
      visitRecords,
      contractRecordsByExpiredDate,
      contractRecordsByActualDate,
      repairmentRecords,
      assignmentRecords,
      onRefreshVisitRecords,
      onRefreshAssignmentRecords
    } = this.props;
    let today = `${year}-${("00" + (month + 1)).slice(-2)}-${(
      "00" + date
    ).slice(-2)}`;

    return (
      <Wrapper>
        <Title>{date}</Title>
        <CalendarArrangementGrid
          title="預約參觀"
          type="visit"
          today={today}
          visitRecords={visitRecords}
          onRefreshVisitRecords={onRefreshVisitRecords}
          css="height: 140px;"
        />
        <CalendarArrangementGrid
          title="預產期"
          type="expected"
          today={today}
          mothers={mothers}
          contractRecords={contractRecordsByExpiredDate}
          momExpectedDateRecords={momExpectedDateRecords}
          css="height: 55px;"
        />
        <CalendarArrangementGrid
          title="寶寶報囍"
          type="actual"
          today={today}
          contractRecords={contractRecordsByActualDate}
          babyBornRecords={babyBornRecords}
        />
        <CalendarArrangementGrid
          title="館內人數"
          type="inside"
          today={today}
          momLivingRecords={momLivingRecords}
          babyLivingRecords={babyLivingRecords}
        />
        <CalendarArrangementGrid
          title="出入住"
          type="living"
          today={today}
          momEnterRoomRecords={momEnterRoomRecords}
          momExitRoomRecords={momExitRoomRecords}
        />
        <CalendarArrangementGrid
          title="媽媽換房"
          type="change"
          today={today}
          momChangeRoomRecords={momChangeRoomRecords}
          css="height: 55px;"
        />
        <CalendarArrangementGrid
          title="修繕房間"
          type="reapir"
          today={today}
          repairmentRecords={repairmentRecords}
        />
        <CalendarArrangementGrid
          title="交辦事項"
          type="assignment"
          today={today}
          assignmentRecords={assignmentRecords}
          onRefreshAssignmentRecords={onRefreshAssignmentRecords}
          css="height: 120px;"
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  border-left: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  width: 100%;
`;

const Title = styled.div`
  background-color: #88add8;
  padding: 5px 15px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  color: white;
`;

export default CalendarArrangementCell;
