import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as Widget from "../../../src/Components/Widget2";
import ActionCreator from "../../../src/ActionCreator";
import Dialog from "../../../src/Components/Dialog";
import moment from "moment";
import Selectors from "../../../src/Selectors";

class CheckoutElfDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contracts: null,
      billings: null,
      loginSection: false,
      username: null,
      password: null,
      checkoutStatus: false
    };
  }
  componentDidMount() {
    this._fetchContracts();
    this._fetchBillings();
  }

  render() {
    let { requestClose, mother } = this.props;
    let {
      contracts,
      billings,
      username,
      password,
      loginSection,
      checkoutStatus
    } = this.state;

    let contractUnpaidTotal =
      contracts &&
      contracts
        .filter(contract => contract.is_paid === false)
        .map(contract => contract.price - contract.deposit)
        .reduce((acc, b) => acc + b, 0);
    let billingUnpaidTotal =
      billings &&
      billings
        .filter(billing => billing.is_paid === false)
        .map(billing => billing.price)
        .reduce((acc, b) => acc + b, 0);

    return (
      <Dialog>
        <Widget.DialogWrapper>
          <Widget.DialogTitle>退房結帳</Widget.DialogTitle>
          <div
            style={{
              maxHeight: "280px",
              borderBottom: "2px solid #eaeaea",
              overflowY: "auto"
            }}
          >
            <Widget.Table>
              <Widget.TableContent lessTable css={"width: 525px;"}>
                <Widget.Fields lessTable>
                  <div className="big-field">合約</div>
                  <div className="field">數量</div>
                  <div className="field">金額</div>
                  <div className="field">付款狀態</div>
                </Widget.Fields>
                {contracts &&
                  contracts.map((contract, i) => (
                    <div className={i % 2 === 0 ? "row-even" : "row-odd"}>
                      <div className="big-item">
                        合約編號
                        {contract.contract_number}
                        (訂金:
                        {contract.deposit})
                      </div>
                      <div className="row-item" />
                      <div className="row-item">
                        {contract.price - contract.deposit}
                      </div>
                      <div className="row-item">
                        {contract.is_paid ? "已付款" : "未付款"}
                      </div>
                    </div>
                  ))}
              </Widget.TableContent>
            </Widget.Table>
            <Widget.Table>
              <Widget.TableContent lessTable css={"width: 525px;"}>
                <Widget.Fields lessTable>
                  <div className="big-field">品項</div>
                  <div className="field">備品數量</div>
                  <div className="field">金額</div>
                  <div className="field">付款狀態</div>
                </Widget.Fields>
                {billings &&
                  billings.map((billing, i) => (
                    <div className={i % 2 === 0 ? "row-even" : "row-odd"}>
                      <div className="big-item">{billing.title}</div>
                      <div className="row-item">
                        {billing.spare_part_counts}
                      </div>
                      <div className="row-item">{billing.price}</div>
                      <div className="row-item">
                        {billing.is_paid ? "已付款" : "未付款"}
                      </div>
                    </div>
                  ))}
              </Widget.TableContent>
            </Widget.Table>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {loginSection && (
              <div>
                <Widget.FormField inputHeight={"20px"}>
                  <label>帳號:</label>
                  <input
                    value={username}
                    onChange={e => this.setState({ username: e.target.value })}
                  />
                </Widget.FormField>
                <Widget.FormField inputHeight={"20px"}>
                  <label>密碼:</label>
                  <input
                    value={password}
                    onChange={e => this.setState({ password: e.target.value })}
                  />
                </Widget.FormField>
              </div>
            )}
            <div style={{ margin: 20, color: "#777777", fontSize: 14 }}>
              <div>
                未付款合約: {contractUnpaidTotal}元 + 品項: {billingUnpaidTotal}
                元
              </div>
              合計
              <span
                style={{ color: "#4485D1", fontSize: 20 }}
              >{`${contractUnpaidTotal + billingUnpaidTotal}`}</span>
              元
            </div>
          </div>
          <div
            style={{ margin: 10, display: "flex", justifyContent: "flex-end" }}
          >
            {loginSection ? (
              <Widget.Button
                color={"Navy"}
                onClick={() => {
                  this._confirmCheckout();
                }}
              >
                確認結帳
              </Widget.Button>
            ) : (
              <Widget.Button
                onClick={() => this.setState({ loginSection: true })}
              >
                結帳
              </Widget.Button>
            )}
            <Widget.Button
              color={"#ffffff"}
              textColor={"#777777"}
              css={"border: 1px solid #DFDFDF"}
              onClick={requestClose}
            >
              取消
            </Widget.Button>
          </div>
          {checkoutStatus && (
            <div style={{ color: "red", textAlign: "right" }}>
              以{username}
              帳號結帳成功!
            </div>
          )}
        </Widget.DialogWrapper>
      </Dialog>
    );
  }
  _fetchContracts = () => {
    let { appActions, mother } = this.props;
    appActions.momCenter
      .searchContracts({ mother })
      .then(contracts => this.setState({ contracts }));
  };

  _fetchBillings = () => {
    let { appActions, mother } = this.props;
    appActions.momCenter
      .fetchMomBillingRecord(mother)
      .then(billings => this.setState({ billings }));
  };

  _confirmCheckout = () => {
    let { appActions, mother } = this.props;
    let { username, password } = this.state;

    appActions.momCenter
      .momCheckout({ id: mother, username, password })
      .then(() => this.setState({ checkoutStatus: true }))
      .then(() => {
        this._fetchContracts();
        this._fetchBillings();
      });
  };
}

export default connect(
  null,
  ActionCreator
)(CheckoutElfDialog);
