import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import ActionCreator from "../../../src/ActionCreator";
import ContractDetailDialog from "./ContractDetailDialog";
import ConfirmDialog from "./ConfirmDialog";
import Selectors from "../../../src/Selectors";

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
`;

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

class ContractList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records: null,
      visitLatestRecord: null,
      selectedRecord: null,
      showSpinner: false,
      showDetailDialog: false,
      showConfirmDialog: false
    };

    let { mother, appActions } = this.props;
    this.listApi = appActions.momCenter.searchContracts.bind(null, {
      mother: mother.id
    });
    this.deleteApi = appActions.momCenter.deleteContract;
  }

  componentDidMount() {
    this._fetchRecords();
  }

  _fetchRecords = () => {
    let { mother, appActions } = this.props;
    this.setState({ showSpinner: true });
    delay(500)
      .then(this.listApi)
      .then(records => {
        this.setState({ records });
      })
      .then(() => appActions.momCenter.searchVisits({ mother: mother.id }))
      .then(records =>
        this.setState({ visitLatestRecord: records[0], showSpinner: false })
      )
      .catch(err => console.warn(err));
  };

  render() {
    let { mother } = this.props;
    let {
      records,
      visitLatestRecord,
      selectedRecord,
      showSpinner,
      showDetailDialog,
      showConfirmDialog
    } = this.state;

    return (
      <Wrapper>
        <Widget.Row>
          <div
            style={{
              display: "flex",
              flex: 1,
              color: "#777777",
              fontWeight: "500",
              padding: 10
            }}
          >
            搜尋結果
          </div>
          <div style={{ margin: 10, display: "flex" }}>
            <Widget.Clickable
              onClick={() =>
                this.setState({ showDetailDialog: true, selectedRecord: null })
              }
            >
              <Widget.Button>新增合約</Widget.Button>
            </Widget.Clickable>

            <Widget.Clickable onClick={this._fetchRecords}>
              <Widget.Button className="white-button">重新整理</Widget.Button>
            </Widget.Clickable>
          </div>
        </Widget.Row>

        {!showSpinner && records != null && records.length === 0 && (
          <Widget.HintMessage>尚未建立此客戶相關紀錄!</Widget.HintMessage>
        )}

        {showSpinner ? (
          <Widget.Row>
            <Widget.Spinner />
          </Widget.Row>
        ) : (
          <Widget.Table>
            <Widget.TableContent>
              <Widget.Fields>
                <div className="field">合約編號</div>
                <div className="date-field">簽訂日期</div>
                <div className="date-field">預產日期</div>
                <div className="field">價錢</div>
                <div className="field">入住天數</div>
                <div className="field">訂金</div>
                <div className="field">訂金狀態</div>

                <div className="field-padding" />
                <div className="field" />
                <div className="field" />
              </Widget.Fields>
              {records &&
                records.map((record, i) => (
                  <div
                    className={i % 2 === 0 ? "row-even" : "row-odd"}
                    key={"contract-list" + i}
                  >
                    <div className="row-item">{record.contract_number}</div>
                    <div className="date-item">{record.contract_date}</div>
                    <div className="date-item">{record.expected_date}</div>
                    <div className="row-item">{record.price || "---"}</div>
                    <div className="row-item">{record.stay}</div>
                    <div className="row-item">{record.deposit}</div>
                    <div className="row-item">
                      {record.is_paid_deposit ? "已付訂金" : "未付訂金"}
                    </div>

                    <div className="row-item-padding" />
                    <div className="row-item">
                      <div
                        style={{ cursor: "pointer", color: "#4485D1" }}
                        onClick={() =>
                          this.setState({
                            selectedRecord: record,
                            showDetailDialog: true
                          })
                        }
                      >
                        <Icon.Edit color={"#4485D1"} />
                        編輯
                      </div>
                    </div>
                    <div className="row-item">
                      <div
                        style={{ cursor: "pointer", color: "#4485D1" }}
                        onClick={() =>
                          this.setState({
                            selectedRecord: record,
                            showConfirmDialog: true
                          })
                        }
                      >
                        <Icon.Delete color={"#4485D1"} />
                        刪除
                      </div>
                    </div>
                  </div>
                ))}
            </Widget.TableContent>
          </Widget.Table>
        )}
        {showDetailDialog && (
          <ContractDetailDialog
            mother={mother}
            visitLatestRecord={visitLatestRecord}
            record={selectedRecord}
            requestClose={() => this.setState({ showDetailDialog: false })}
            requestRefresh={this._fetchRecords}
          />
        )}

        {showConfirmDialog && (
          <ConfirmDialog
            text="確定要刪除嗎？此動作無法還原。"
            requestClose={() => this.setState({ showConfirmDialog: false })}
            confirmAction={() => {
              this.deleteApi(selectedRecord.id)
                .then(this._fetchRecords)
                .then(() => this.setState({ showConfirmDialog: false }))
                .catch(err => console.warn(err));
            }}
          />
        )}
      </Wrapper>
    );
  }
}

export default connect(
  state => ({
    users: Selectors.momCenter.getUsers(state)
  }),
  ActionCreator
)(ContractList);
