import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Selectors from "../../../src/Selectors";
import ActionCreator from "../../../src/ActionCreator";
import * as Widget from "../../Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import BabyMomRoomingInRecordDetailDialog from "./BabyMomRoomingInRecordDetailDialog";
import ConfirmDialog from "./ConfirmDialog";

class BabyMomRoomingInRecordList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: true,
      records: null,
      selectedRecord: null,
      showDetailDialog: false
    };
  }

  componentDidMount() {
    this._fetchRecords();
  }

  render() {
    let { appActions, selectedBaby, users } = this.props;
    let {
      showSpinner,
      records,
      selectedRecord,
      showDetailDialog,
      showConfirmDialog
    } = this.state;

    return (
      <Wrapper>
        <Widget.Row>
          <div
            style={{
              display: "flex",
              flex: 1,
              color: "#777777",
              fontWeight: "500",
              padding: 10
            }}
          >
            搜尋結果
          </div>
          <div style={{ margin: 10, display: "flex" }}>
            <Widget.Clickable
              onClick={() =>
                this.setState({ showDetailDialog: true, selectedRecord: null })
              }
            >
              <Widget.Button>新增親子同室紀錄</Widget.Button>
            </Widget.Clickable>
            <Widget.Clickable onClick={this._fetchRecords}>
              <Widget.Button className="white-button">重新整理</Widget.Button>
            </Widget.Clickable>
          </div>
        </Widget.Row>

        {!showSpinner && records != null && records.length === 0 && (
          <Widget.HintMessage>尚未建立該寶寶相關紀錄!</Widget.HintMessage>
        )}

        {showSpinner ? (
          <Widget.Spinner />
        ) : (
          <Widget.Table>
            <Widget.TableContent>
              <Widget.Fields>
                <div className="date-field">記錄日期</div>
                <div className="field">記錄員工</div>
                <div className="field">奶量</div>
                <div className="field">大便</div>
                <div className="field">小便</div>
                <div className="field">體重</div>
                <div className="field">推出時間</div>
                <div className="field">返室時間</div>
                <div className="field-padding" />
                <div className="field" />
                <div className="field" />
              </Widget.Fields>

              {records &&
                records.map((record, i) => (
                  <div
                    className={i % 2 === 0 ? "row-even" : "row-odd"}
                    key={"record" + i}
                  >
                    <div className="date-item">{record.record_date}</div>
                    <div className="row-item">
                      <select disabled>
                        <option>
                          {users.map(user => {
                            if (user.id === record.staff) {
                              return user.user.username;
                            }
                          })}
                        </option>
                      </select>
                    </div>
                    <div className="row-item">
                      {record.mother_milk
                        ? record.mother_milk
                        : record.formula_milk}
                    </div>
                    <div className="row-item">{record.stool}</div>
                    <div className="row-item">{record.urine}</div>
                    <div className="row-item">{record.weight}</div>
                    <div className="row-item">
                      {record.out_time && record.out_time.slice(0, 5)}
                    </div>
                    <div className="row-item">
                      {record.back_time && record.back_time.slice(0, 5)}
                    </div>

                    <div className="row-item-padding" />
                    <div className="row-item">
                      <div
                        style={{ cursor: "pointer", color: "#4485D1" }}
                        onClick={() =>
                          this.setState({
                            selectedRecord: record,
                            showDetailDialog: true
                          })
                        }
                      >
                        <Icon.Edit color={"#4485D1"} />
                        編輯
                      </div>
                    </div>
                    <div className="row-item">
                      <div
                        style={{ cursor: "pointer", color: "#4485D1" }}
                        onClick={() =>
                          this.setState({
                            selectedRecord: record,
                            showConfirmDialog: true
                          })
                        }
                      >
                        <Icon.Delete color={"#4485D1"} />
                        刪除
                      </div>
                    </div>
                  </div>
                ))}
            </Widget.TableContent>
          </Widget.Table>
        )}

        {showDetailDialog && (
          <BabyMomRoomingInRecordDetailDialog
            selectedBaby={selectedBaby}
            record={selectedRecord}
            requestClose={() => this.setState({ showDetailDialog: false })}
            requestRefresh={this._fetchRecords}
          />
        )}

        {showConfirmDialog && (
          <ConfirmDialog
            text="確定要刪除嗎？此動作無法還原。"
            requestClose={() => this.setState({ showConfirmDialog: false })}
            confirmAction={() =>
              appActions.momCenter
                .deletBabyMomRoomingInRecord(selectedRecord.id)
                .then(this._fetchRecords)
                .then(() => this.setState({ showConfirmDialog: false }))
                .catch(err => console.warn(err))
            }
          />
        )}
      </Wrapper>
    );
  }

  _fetchRecords = () => {
    let { appActions, selectedBaby } = this.props;

    this.setState({ showSpinner: true });
    appActions.momCenter
      .fetchBabyMomRoomingInRecord(selectedBaby.id)
      .then(records => this.setState({ records }))
      .then(() => this.setState({ showSpinner: false }))
      .catch(err => console.warn(err));
  };
}

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
`;

export default connect(
  (state, ownProps) => ({
    users: Selectors.momCenter.getUsers(state)
  }),
  ActionCreator
)(BabyMomRoomingInRecordList);
