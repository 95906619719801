import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import ActionCreator from "../../../src/ActionCreator";
import Selectors from "../../../src/Selectors";
import AddSparePartDialog from "./AddSparePartDialog";
import SparePartIdRecord from "./SparePartIdRecord";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";

const Wrapper = styled.div`
  height: 100vh;
  background-color: #e8e8e8;
  padding: 10px;
  display: flex;
`;

const Container = styled.div`
  padding: 20px;
  flex: 1;
  overflow: auto;
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(154, 154, 154, 0.5);
`;

const Title = styled.h2`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #777777;
`;

class SparePartDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spareParts: [],
      showAddDialog: false,
      showRecordPage: false,
      selectedRecord: null
    };
  }

  componentDidMount() {
    let { spareParts } = this.props;
    this.setState({ spareParts });
  }

  render() {
    let { appActions } = this.props;
    let {
      spareParts,
      showAddDialog,
      showRecordPage,
      selectedRecord
    } = this.state;

    return (
      <Wrapper>
        <Container>
          {showRecordPage ? (
            <SparePartIdRecord
              id={selectedRecord.id}
              goBack={() => {
                appActions.momCenter
                  .fetchSparePartList()
                  .then(() =>
                    this.setState({ spareParts: this.props.spareParts })
                  )
                  .then(() => this.setState({ showRecordPage: false }))
                  .catch(err => console.warn(err));
              }}
            />
          ) : (
            <>
              <Title>
                備品管理
                <div
                  style={{
                    margin: 10,
                    display: "flex",
                    justifyContent: "flex-end"
                  }}
                >
                  <Widget.Button
                    css="margin: 0px 20px 0px 0px; width: 100px;"
                    onClick={() => this.setState({ showAddDialog: true })}
                  >
                    新增備品
                  </Widget.Button>
                  <Widget.Button
                    className="white-button"
                    css="margin: 0px; width: 100px;"
                    onClick={() =>
                      appActions.momCenter.fetchSparePartList().then(() =>
                        this.setState({
                          spareParts: this.props.spareParts
                        })
                      )
                    }
                  >
                    重新整理
                  </Widget.Button>
                </div>
              </Title>
              <Widget.Table>
                <Widget.TableContent lessTable>
                  <Widget.Fields lessTable>
                    <div className="field">品項</div>
                    <div className="field">單價</div>
                    <div className="field">數量</div>
                    <div className="field" />
                    <div className="field" />
                  </Widget.Fields>
                  {spareParts &&
                    spareParts.map((item, i) => (
                      <div className={i % 2 === 0 ? "row-even" : "row-odd"}>
                        <div className="row-item">{item.name}</div>
                        <div className="row-item">{item.unit_price}</div>
                        <div className="row-item">{item.total}</div>
                        <div className="row-item-padding" />
                        <div className="row-item">
                          <div
                            style={{ cursor: "pointer", color: "#4485D1" }}
                            onClick={() =>
                              this.setState({
                                showRecordPage: true,
                                selectedRecord: item
                              })
                            }
                          >
                            <Icon.ArrowForward color={"#4485D1"} />
                          </div>
                        </div>
                      </div>
                    ))}
                </Widget.TableContent>
              </Widget.Table>

              {showAddDialog && (
                <AddSparePartDialog
                  toClose={() => {
                    appActions.momCenter
                      .fetchSparePartList()
                      .then(() =>
                        this.setState({ spareParts: this.props.spareParts })
                      )
                      .then(() => this.setState({ showAddDialog: false }))
                      .catch(err => console.warn(err));
                  }}
                />
              )}
            </>
          )}
        </Container>
      </Wrapper>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    spareParts: Selectors.momCenter.getSpareParts(state)
  }),
  ActionCreator
)(SparePartDashboard);
