import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import ActionCreator from "../../../src/ActionCreator";
import Dialog from "../../../src/Components/Dialog";
import Selectors from "../../../src/Selectors";
import moment from "moment";

const Wrapper = styled.div`
  padding: 10px;
  position: relative;
`;

const Close = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

const Error = styled.h3`
  color: red;
`;

class MomVisitFormDialog extends React.Component {
  constructor(props) {
    super(props);

    let { record, mother, appActions, date } = this.props;
    let isCreateView = false;

    if (!record) {
      isCreateView = true;
      record = {
        mother: mother.id,
        visit_time: date
          ? moment(date).format("YYYY-MM-DDTHH:mm")
          : moment(new Date()).format("YYYY-MM-DDTHH:mm"),
        created: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
        baby_index: 1,
        baby_count: 1,
        note: ""
      };
    }

    this.state = {
      record: record && { ...record },
      isEditing: true,
      isUpdating: false,
      error: null,
      isCreateView,

      tmp_date: date
        ? moment(date).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
      tmp_time: moment(new Date()).format("HH:MM")
    };

    this.createApi = appActions.momCenter.createVisit;
    this.updateApi = appActions.momCenter.updateVisit;

    this.originalRecord = { ...record };
  }

  render() {
    let { requestClose, users, expand = true } = this.props;
    let { record, isEditing, isUpdating, error, isCreateView } = this.state;

    return (
      <Dialog>
        <Widget.DialogWrapper>
          {record ? (
            <Widget.FieldContainer>
              <Widget.FormField stacked>
                <label>帶參觀人</label>
                <select
                  value={record.staff && record.staff.id}
                  onChange={e =>
                    this.setState({
                      record: { ...record, staff: e.target.value }
                    })
                  }
                  disabled={!isEditing || isUpdating}
                >
                  {users &&
                    users.map(user => (
                      <option
                        selected={
                          record.staff && record.staff === user.id
                            ? "selected"
                            : ""
                        }
                        key={user.id}
                        value={user.id}
                      >
                        {user.user.username}
                      </option>
                    ))}
                </select>
              </Widget.FormField>

              <Widget.FormField stacked>
                <label>預約參觀日期</label>
                <input
                  type="date"
                  value={record.visit_time.split("T")[0]}
                  onChange={e =>
                    this.setState({
                      record: {
                        ...record,
                        visit_time: `${e.target.value}T${this.state.tmp_time}`
                      },
                      tmp_date: e.target.value
                    })
                  }
                  disabled={!isEditing || isUpdating}
                />
              </Widget.FormField>

              <Widget.FormField stacked>
                <label>預約參觀時間</label>
                <input
                  type="time"
                  name="time"
                  value={record.visit_time.slice(11, 16)}
                  onChange={e =>
                    this.setState({
                      record: {
                        ...record,
                        visit_time: `${this.state.tmp_date}T${e.target.value}`
                      },
                      tmp_time: e.target.value
                    })
                  }
                  disabled={!isEditing || isUpdating}
                />
              </Widget.FormField>

              <Widget.FormField stacked>
                <label>參觀目的</label>
                <select
                  value={record.visit_target}
                  // onChange={e =>
                  //   this.setState({
                  //     record: { ...record, visit_target: e.target.value }
                  //   })
                  // }
                >
                  <option value="1">option 1</option>
                  <option value="2">option 2</option>
                  <option value="3">option 3</option>
                </select>
              </Widget.FormField>

              <Widget.FormField stacked>
                <label>預產期</label>
                <input
                  value={record.expected_date}
                  name="disabled"
                  type="date"
                  onChange={e =>
                    this.setState({
                      record: { ...record, expected_date: e.target.value }
                    })
                  }
                  disabled={!isEditing || isUpdating}
                />
              </Widget.FormField>

              {expand && (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      borderBottom: "2px solid #eaeaea",
                      marginBottom: 10
                    }}
                  >
                    <Widget.FormField stacked>
                      <label>預期入住天數</label>
                      <input
                        value={record.expected_duration}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              expected_duration: e.target.value
                            }
                          })
                        }
                        disabled={!isEditing || isUpdating}
                      />
                    </Widget.FormField>

                    <Widget.FormField stacked>
                      <label>胎次</label>
                      <input
                        value={record.baby_index}
                        onChange={e =>
                          this.setState({
                            record: { ...record, baby_index: e.target.value }
                          })
                        }
                        disabled={!isEditing || isUpdating}
                      />
                    </Widget.FormField>

                    <Widget.FormField stacked>
                      <label>胎數</label>
                      <input
                        value={record.baby_count}
                        onChange={e =>
                          this.setState({
                            record: { ...record, baby_count: e.target.value }
                          })
                        }
                        disabled={!isEditing || isUpdating}
                      />
                    </Widget.FormField>

                    <Widget.FormField stacked>
                      <label>參觀人數</label>
                      <input
                        value={record.visitor_qt}
                        onChange={e =>
                          this.setState({
                            record: { ...record, visitor_qt: e.target.value }
                          })
                        }
                        disabled={!isEditing || isUpdating}
                      />
                    </Widget.FormField>

                    <Widget.FormField inputWidth="30px">
                      <input
                        type="checkbox"
                        checked={record.concern_visit}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              concern_visit: e.target.checked
                            }
                          })
                        }
                      />
                      <label>預約參觀提醒</label>
                    </Widget.FormField>

                    <Widget.FormField inputWidth="30px">
                      <input
                        type="checkbox"
                        checked={record.concern_prenatal}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              concern_prenatal: e.target.checked
                            }
                          })
                        }
                      />
                      <label>產前關懷提醒</label>
                    </Widget.FormField>

                    <Widget.FormField stacked inputWidth="320px">
                      <label>參觀備註</label>
                      <input
                        value={record.demand_note}
                        onChange={e =>
                          this.setState({
                            record: { ...record, demand_note: e.target.value }
                          })
                        }
                        disabled={!isEditing || isUpdating}
                      />
                    </Widget.FormField>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      borderBottom: "2px solid #eaeaea",
                      marginBottom: 10
                    }}
                  >
                    <Widget.FormField stacked>
                      <label>試吃日期</label>
                      <input
                        type="date"
                        value={record.meal_trial_date}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              meal_trial_date: e.target.value
                            }
                          })
                        }
                        disabled={!isEditing || isUpdating}
                      />
                    </Widget.FormField>

                    <Widget.FormField stacked>
                      <label>試吃餐別</label>
                      <select
                        value={record.meal_period}
                        onChange={e =>
                          this.setState({
                            record: { ...record, meal_period: e.target.value }
                          })
                        }
                      >
                        <option value={"breakfast"}>早餐</option>
                        <option value={"lunch"}>午餐</option>
                        <option value={"snack_lunch"}>午點心</option>
                        <option value={"dinner"}>晚餐</option>
                      </select>
                    </Widget.FormField>

                    <Widget.FormField stacked>
                      <label>試吃費</label>
                      <input
                        value={record.meal_trial}
                        onChange={e =>
                          this.setState({
                            record: { ...record, meal_trial: e.target.value }
                          })
                        }
                        disabled={!isEditing || isUpdating}
                      />
                    </Widget.FormField>

                    <Widget.FormField inputWidth="30px">
                      <input
                        type="checkbox"
                        checked={record.had_meal_trial}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              had_meal_trial: e.target.checked
                            }
                          })
                        }
                        disabled={!isEditing || isUpdating}
                      />
                      <label>已試吃，建立帳單紀錄</label>
                      {this.originalRecord.had_meal_trial &&
                        !this.state.record.had_meal_trial && (
                          <span style={{ color: "red", fontSize: 12 }}>
                            請手動至帳單記錄刪除試吃訂單
                          </span>
                        )}
                    </Widget.FormField>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      borderBottom: "2px solid #eaeaea",
                      marginBottom: 10
                    }}
                  >
                    <Widget.FormField>
                      <label>特別叮嚀-飲食</label>
                      <input
                        value={record.reminder_meal}
                        onChange={e =>
                          this.setState({
                            record: { ...record, reminder_meal: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField>
                      <label>特別叮嚀-生活</label>
                      <input
                        value={record.reminder_life}
                        onChange={e =>
                          this.setState({
                            record: { ...record, reminder_life: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField>
                      <label>特別叮嚀-房型</label>
                      <input
                        value={record.reminder_room}
                        onChange={e =>
                          this.setState({
                            record: { ...record, reminder_room: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap"
                    }}
                  >
                    <Widget.FormField inputWidth="30px">
                      <input
                        type="checkbox"
                        checked={record.cancel_visit}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              cancel_visit: e.target.checked
                            }
                          })
                        }
                        disabled={!isEditing || isUpdating}
                      />
                      <label>確定取消參觀</label>
                    </Widget.FormField>
                    <Widget.FormField stacked>
                      <label>取消原因</label>
                      <input
                        value={record.cancel_reason}
                        onChange={e =>
                          this.setState({
                            record: { ...record, cancel_reason: e.target.value }
                          })
                        }
                        disabled={!isEditing || isUpdating}
                      />
                    </Widget.FormField>

                    <Widget.FormField stacked>
                      <label>帶參觀人評估</label>
                      <textarea
                        value={record.note}
                        onChange={e =>
                          this.setState({
                            record: { ...record, note: e.target.value }
                          })
                        }
                        disabled={!isEditing || isUpdating}
                      />
                    </Widget.FormField>
                  </div>
                </>
              )}
            </Widget.FieldContainer>
          ) : (
            <h1>fetch record failed</h1>
          )}

          {error && <Error>{error}</Error>}

          {isEditing &&
            (isUpdating ? (
              <Widget.Spinner />
            ) : isCreateView ? (
              <Widget.Row align="center" justify="space-between">
                <div
                  style={{ marginRight: 15, fontSize: 14, color: "#777777" }}
                >
                  創建日期：{moment(new Date()).format("YYYY-MM-DD")}
                </div>
                <Widget.Button onClick={this._confirmCreate}>
                  確認新增
                </Widget.Button>
              </Widget.Row>
            ) : (
              <Widget.Row align="center" justify="space-between">
                <div
                  style={{ marginRight: 15, fontSize: 14, color: "#777777" }}
                >
                  創建日期：{record.created}
                </div>
                <Widget.Button onClick={this._confirmUpdate}>
                  確認修改
                </Widget.Button>
              </Widget.Row>
            ))}

          <Close onClick={requestClose}>
            <Icon.Close />
          </Close>
        </Widget.DialogWrapper>
      </Dialog>
    );
  }

  _doMealTrialCheck = () => {
    let { record } = this.state;
    let { originalRecord } = this;

    let { appActions } = this.props;

    if (
      record.had_meal_trial === true &&
      originalRecord.had_meal_trial === false
    ) {
      appActions.momCenter
        .createMomBillingRecord({
          title: record.meal_period,
          price: record.meal_trial,
          ...record
        })
        .catch(() => {
          this.setState({ isUpdating: false });
          this._showErrorToast("API錯誤, 請稍後再試", 2000);
        });
    }
  };

  _confirmCreate = () => {
    let { requestRefresh, requestClose } = this.props;
    let { record } = this.state;

    this.setState({ isUpdating: true });
    this.createApi(record)
      .then(this._doMealTrialCheck)
      .then(() => {
        this.setState({ isUpdating: false });
        requestRefresh();
        requestClose();
      })
      .catch(() => {
        this.setState({ isUpdating: false });
        this._showErrorToast("API錯誤, 請稍後再試", 2000);
      });
  };

  _confirmUpdate = () => {
    let { requestRefresh, requestClose } = this.props;
    let { record } = this.state;

    this.setState({ isUpdating: true });
    this.updateApi(record)
      .then(this._doMealTrialCheck)
      .then(() => {
        this.setState({ isUpdating: false });
        requestRefresh();
        requestClose();
      })
      .catch(() => {
        this.setState({ isUpdating: false });
        this._showErrorToast("API錯誤, 請稍後再試", 2000);
      });
  };

  _showErrorToast = (message, ms) => {
    this.setState({ error: message });
    setTimeout(() => {
      this.setState({ error: null });
    }, ms);
  };
}

export default connect(
  (state, ownProps) => ({
    users: Selectors.momCenter.getUsers(state)
  }),
  ActionCreator
)(MomVisitFormDialog);
