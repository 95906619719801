import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as Widget from "../../../src/Components/Widget2";
import ActionCreator from "../../../src/ActionCreator";
import Dialog from "../../../src/Components/Dialog";
import moment from "moment";
import Selectors from "../../../src/Selectors";

const Error = styled.h3`
  color: red;
`;

function decodeRoomConfig(config) {
  try {
    return JSON.parse(config);
  } catch (ex) {}
  return {};
}

function encodeRoomConfig(config) {
  return JSON.stringify(config);
}

function calcRoomConfigPrice(config, roomTypes) {
  if (!config) {
    return 0;
  }

  let price = 0;
  for (let roomTypeId in config) {
    const roomType = roomTypes.find(
      _roomType => String(_roomType.id) === roomTypeId
    );
    if (roomType) {
      let days = parseInt(config[roomTypeId], 10);
      price += days * roomType.price;
    } else {
      // invalid roomType
      return 0;
    }
  }

  if (isNaN(price)) {
    return 0;
  }

  return price;
}

class ContractDetailDialog extends React.Component {
  constructor(props) {
    super(props);

    let { visitLatestRecord, record, mother, appActions } = this.props;
    let isCreateView = false;

    if (!record) {
      isCreateView = true;
      record = {
        mother: mother.id,
        signed_date: moment(new Date()).format("YYYY-MM-DD"),
        expected_date: visitLatestRecord.expected_date,
        actual_date:
          mother.expected_date || moment(new Date()).format("YYYY-MM-DD"),
        delivery_method: mother.delivery_method || "c-section",
        hospital: mother.hospital || "",
        birth_index: 1,
        baby_count: 1,
        price: 0,
        note: ""
      };
    }

    this.state = {
      record: record && { ...record },
      isEditing: true,
      isUpdating: false,
      error: null,
      isCreateView,
      roomConfig: (record && decodeRoomConfig(record.room_config)) || {}
    };

    this.createApi = appActions.momCenter.createContract;
    this.updateApi = appActions.momCenter.updateContract;
  }

  render() {
    let { requestClose, roomTypes, users } = this.props;
    let {
      record,
      isEditing,
      isUpdating,
      error,
      isCreateView,
      roomConfig
    } = this.state;
    let suggestPrice = calcRoomConfigPrice(roomConfig, roomTypes) || 0;

    return (
      <Dialog>
        <Widget.DialogWrapper>
          {isCreateView ? (
            <Widget.DialogTitle>新增合約</Widget.DialogTitle>
          ) : (
            <Widget.DialogTitle>修改合約</Widget.DialogTitle>
          )}

          <Widget.FieldContainer>
            <div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  borderBottom: "2px solid #eaeaea",
                  marginBottom: 10
                }}
              >
                <h4
                  style={{
                    width: "100%",
                    display: "flex",
                    margin: "0px 15px",
                    paddingBottom: 10,
                    color: "#9a9a9a"
                  }}
                >
                  合約內容
                </h4>
                <Widget.FormField stacked>
                  <label>簽訂日期</label>
                  <input
                    type="date"
                    value={record.contract_date}
                    onChange={e =>
                      this.setState({
                        record: { ...record, contract_date: e.target.value }
                      })
                    }
                    disabled={!isEditing || isUpdating}
                  />
                </Widget.FormField>

                <Widget.FormField stacked>
                  <label>預產日期</label>
                  <input
                    type="date"
                    value={record.expected_date}
                    onChange={e =>
                      this.setState({
                        record: { ...record, expected_date: e.target.value }
                      })
                    }
                    disabled={!isEditing || isUpdating}
                  />
                </Widget.FormField>

                <Widget.FormField stacked>
                  <label>實際生產日期</label>
                  <input
                    type="date"
                    value={record.actual_date}
                    onChange={e =>
                      this.setState({
                        record: { ...record, actual_date: e.target.value }
                      })
                    }
                    disabled={!isEditing || isUpdating}
                  />
                </Widget.FormField>

                <Widget.FormField stacked>
                  <label>預計生產方式</label>
                  <select
                    value={record.delivery_method}
                    onChange={e =>
                      this.setState({
                        record: { ...record, delivery_method: e.target.value }
                      })
                    }
                  >
                    <option value="c-section">剖腹產</option>
                    <option value="vaginal delivery">自然產</option>
                  </select>
                </Widget.FormField>

                <Widget.FormField stacked>
                  <label>預計生產醫院</label>
                  <input
                    value={record.hospital}
                    onChange={e =>
                      this.setState({
                        record: { ...record, hospital: e.target.value }
                      })
                    }
                  />
                </Widget.FormField>

                <Widget.FormField stacked>
                  <label>入住天數</label>
                  <input
                    value={record.stay}
                    onChange={e =>
                      this.setState({
                        record: { ...record, stay: e.target.value }
                      })
                    }
                    disabled={!isEditing || isUpdating}
                  />
                </Widget.FormField>

                <Widget.FormField stacked>
                  <label>寶寶人數</label>

                  <select
                    value={record.baby_qt}
                    onChange={event =>
                      this.setState({
                        record: {
                          ...record,
                          baby_qt: event.target.value
                        }
                      })
                    }
                  >
                    <option value={"singleton"}>單胞胎</option>
                    <option value={"twins"}>雙胞胎</option>
                    <option value={"triplets"}>三胞胎</option>
                  </select>
                </Widget.FormField>

                <Widget.FormField stacked>
                  <label>簽約人</label>
                  <select
                    value={record.staff && record.staff.id}
                    onChange={e =>
                      this.setState({
                        record: { ...record, staff: e.target.value }
                      })
                    }
                  >
                    {users &&
                      users.map(user => (
                        <option
                          selected={
                            record.staff && record.staff === user.id
                              ? "selected"
                              : ""
                          }
                          key={user.id}
                          value={user.id}
                        >
                          {user.user.username}
                        </option>
                      ))}
                  </select>
                </Widget.FormField>

                <Widget.FormField stacked>
                  <label>簽約日期</label>
                  <input
                    type="date"
                    value={record.signed_date}
                    onChange={e =>
                      this.setState({
                        record: { ...record, signed_date: e.target.value }
                      })
                    }
                    disabled={!isEditing || isUpdating}
                  />
                </Widget.FormField>

                <Widget.FormField stacked>
                  <label>預計入住日期</label>
                  <input
                    type="date"
                    value={record.checkin_date}
                    onChange={e =>
                      this.setState({
                        record: { ...record, checkin_date: e.target.value }
                      })
                    }
                    disabled={!isEditing || isUpdating}
                  />
                </Widget.FormField>

                <Widget.FormField stacked>
                  <label>領獎金人</label>
                  <select
                    value={record.staff_get_bonus && record.staff_get_bonus.id}
                    onChange={e =>
                      this.setState({
                        record: { ...record, staff_get_bonus: e.target.value }
                      })
                    }
                  >
                    {users &&
                      users.map(user => (
                        <option
                          selected={
                            record.staff_get_bonus &&
                            record.staff_get_bonus === user.id
                              ? "selected"
                              : ""
                          }
                          key={user.id}
                          value={user.id}
                        >
                          {user.user.username}
                        </option>
                      ))}
                  </select>
                </Widget.FormField>

                <Widget.FormField stacked>
                  <label>訂金</label>
                  <input
                    value={record.deposit}
                    onChange={e =>
                      this.setState({
                        record: { ...record, deposit: e.target.value }
                      })
                    }
                    disabled={!isEditing || isUpdating}
                  />
                </Widget.FormField>

                <Widget.FormField stacked>
                  <label>已付訂金</label>
                  <input
                    type="checkbox"
                    checked={record.is_paid_deposit}
                    onChange={e =>
                      this.setState({
                        record: { ...record, is_paid_deposit: e.target.checked }
                      })
                    }
                    disabled={!isEditing || isUpdating}
                  />
                </Widget.FormField>

                <Widget.FormField stacked>
                  <label>合約金額</label>
                  <input
                    value={record.price}
                    onChange={e =>
                      this.setState({
                        record: { ...record, price: e.target.value }
                      })
                    }
                    disabled={!isEditing || isUpdating}
                  />
                </Widget.FormField>

                <Widget.FormField stacked>
                  <label>特殊折扣訂房</label>
                  <input
                    value={record.discount}
                    onChange={e =>
                      this.setState({
                        record: { ...record, discount: e.target.value }
                      })
                    }
                    disabled={!isEditing || isUpdating}
                  />
                </Widget.FormField>

                <Widget.FormField stacked>
                  <label>折扣理由說明</label>
                  <textarea
                    value={record.discount_reason}
                    onChange={e =>
                      this.setState({
                        record: { ...record, discount_reason: e.target.value }
                      })
                    }
                    disabled={!isEditing || isUpdating}
                  />
                </Widget.FormField>

                <Widget.FormField stacked>
                  <label>胎次</label>
                  <input
                    value={record.birth_index}
                    onChange={e =>
                      this.setState({
                        record: { ...record, birth_index: e.target.value }
                      })
                    }
                    disabled={!isEditing || isUpdating}
                  />
                </Widget.FormField>

                <Widget.FormField stacked>
                  <label>胎數</label>
                  <input
                    value={record.baby_count}
                    onChange={e =>
                      this.setState({
                        record: { ...record, baby_count: e.target.value }
                      })
                    }
                    disabled={!isEditing || isUpdating}
                  />
                </Widget.FormField>

                {roomTypes.map(roomType => (
                  <Widget.FormField key={roomType.id} stacked>
                    <label>{`${roomType.name}($${roomType.price})房型`}</label>
                    <input
                      type="number"
                      value={roomConfig[roomType.id] || ""}
                      onChange={e =>
                        this.setState({
                          roomConfig: {
                            ...roomConfig,
                            [roomType.id]: e.target.value
                          }
                        })
                      }
                      disabled={!isEditing || isUpdating}
                    />
                    日
                  </Widget.FormField>
                ))}

                <Widget.FormField stacked>
                  <label>總價</label>
                  <div
                    style={{ color: "lightgrey", fontSize: 12 }}
                  >{`* 建議售價: ${suggestPrice}`}</div>
                  <input
                    type="number"
                    value={record.price}
                    onChange={e =>
                      this.setState({
                        record: { ...record, price: e.target.value }
                      })
                    }
                    disabled={!isEditing || isUpdating}
                  />
                </Widget.FormField>

                <Widget.FormField stacked>
                  <label>備註</label>
                  <textarea
                    value={record.note}
                    onChange={e =>
                      this.setState({
                        record: { ...record, note: e.target.value }
                      })
                    }
                    disabled={!isEditing || isUpdating}
                  />
                </Widget.FormField>
              </div>

              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <h4
                  style={{
                    width: "100%",
                    display: "flex",
                    margin: "0px 15px",
                    paddingBottom: 10,
                    color: "#9a9a9a"
                  }}
                >
                  膳食內容
                </h4>
                <Widget.FormField stacked>
                  <label>早餐</label>
                  <select
                    value={record.breakfast}
                    onChange={e =>
                      this.setState({
                        record: { ...record, breakfast: e.target.value }
                      })
                    }
                  >
                    <option value={"chinese-style"}>中式</option>
                    <option value={"western-style"}>西式</option>
                    <option value={"vegetarian"}>素食</option>
                  </select>
                </Widget.FormField>
                <Widget.FormField stacked>
                  <label>午餐</label>
                  <select
                    value={record.lunch}
                    onChange={e =>
                      this.setState({
                        record: { ...record, lunch: e.target.value }
                      })
                    }
                  >
                    <option value={"chinese-style"}>中式</option>
                    <option value={"western-style"}>西式</option>
                    <option value={"vegetarian"}>素食</option>
                  </select>
                </Widget.FormField>
                <Widget.FormField stacked>
                  <label>晚餐</label>
                  <select
                    value={record.dinner}
                    onChange={e =>
                      this.setState({
                        record: { ...record, dinner: e.target.value }
                      })
                    }
                  >
                    <option value={"chinese-style"}>中式</option>
                    <option value={"western-style"}>西式</option>
                    <option value={"vegetarian"}>素食</option>
                  </select>
                </Widget.FormField>
                <Widget.FormField stacked>
                  <label>午點心</label>
                  <select
                    value={record.snack_lunch}
                    onChange={e =>
                      this.setState({
                        record: { ...record, snack_lunch: e.target.value }
                      })
                    }
                  >
                    <option value={"chinese-style"}>中式</option>
                    <option value={"western-style"}>西式</option>
                    <option value={"vegetarian"}>素食</option>
                  </select>
                </Widget.FormField>
                <Widget.FormField stacked>
                  <label>媽媽飲食禁忌</label>
                  <textarea
                    value={record.meal_constraint}
                    onChange={e =>
                      this.setState({
                        record: { ...record, meal_constraint: e.target.value }
                      })
                    }
                    disabled={!isEditing || isUpdating}
                  />
                </Widget.FormField>
              </div>
            </div>
          </Widget.FieldContainer>

          {error && <Error>{error}</Error>}
          <div
            style={{ margin: 10, display: "flex", justifyContent: "flex-end" }}
          >
            {isEditing &&
              (isUpdating ? (
                <Widget.Spinner />
              ) : isCreateView ? (
                <Widget.Button onClick={this._confirmCreate}>
                  確認新增
                </Widget.Button>
              ) : (
                <Widget.Button onClick={this._confirmUpdate}>
                  確認修改
                </Widget.Button>
              ))}

            <Widget.Button
              color={"#ffffff"}
              textColor={"#777777"}
              css={"border: 1px solid #DFDFDF"}
              onClick={requestClose}
            >
              取消
            </Widget.Button>
          </div>
        </Widget.DialogWrapper>
      </Dialog>
    );
  }

  _confirmCreate = () => {
    let { requestRefresh, requestClose } = this.props;
    let { record, roomConfig } = this.state;

    record = { ...record, room_config: encodeRoomConfig(roomConfig) };

    this.setState({ isUpdating: true });
    this.createApi(record)
      .then(() => {
        this.setState({ isUpdating: false });
        requestRefresh();
        requestClose();
      })
      .catch(() => {
        this.setState({ isUpdating: false });
        this._showErrorToast("API錯誤, 請稍後再試", 2000);
      });
  };

  _confirmUpdate = () => {
    let { requestRefresh, requestClose } = this.props;
    let { record, roomConfig } = this.state;

    record = { ...record, room_config: encodeRoomConfig(roomConfig) };

    this.setState({ isUpdating: true });
    this.updateApi(record)
      .then(() => {
        this.setState({ isUpdating: false });
        requestRefresh();
        requestClose();
      })
      .catch(() => {
        this.setState({ isUpdating: false });
        this._showErrorToast("API錯誤, 請稍後再試", 2000);
      });
  };

  _showErrorToast = (message, ms) => {
    this.setState({ error: message });
    setTimeout(() => {
      this.setState({ error: null });
    }, ms);
  };
}

export default connect(
  (state, ownProps) => ({
    users: Selectors.momCenter.getUsers(state),
    roomTypes: Selectors.momCenter.getRoomTypes(state)
  }),
  ActionCreator
)(ContractDetailDialog);
