import React from "react";
import styled from "styled-components";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import ConfirmDialog from "./ConfirmDialog";
import ActionCreator from "../../../src/ActionCreator";
import { connect } from "react-redux";

const BabyItems = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const BabyItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px 15px 0px;
  border: ${props => props.selected && "2px solid #88ADD8"};
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 2px 0 rgba(154, 154, 154, 0.5);
  background-color: #ffffff;
  width: 188px;
  height: 70px;
  font-size: 14px;
  color: #777777;
  cursor: pointer;

  & > .name {
    width: 90px;
  }

  @media screen and (max-width: 770px) {
    width: 176px;
    height: 65px;

    & > .name {
      width: 90px;
    }
  }
`;

const Clickable = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  & > .text {
    font-size: 14px;
    color: #4485d1;
  }
`;

class BabyList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmDialog: false
    };
  }

  render() {
    let {
      babies,
      selectedBaby,
      onSelect,
      appActions,
      fetchMother
    } = this.props;
    let { showConfirmDialog } = this.state;

    return (
      <BabyItems>
        {babies.map((baby, i) => (
          <BabyItem
            key={i}
            selected={selectedBaby && selectedBaby.id === baby.id}
            onClick={() => {
              onSelect(baby);
            }}
          >
            <div className="name">{baby.name}</div>
            <Clickable
              onClick={() => this.setState({ showConfirmDialog: true })}
            >
              <Icon.Delete color={"#4485D1"} />
              <div className="text">刪除</div>
            </Clickable>
          </BabyItem>
        ))}

        {showConfirmDialog && (
          <ConfirmDialog
            text="確定要刪除嗎？此動作無法還原。"
            requestClose={() => this.setState({ showConfirmDialog: false })}
            confirmAction={() => {
              return appActions.momCenter
                .deleteBaby(selectedBaby.id)
                .then(() => this.setState({ showConfirmDialog: false }))
                .then(fetchMother)
                .then(() => onSelect(null))
                .catch(err => console.warn(err));
            }}
          />
        )}
      </BabyItems>
    );
  }
}
export default connect(
  null,
  ActionCreator
)(BabyList);
