import React from "react";
import styled from "styled-components";
import Dialog from "../../../src/Components/Dialog";
import * as Widget from "../../../src/Components/Widget2";

const Wrapper = styled.div`
  padding: 20px;
`;

const Content = styled.div`
  margin-bottom: 20px;
  white-space: pre-wrap;
  text-align: center;
  font-size: 14px;
  color: #777777;
`;

const ButtonSection = styled.div`
  text-align: center;
`;

class ConfirmDialog extends React.Component {
  render() {
    let { text, requestClose, confirmAction } = this.props;

    return (
      <Dialog>
        <Wrapper>
          <Content>{text}</Content>

          <ButtonSection>
            <Widget.Button
              className="white-button"
              css="margin: 0px 10px 0px 0px; width: 100px;"
              onClick={requestClose}
            >
              取消
            </Widget.Button>
            <Widget.Button
              css="margin-left: 0px; width: 100px;"
              onClick={confirmAction}
            >
              確認
            </Widget.Button>
          </ButtonSection>
        </Wrapper>
      </Dialog>
    );
  }
}

export default ConfirmDialog;
