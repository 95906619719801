import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as Widget from "../../../src/Components/Widget2";
import Selectors from "../../../src/Selectors";
import ActionCreator from "../../../src/ActionCreator";
import MomDashboard from "./MomDashboard";
import SparePartDashboard from "./SparePartDashboard";
import DailyArrangementDashboard from "./DailyArrangementDashboard";
import BabyDashboard from "./BabyDashboard";
import RepairmentDashboard from "./RepairmentDashboard";

const Wrapper = styled.div`
  display: flex;

  & > .top-content {
    flex: 1;
  }

  @media screen and (max-width: 600px) {
    display: block;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;
`;

const TopNavBar = styled.div`
  width: 150px;
  height: 100vh;
  background-color: #88add8;
  box-shadow: 0 2px 2px 0 rgba(154, 154, 154, 0.5);
`;

const TopNavTitle = styled.div`
  padding: 25px 20px;
  text-align: center;
  white-space: pre-wrap;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

const TopNavItem = styled.div`
  padding: 10px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: #ffffff;
  background-color: ${props => props.selected && "#4485D1"};
  cursor: pointer;

  & > img {
    margin-right: 10px;
    width: 40px;
    height: 40px;
  }

  @media screen and (max-width: 768px) {
    & > img {
      margin: 0px 15px;
    }
  }
`;

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasBasicData: false
    };
  }

  componentDidMount() {
    let { appActions } = this.props;

    delay(500)
      .then(appActions.momCenter.fetchRoomTypeList)
      .then(appActions.momCenter.fetchUserList)
      .then(appActions.momCenter.fetchRoomList)
      .then(appActions.momCenter.fetchIncubatorList)
      .then(appActions.momCenter.fetchIncubatorTypeList)
      .then(appActions.momCenter.fetchSparePartList)
      .then(() => this.setState({ hasBasicData: true }))
      .catch(err => console.warn(err));
  }

  render() {
    let { appActions, navActions, topNavState } = this.props;
    let { hasBasicData } = this.state;

    if (!hasBasicData) {
      return (
        <Container>
          <Widget.Spinner />
        </Container>
      );
    }

    return (
      <Wrapper>
        <TopNavBar>
          <TopNavTitle>{"青田悅\n產後護理中心"}</TopNavTitle>
          <TopNavItem
            selected={topNavState === "rooms"}
            onClick={() => appActions.momCenter.setTopNavState("rooms")}
          >
            <img src="/images/pages/navbar/icon_rooms.png" />
            <div>館內</div>
          </TopNavItem>

          <TopNavItem
            selected={topNavState === "mom"}
            onClick={() => appActions.momCenter.setTopNavState("mom")}
          >
            <img src="/images/pages/navbar/icon_mom.png" />
            <div>媽媽</div>
          </TopNavItem>

          <TopNavItem
            selected={topNavState === "baby"}
            onClick={() => appActions.momCenter.setTopNavState("baby")}
          >
            <img src="/images/pages/navbar/icon_baby.png" />
            <div>寶寶</div>
          </TopNavItem>

          <TopNavItem
            selected={topNavState === "spare_part"}
            onClick={() => appActions.momCenter.setTopNavState("spare_part")}
          >
            <img src="/images/pages/navbar/icon_spare_part.png" />
            <div>備品</div>
          </TopNavItem>

          <TopNavItem
            selected={topNavState === "repairment"}
            onClick={() => appActions.momCenter.setTopNavState("repairment")}
          >
            <img src="/images/pages/navbar/icon_repairment.png" />
            <div>修繕</div>
          </TopNavItem>

          <TopNavItem
            selected={topNavState === "settings"}
            onClick={() => appActions.momCenter.setTopNavState("setting")}
          >
            <img src="/images/pages/navbar/icon_settings.png" />
            <div>設定</div>
          </TopNavItem>

          <TopNavItem
            selected={topNavState === "logout"}
            onClick={() => appActions.logout().then(() => navActions.push("/"))}
          >
            <img src="/images/pages/navbar/icon_others.png" />
            <div>登出</div>
          </TopNavItem>
        </TopNavBar>

        <div className="top-content">
          {(() => {
            if (topNavState === "rooms") {
              return <DailyArrangementDashboard />;
            }
            if (topNavState === "mom") {
              return <MomDashboard />;
            }
            if (topNavState === "baby") {
              return <BabyDashboard />;
            }
            if (topNavState === "spare_part") {
              return <SparePartDashboard />;
            }
            if (topNavState === "repairment") {
              return <RepairmentDashboard />;
            }

            return (
              <div style={{ padding: 10 }}>
                <Widget.Button
                  onClick={() =>
                    appActions.logout().then(() => navActions.push("/"))
                  }
                >
                  登出
                </Widget.Button>
              </div>
            );
          })()}
        </div>
      </Wrapper>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    topNavState: Selectors.momCenter.getTopNavState(state)
  }),
  ActionCreator
)(Dashboard);
