import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import ActionCreator from "../../../src/ActionCreator";
import Dialog from "../../../src/Components/Dialog";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";

class ConcernDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      momVisitReordsByTmw: [],
      momExpectedRecordsByWeek: [],
      momLivingRecordsByTmw: [],
      showSpinner: false,
      message: {
        display: false,
        text: "",
        error: false
      }
    };
  }

  componentDidMount() {
    this._fetchMomVisitReordsByTmw();
    this._fetchMomLivingRecordsByTmw();
    this._fetchMomExpectedRecordsByWeek();
  }

  render() {
    let { appActions, onClose } = this.props;
    let {
      momVisitReordsByTmw,
      momExpectedRecordsByWeek,
      momLivingRecordsByTmw,
      showSpinner,
      message
    } = this.state;

    return (
      <Dialog>
        <Widget.DialogWrapper>
          <Widget.Close onClick={onClose}>
            <Icon.Close />
          </Widget.Close>
          <Widget.DialogTitle>
            每日關懷 {moment(new Date()).format("YYYY-MM-DD")}{" "}
          </Widget.DialogTitle>

          <Widget.Row css="margin-left: 15px;">
            <Widget.Row align="center" css="margin-right: 25px;">
              <div
                style={{
                  marginRight: 10,
                  borderRadius: "50%",
                  width: 13,
                  height: 13,
                  background: "#E4A5B3"
                }}
              />
              <div style={{ fontSize: 14, color: "#777777" }}>未完成</div>
            </Widget.Row>

            <Widget.Row align="center">
              <div
                style={{
                  marginRight: 10,
                  borderRadius: "50%",
                  width: 13,
                  height: 13,
                  background: "#777777"
                }}
              />
              <div style={{ fontSize: 14, color: "#777777" }}>已完成</div>
            </Widget.Row>
          </Widget.Row>

          <SectionTitle>預約參觀提醒（明日）</SectionTitle>
          <Widget.Container css="border-bottom: 1px solid lightgray; padding: 0px 30px 10px;">
            {momVisitReordsByTmw
              .sort(
                (a, b) =>
                  new Date(a.visit_time).getTime() -
                  new Date(b.visit_time).getTime()
              )
              .map((record, i) => (
                <RecordItem
                  key={"mom-visit-record" + i}
                  needHighlight={!record.concern_visit}
                >
                  <input
                    type="checkbox"
                    checked={record.concern_visit}
                    onChange={e => {
                      record.concern_visit = e.target.checked;
                      record.edit = !record.edit;
                      this.setState({
                        momVisitReordsByTmw,
                        messgae: { display: false }
                      });
                    }}
                  />
                  <div
                    onClick={() => {
                      appActions.momCenter.setTopNavState("mom");
                      appActions.momCenter.setMomDashboardState({
                        uiState: "selected",
                        selectedTab: "mother",
                        selectedMother: record.mother_data
                      });
                    }}
                  >
                    {record.mother_data.phone} {record.mother_data.name}{" "}
                    {moment(record.visit_time).format("YYYY-MM-DD HH:MM")} 參觀
                  </div>
                </RecordItem>
              ))}
          </Widget.Container>

          <SectionTitle>產前關懷提醒（七天後）</SectionTitle>
          <Widget.Container css="border-bottom: 1px solid lightgray; padding: 0px 30px 10px;">
            {momExpectedRecordsByWeek.map((record, i) => (
              <RecordItem
                key={"mom-expected-item" + i}
                needHighlight={!record.concern_prenatal}
              >
                <input
                  type="checkbox"
                  checked={record.concern_prenatal}
                  onChange={e => {
                    record.concern_prenatal = e.target.checked;
                    record.edit = !record.edit;
                    this.setState({
                      momExpectedRecordsByWeek,
                      messgae: { display: false }
                    });
                  }}
                />
                <div
                  onClick={() => {
                    appActions.momCenter.setTopNavState("mom");
                    appActions.momCenter.setMomDashboardState({
                      uiState: "selected",
                      selectedTab: "mother",
                      selectedMother: record.mother_data
                    });
                  }}
                >
                  {record.mother_data.phone} {record.mother_data.name}{" "}
                  {record.expected_date} 預產
                </div>
              </RecordItem>
            ))}
          </Widget.Container>

          <SectionTitle>入住關懷提醒（明日）</SectionTitle>
          <Widget.Container css="padding: 0px 30px;">
            {momLivingRecordsByTmw.map((record, i) => (
              <RecordItem
                key={"mom-living-item" + i}
                needHighlight={!record.concern_checkin}
              >
                <input
                  type="checkbox"
                  checked={record.concern_checkin}
                  onChange={e => {
                    record.concern_checkin = e.target.checked;
                    record.edit = !record.edit;
                    this.setState({
                      momLivingRecordsByTmw,
                      messgae: { display: false }
                    });
                  }}
                />
                <div
                  onClick={() => {
                    appActions.momCenter.setTopNavState("mom");
                    appActions.momCenter.setMomDashboardState({
                      uiState: "selected",
                      selectedTab: "mother",
                      selectedMother: record.mother
                    });
                  }}
                >
                  {record.mother.phone} {record.mother.name} {record.from_date}{" "}
                  入住 {record.room.name}
                </div>
              </RecordItem>
            ))}
          </Widget.Container>

          <Widget.Row align="center" justify="flex-end" css="margin-top: 20px;">
            {message.display && (
              <div style={{ marginRight: 5, fontSize: 16, color: "#777777" }}>
                {message.text}
              </div>
            )}
            {showSpinner ? (
              <Widget.Spinner />
            ) : (
              <Widget.Button onClick={this._checkAndConfirmUpdate}>
                確認修改
              </Widget.Button>
            )}
          </Widget.Row>
        </Widget.DialogWrapper>
      </Dialog>
    );
  }

  _checkAndConfirmUpdate = () => {
    let { appActions } = this.props;
    let {
      momVisitReordsByTmw,
      momExpectedRecordsByWeek,
      momLivingRecordsByTmw
    } = this.state;

    let momVisitReordsByEdit = momVisitReordsByTmw.filter(r => r.edit === true);
    let momExpectedRecordsByEdit = momExpectedRecordsByWeek.filter(
      r => r.edit === true
    );
    let momLivingRecordsByEdit = momLivingRecordsByTmw.filter(
      r => r.edit === true
    );

    this.setState({ showSpinner: true });
    return new Promise((resolve, reject) => setTimeout(resolve, 300))
      .then(() => {
        momVisitReordsByEdit.forEach(async record => {
          try {
            await appActions.momCenter.updateVisit(record);
          } catch (err) {
            console.warn(err);
          }
        });
      })
      .then(() => {
        momExpectedRecordsByEdit.forEach(async record => {
          try {
            await appActions.momCenter.updateVisit(record);
          } catch (err) {
            console.warn(err);
          }
        });
      })
      .then(() => {
        momLivingRecordsByEdit.forEach(async record => {
          try {
            await appActions.momCenter.updateMomLivingRecord(record);
          } catch (err) {
            console.warn(err);
          }
        });
      })
      .then(() => this.setState({ showSpinner: false }))
      .then(() =>
        this.setState({ message: { display: true, text: "修改成功！" } })
      )
      .catch(err => {
        this.setState({ showSpinner: false });
        console.warn(err);
      });
  };

  _fetchMomVisitReordsByTmw = () => {
    let { appActions } = this.props;

    let today = new Date();
    let tomorrow = new Date(today.getTime() + 86400000);
    let from_date = `${tomorrow.getFullYear()}-${(
      "00" +
      (tomorrow.getMonth() + 1)
    ).slice(-2)}-${("00" + tomorrow.getDate()).slice(-2)}`;

    appActions.momCenter
      .fetchVisitByDate({ from_date, duration: 1, field: "visit_time" })
      .then(records => {
        let data = records.map(record => ({ ...record, edit: false }));
        this.setState({ momVisitReordsByTmw: data });
      })
      .catch(err => console.warn(err));
  };

  _fetchMomExpectedRecordsByWeek = () => {
    //預產期（按照預約參觀說的時間）前一週（七天整）提醒 也是打電話
    let { appActions } = this.props;

    let today = new Date();
    let week = new Date(today.getTime() + 86400000 * 7);
    let from_date = `${week.getFullYear()}-${(
      "00" +
      (week.getMonth() + 1)
    ).slice(-2)}-${("00" + week.getDate()).slice(-2)}`;

    appActions.momCenter
      .fetchVisitByDate({ from_date, duration: 0, field: "expected_date" })
      .then(records => {
        let data = records.map(record => ({ ...record, edit: false }));
        this.setState({ momExpectedRecordsByWeek: data });
      })
      .catch(err => console.warn(err));
  };

  _fetchMomLivingRecordsByTmw = () => {
    //排房當天的前一天 顯示入住關懷提醒
    let { appActions } = this.props;

    let today = new Date();
    let tomorrow = new Date(today.getTime() + 86400000);
    let from_date = `${tomorrow.getFullYear()}-${(
      "00" +
      (tomorrow.getMonth() + 1)
    ).slice(-2)}-${("00" + tomorrow.getDate()).slice(-2)}`;

    appActions.momCenter
      .searchMomLivingRecordsByDate({ from_date, duration: 1 })
      .then(records => {
        let tomorrowRecords = records.filter(
          record => record.from_date === from_date
        );
        let data = tomorrowRecords.map(record => ({ ...record, edit: false }));
        this.setState({ momLivingRecordsByTmw: data });
      })
      .catch(err => console.warn(err));
  };
}

const SectionTitle = styled.div`
  margin: 10px 15px;
  font-size: 16px;
  color: #777777;
`;

const RecordItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${props => (props.needHighlight ? "#E4A5B3" : "#777777")};

  & > input {
    margin-right: 8px;
  }

  & > div {
    flex: 1;
    padding: 5px 10px;
    cursor: pointer;

    :hover {
      border-radius: 10px;
      background: #e8e8e8;
    }
  }
`;

export default connect(
  null,
  ActionCreator
)(ConcernDialog);
