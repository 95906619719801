import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import ActionCreator from "../../../src/ActionCreator";
import Selectors from "../../../src/Selectors";
import Dialog from "../../../src/Components/Dialog";

const Close = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

const Error = styled.h3`
  color: red;
`;

class MomVisitFormDialog extends React.Component {
  constructor(props) {
    super(props);

    let { record, mother, appActions } = this.props;
    let isCreateView = false;

    if (!record) {
      isCreateView = true;
      record = {
        mother: mother.id,
        from_date: "",
        duration: 30,
        enter: "",
        exit: "",
        room: "",
        delivery_method: "",
        baby_count: "",
        birth_index: "",
        concern_checkin: false,
        checkin: false
      };
    }

    this.state = {
      record: record && { ...record },
      isEditing: true,
      isUpdating: false,
      error: null,
      isCreateView
    };

    this.createApi = appActions.momCenter.createMomLivingRecord;
    this.updateApi = appActions.momCenter.updateMomLivingRecord;
  }

  render() {
    let { requestClose, rooms } = this.props;
    let { record, isEditing, isUpdating, error, isCreateView } = this.state;

    return (
      <Dialog>
        <Widget.DialogWrapper>
          <Widget.Row wrap={"true"}>
            <Widget.FormField stacked>
              <label>起始日期</label>
              <input
                type="date"
                value={record.from_date}
                onChange={e =>
                  this.setState({
                    record: { ...record, from_date: e.target.value }
                  })
                }
                disabled={!isEditing || isUpdating}
              />
            </Widget.FormField>

            <Widget.FormField stacked>
              <label>天數</label>
              <input
                type="number"
                value={record.duration}
                onChange={e =>
                  this.setState({
                    record: { ...record, duration: e.target.value }
                  })
                }
                disabled={!isEditing || isUpdating}
              />
            </Widget.FormField>

            <Widget.FormField stacked>
              <label>房間</label>
              <select
                // first time we use record.room.id, since the
                // data comes from api server.
                // once the value changed, simply use record.room
                // since we only pass the id to setState in onChange.
                value={record.room.id || record.room}
                onChange={e =>
                  this.setState({
                    record: { ...record, room: e.target.value }
                  })
                }
              >
                <option value="">---</option>

                {rooms.map(room => (
                  <option key={room.id} value={room.id}>
                    {room.name}
                  </option>
                ))}
              </select>
            </Widget.FormField>

            <Widget.FormField stacked>
              <label>入住原因</label>
              <select
                value={record.enter}
                onChange={e =>
                  this.setState({
                    record: { ...record, enter: e.target.value }
                  })
                }
              >
                <option value="new">新入住</option>
                <option value="change">換房</option>
                <option value="expand">延長天數</option>
              </select>
            </Widget.FormField>

            <Widget.FormField stacked>
              <label>出住原因</label>
              <select
                value={record.exit}
                onChange={e =>
                  this.setState({
                    record: { ...record, exit: e.target.value }
                  })
                }
              >
                <option value="">---</option>
                <option value="checkout">退房結帳</option>
              </select>
            </Widget.FormField>

            <Widget.FormField stacked>
              <label>生產方式</label>
              <select
                value={record.delivery_method}
                onChange={e =>
                  this.setState({
                    record: {
                      ...record,
                      delivery_method: e.target.value
                    },
                    emptyRooms: null
                  })
                }
              >
                <option value="">---</option>
                <option value="CS">CS</option>
                <option value="NSD">NSD</option>
              </select>
            </Widget.FormField>

            <Widget.FormField stacked>
              <label>胎數</label>
              <input
                type="number"
                value={record.baby_count}
                onChange={e =>
                  this.setState({
                    record: {
                      ...record,
                      baby_count: e.target.value
                    },
                    emptyRooms: null
                  })
                }
              />
            </Widget.FormField>

            <Widget.FormField stacked>
              <label>胎次</label>
              <input
                type="number"
                value={record.birth_index}
                onChange={e =>
                  this.setState({
                    record: {
                      ...record,
                      birth_index: e.target.value
                    },
                    emptyRooms: null
                  })
                }
              />
            </Widget.FormField>

            <Widget.FormField inputWidth="30px">
              <input
                type="checkbox"
                checked={record.concern_checkin}
                onChange={e =>
                  this.setState({
                    record: {
                      ...record,
                      concern_checkin: e.target.checked
                    },
                    emptyRooms: null
                  })
                }
              />
              <label>入住關懷提醒</label>
            </Widget.FormField>

            <Widget.FormField inputWidth="30px">
              <input
                type="checkbox"
                checked={record.checkin}
                onChange={e =>
                  this.setState({
                    record: { ...record, checkin: e.target.checked },
                    emptyRooms: null
                  })
                }
              />
              <label>已入住</label>
            </Widget.FormField>
          </Widget.Row>

          {error && <Error>{error}</Error>}

          {isEditing &&
            (isUpdating ? (
              <Widget.Spinner />
            ) : isCreateView ? (
              <Widget.Row>
                <Widget.Button onClick={this._confirmCreate}>
                  確認新增
                </Widget.Button>
              </Widget.Row>
            ) : (
              <Widget.Row>
                <Widget.Button onClick={this._confirmUpdate}>
                  確認修改
                </Widget.Button>
              </Widget.Row>
            ))}

          <Close onClick={requestClose}>
            <Icon.Close />
          </Close>
        </Widget.DialogWrapper>
      </Dialog>
    );
  }

  _confirmCreate = () => {
    let { requestRefresh, requestClose } = this.props;
    let { record } = this.state;

    this.setState({ isUpdating: true });
    this.createApi(record)
      .then(() => {
        this.setState({ isUpdating: false });
        requestRefresh();
        requestClose();
      })
      .catch(() =>
        this.setState({
          isUpdating: false,
          error: "請確認該房間當下的排房情形有無重複"
        })
      );
  };

  _confirmUpdate = () => {
    let { requestRefresh, requestClose } = this.props;
    let { record } = this.state;

    this.setState({ isUpdating: true });
    this.updateApi(record)
      .then(() => {
        this.setState({ isUpdating: false });
        requestRefresh();
        requestClose();
      })
      .catch(() =>
        this.setState({
          isUpdating: false,
          error: "請確認該房間當下的排房情形有無重複"
        })
      );
  };
}

export default connect(
  (state, ownProps) => ({
    rooms: Selectors.momCenter.getRooms(state)
  }),
  ActionCreator
)(MomVisitFormDialog);
