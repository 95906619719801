import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Selectors from "../../../src/Selectors";
import ActionCreator from "../../../src/ActionCreator";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import MomListingDialog from "./MomListingDialog";
import BabyDetailDialog from "./BabyDetailDialog";
import BabyList from "./BabyList";
import BabyInfoForm from "./BabyInfoForm";
import BabyLivingRecordList from "./BabyLivingRecordList";
import BabyHealthRecordList from "./BabyHealthRecordList";
import BabyMomRoomingInRecordList from "./BabyMomRoomingInRecordList";
import IncubatorSchedulingPanel from "./IncubatorSchedulingPanel";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
`;

const SearchArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  background-color: #e8e8e8;

  & > .status-section {
    width: 100%;
  }

  @media screen and (max-width: 770px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StatusBar = styled.div`
  margin: 0px 20px 15px 0px;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(154, 154, 154, 0.5);
  width: ${props => (props.large ? "640" : "450")}px;
  height: 70px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;

  & > .customerInfo {
    width: 160px;
    font-size: 14px;
    color: #777777;

    @media screen and (max-width: 770px) {
      width: 100px;
    }
  }

  & > .title {
    margin-right: 30px;
    border-right: 1px solid #eaeaea;
    padding: 10px;
    max-width: 58px;
    text-align: center;
    font-size: 14px;
    color: #777777;
  }

  & > .searchInput {
    display: flex;
    align-items: center;
    border: 1px solid #e9eff4;
    border-radius: 4px;
    margin-right: 20px;
    padding-left: 10px;
    width: 220px;
    height: 40px;
  }

  & > div > input {
    border: 0px;
    padding-left: 10px;
    font-size: 14px;
    color: #777777;

    ::placeholder {
      color: lightgray;
    }

    :focus {
      outline: none;
    }
  }

  @media screen and (max-width: 770px) {
    width: ${props => props.large && "550"}px;
  }
`;

const BabyCreate = styled.div`
  display: flex;
  align-items: center;
  border: 1px dashed #979797;
  border-radius: 5px;
  padding: 15px;
  width: 188px;
  height: 70px;
  font-size: 14px;
  color: #777777;
  cursor: pointer;

  @media screen and (max-width: 770px) {
    width: 176px;
    height: 65px;
  }
`;

const Message = styled.div`
  padding: 20px;
  font-size: 14px;
  color: #777777;
`;

const UiTabs = {
  babyInfo: "babyInfo",
  incubator: "incubator",
  babyHealthRecord: "babyHealthRecord",
  babyMomRoomingInRecord: "babyMomRoomingInRecord"
};

const UiState = {
  normal: "normal",
  searchingMom: "searchingMom", //show MomListing Dialog
  creating: "creating", //show CreatingBabyDialog, with create flag
  selectedMom: "selectedMom" //show mom detail & back to search
};

class BabyDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetailDialog: false,
      values: {
        name: "",
        phone: ""
      },
      showIncubatorSchedulingPanel: false
    };
  }

  render() {
    let {
      dashboardState: { selectedTab, uiState, selectedMother, selectedBaby },
      appActions
    } = this.props;
    let { values, showDetailDialog, showIncubatorSchedulingPanel } = this.state;

    return (
      <Wrapper>
        <SearchArea>
          {uiState === UiState.selectedMom ? (
            <div className="status-section">
              <StatusBar large>
                <div className="customerInfo" style={{ marginLeft: 20 }}>
                  {selectedMother.name}
                  女士
                </div>
                <div className="customerInfo">
                  客戶編號： {selectedMother.id}
                </div>
                <div className="customerInfo">
                  客戶電話： {selectedMother.phone}
                </div>
                <Widget.Button
                  className="white-button"
                  css="margin: 0px 10px 0px; width: 100px;"
                  onClick={() =>
                    appActions.momCenter.setBabyDashboardState({
                      selectedMother: null,
                      selectedBaby: null,
                      uiState: UiState.normal
                    })
                  }
                >
                  返回搜尋
                </Widget.Button>
              </StatusBar>

              <Widget.Row wrap="wrap" css="margin-bottom: 10px;">
                <BabyList
                  babies={selectedMother.babies}
                  selectedBaby={selectedBaby}
                  onSelect={baby =>
                    appActions.momCenter.setBabyDashboardState({
                      selectedBaby: baby
                    })
                  }
                  fetchMother={this._fetchMother}
                />
                <BabyCreate
                  onClick={() => this.setState({ showDetailDialog: true })}
                >
                  <Icon.Add color="#777777" />
                  新增
                </BabyCreate>
              </Widget.Row>
            </div>
          ) : (
            <StatusBar>
              <div className="title">搜尋客戶</div>

              <div className="searchInput">
                <Icon.Search color="lightgray" />
                <input
                  placeholder={"姓名或電話"}
                  value={values.name || values.phone}
                  onChange={e => {
                    if (isNaN(e.target.value)) {
                      return this.setState({
                        values: { ...values, name: e.target.value }
                      });
                    }
                    return this.setState({
                      values: { name: "", phone: e.target.value }
                    });
                  }}
                />
              </div>
              <Widget.Button
                css="margin: 0px 10px 0px 0px; width: 100px;"
                onClick={() =>
                  appActions.momCenter.setBabyDashboardState({
                    uiState: UiState.searchingMom
                  })
                }
              >
                搜尋客戶
              </Widget.Button>
            </StatusBar>
          )}

          <Widget.Button
            className="white-button"
            css="margin: 0px 0px 15px; width: 120px;"
            onClick={() =>
              this.setState({ showIncubatorSchedulingPanel: true })
            }
          >
            排保溫箱詳情
          </Widget.Button>
        </SearchArea>

        <Widget.TabContainer>
          <div style={{ backgroundColor: "#E8E8E8" }}>
            <Widget.TabItem
              selected={selectedTab === UiTabs.babyInfo}
              onClick={() =>
                appActions.momCenter.setBabyDashboardState({
                  selectedTab: UiTabs.babyInfo
                })
              }
            >
              寶寶資料
            </Widget.TabItem>
            <Widget.TabItem
              selected={selectedTab === UiTabs.incubator}
              onClick={() =>
                appActions.momCenter.setBabyDashboardState({
                  selectedTab: UiTabs.incubator
                })
              }
            >
              排保溫箱
            </Widget.TabItem>
            <Widget.TabItem
              selected={selectedTab === UiTabs.babyHealthRecord}
              onClick={() =>
                appActions.momCenter.setBabyDashboardState({
                  selectedTab: UiTabs.babyHealthRecord
                })
              }
            >
              寶寶健康紀錄
            </Widget.TabItem>
            <Widget.TabItem
              selected={selectedTab === UiTabs.babyMomRoomingInRecord}
              onClick={() =>
                appActions.momCenter.setBabyDashboardState({
                  selectedTab: UiTabs.babyMomRoomingInRecord
                })
              }
            >
              親子同室紀錄
            </Widget.TabItem>
          </div>

          <div style={{ padding: 20 }} className="content">
            {(() => {
              switch (selectedTab) {
                case UiTabs.babyInfo:
                  return (
                    <>
                      {(selectedBaby && (
                        <BabyInfoForm
                          key={selectedBaby.id}
                          mother={selectedMother}
                          baby={selectedBaby}
                          onChange={baby => {
                            appActions.momCenter.setBabyDashboardState({
                              selectedBaby: baby
                            });
                            this._fetchMother();
                          }}
                          babyListRefresh={() => this._fetchMother()}
                        />
                      )) || <Message>請選擇寶寶姓名。</Message>}
                    </>
                  );

                case UiTabs.incubator:
                  return (
                    <>
                      {(selectedBaby && (
                        <BabyLivingRecordList
                          key={selectedBaby.id}
                          selectedBaby={selectedBaby}
                        />
                      )) || <Message>請選擇寶寶姓名。</Message>}
                    </>
                  );

                case UiTabs.babyHealthRecord:
                  return (
                    <>
                      {(selectedBaby && (
                        <BabyHealthRecordList
                          key={selectedBaby.id}
                          selectedBaby={selectedBaby}
                        />
                      )) || <Message>請選擇寶寶姓名。</Message>}
                    </>
                  );

                case UiTabs.babyMomRoomingInRecord:
                  return (
                    <>
                      {(selectedBaby && (
                        <BabyMomRoomingInRecordList
                          key={selectedBaby.id}
                          selectedBaby={selectedBaby}
                        />
                      )) || <Message>請選擇寶寶姓名。</Message>}
                    </>
                  );
              }
            })()}
          </div>
        </Widget.TabContainer>
        {uiState === UiState.searchingMom && (
          <MomListingDialog
            name={values.name}
            phone={values.phone}
            requestClose={() =>
              appActions.momCenter.setBabyDashboardState({
                uiState: UiState.normal
              })
            }
            selectMother={mother => {
              appActions.momCenter.setBabyDashboardState({
                uiState: UiState.selectedMom,
                selectedTab: UiTabs.babyInfo,
                selectedMother: mother
              });
            }}
          />
        )}
        {showDetailDialog && (
          <BabyDetailDialog
            selectMother={selectedMother}
            requestClose={() => {
              this.setState({ showDetailDialog: false });
              appActions.momCenter.setBabyDashboardState({
                selectedBaby: null
              });
            }}
            fetchMother={this._fetchMother}
          />
        )}
        {showIncubatorSchedulingPanel && (
          <IncubatorSchedulingPanel
            requestClose={() =>
              this.setState({ showIncubatorSchedulingPanel: false })
            }
          />
        )}
      </Wrapper>
    );
  }

  _fetchMother = () => {
    let {
      dashboardState: { selectedMother },
      appActions
    } = this.props;

    appActions.momCenter
      .fetchMother(selectedMother.id)
      .then(selectedMother =>
        appActions.momCenter.setBabyDashboardState({ selectedMother })
      )
      .catch(err => console.warn(err));
  };
}
export default connect(
  (state, ownProps) => ({
    dashboardState: Selectors.momCenter.getBabyDashboardState(state)
  }),
  ActionCreator
)(BabyDashboard);
