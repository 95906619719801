import React from "react";
import { connect } from "react-redux";
import ActionCreator from "../../../src/ActionCreator";
import Selectors from "../../../src/Selectors";
import Dialog from "../../../src/Components/Dialog";
import * as Widget from "../../../src/Components/Widget2";

class BabyMomRoomingInReocrdDetailDialog extends React.Component {
  constructor(props) {
    super(props);

    let { record, selectedBaby, users } = this.props;
    let isCreateView = false;

    if (!record) {
      isCreateView = true;
      record = {
        baby: selectedBaby.id,
        staff: users[0].id,
        record_date: "",
        record_time: "",
        out_time: "",
        back_time: "",
        note: ""
      };
    }

    this.state = {
      record: record,
      showSpinner: false,
      errorMessage: "",
      isCreateView
    };
  }

  render() {
    let { requestClose, users } = this.props;
    let { record, showSpinner, errorMessage, isCreateView } = this.state;

    return (
      <Dialog>
        <Widget.DialogWrapper>
          {isCreateView ? (
            <Widget.DialogTitle>新增親子同室紀錄</Widget.DialogTitle>
          ) : (
            <Widget.DialogTitle>修改親子同室紀錄</Widget.DialogTitle>
          )}

          <Widget.FieldContainer>
            <Widget.FormField>
              <label>記錄日期：</label>
              <input
                type="date"
                value={record.record_date}
                onChange={e =>
                  this.setState({
                    record: { ...record, record_date: e.target.value }
                  })
                }
              />
            </Widget.FormField>

            <Widget.FormField>
              <label>記錄時間：</label>
              <input
                type="time"
                value={record.record_time}
                onChange={e =>
                  this.setState({
                    record: { ...record, record_time: e.target.value }
                  })
                }
              />
            </Widget.FormField>

            <Widget.FormField>
              <label>記錄員工：</label>
              <select
                value={record.staff}
                onChange={e =>
                  this.setState({
                    record: { ...record, staff: e.target.value }
                  })
                }
              >
                {users.map(user => (
                  <option key={user.id} value={user.id}>
                    {user.user.username}
                  </option>
                ))}
              </select>
            </Widget.FormField>

            <Widget.FormField>
              <label>推出時間：</label>
              <input
                type="time"
                value={record.out_time}
                onChange={e =>
                  this.setState({
                    record: { ...record, out_time: e.target.value }
                  })
                }
              />
            </Widget.FormField>

            <Widget.FormField>
              <label>返室時間：</label>
              <input
                type="time"
                value={record.back_time}
                onChange={e =>
                  this.setState({
                    record: { ...record, back_time: e.target.value }
                  })
                }
              />
            </Widget.FormField>

            <div style={{ marginBottom: 15 }}>
              <div
                style={{ margin: "0px 15px", fontSize: 14, color: "#777777" }}
              >
                奶量
              </div>
              <Widget.FormFieldsBlock wrap>
                <Widget.FormField>
                  <input
                    type="checkbox"
                    checked={record.breastfeeding_check}
                    onChange={e =>
                      this.setState({
                        record: {
                          ...record,
                          breastfeeding_check: e.target.checked
                        }
                      })
                    }
                  />
                  <label>親餵</label>

                  <input
                    value={record.breastfeeding_time}
                    onChange={e =>
                      this.setState({
                        record: {
                          ...record,
                          breastfeeding_time: e.target.value
                        }
                      })
                    }
                  />
                  <label style={{ marginLeft: 5 }}>/分鐘</label>
                </Widget.FormField>

                <Widget.FormField inputWidth={"100px"}>
                  <label>母乳</label>
                  <input
                    value={record.mother_milk}
                    onChange={e =>
                      this.setState({
                        record: { ...record, mother_milk: e.target.value }
                      })
                    }
                  />
                  <label style={{ marginLeft: 5 }}>ml</label>
                </Widget.FormField>

                <Widget.FormField inputWidth={"100px"}>
                  <label>配方奶</label>
                  <input
                    value={record.formula_milk}
                    onChange={e =>
                      this.setState({
                        record: { ...record, formula_milk: e.target.value }
                      })
                    }
                  />
                  <label style={{ marginLeft: 5 }}>ml</label>
                </Widget.FormField>
              </Widget.FormFieldsBlock>
            </div>

            <div style={{ margin: "0px 15px", fontSize: 14, color: "#777777" }}>
              情形
            </div>
            <Widget.FormFieldsBlock wrap>
              <Widget.FormField inputWidth={"150px"}>
                <label>大便</label>
                <input
                  value={record.stool}
                  onChange={e =>
                    this.setState({
                      record: { ...record, stool: e.target.value }
                    })
                  }
                />
              </Widget.FormField>

              <Widget.FormField inputWidth={"150px"}>
                <label>小便</label>
                <input
                  value={record.urine}
                  onChange={e =>
                    this.setState({
                      record: { ...record, urine: e.target.value }
                    })
                  }
                />
              </Widget.FormField>

              <Widget.FormField inputWidth={"150px"}>
                <label>體重</label>
                <input
                  value={record.weight}
                  onChange={e =>
                    this.setState({
                      record: { ...record, weight: e.target.value }
                    })
                  }
                />
              </Widget.FormField>
            </Widget.FormFieldsBlock>

            <div
              style={{
                marginTop: 15,
                borderTop: "2px solid #eaeaea",
                width: "100%"
              }}
            >
              <Widget.FormField stacked>
                <label>備註</label>
                <input
                  type="text"
                  value={record.note}
                  onChange={event =>
                    this.setState({
                      record: {
                        ...record,
                        note: event.target.value
                      }
                    })
                  }
                />
              </Widget.FormField>
            </div>
          </Widget.FieldContainer>

          {errorMessage && (
            <div style={{ color: "red", padding: "10px" }}>{errorMessage}</div>
          )}

          <div
            style={{ margin: 10, display: "flex", justifyContent: "flex-end" }}
          >
            {showSpinner ? (
              <Widget.Spinner />
            ) : isCreateView ? (
              <Widget.Button onClick={this._confirmCreate}>
                確認新增
              </Widget.Button>
            ) : (
              <Widget.Button onClick={this._confirmUpdate}>
                確認修改
              </Widget.Button>
            )}

            <div onClick={requestClose}>
              <Widget.Button
                color={"#ffffff"}
                textColor={"#777777"}
                css={"border: 1px solid #DFDFDF"}
              >
                取消
              </Widget.Button>
            </div>
          </div>
        </Widget.DialogWrapper>
      </Dialog>
    );
  }

  _confirmCreate = () => {
    let { appActions, requestClose, requestRefresh } = this.props;
    let { record } = this.state;

    if (record.record_date === "" || record.record_time === "") {
      return this.setState({ errorMessage: "記錄日期、記錄時間不可空白！" });
    }

    if (record.out_time === "" || record.back_time === "") {
      return this.setState({ errorMessage: "推出時間、返出時間不可空白！" });
    }

    this.setState({ showSpinner: true });
    appActions.momCenter
      .createBabyMomRoomingInRecord(record)
      .then(() => this.setState({ showSpinner: false }))
      .then(requestClose)
      .then(requestRefresh)
      .catch(err => console.warn(err));
  };

  _confirmUpdate = () => {
    let { appActions, requestClose, requestRefresh } = this.props;
    let { record } = this.state;

    if (record.record_date === "" || record.record_time === "") {
      return this.setState({ errorMessage: "記錄日期、記錄時間不可空白！" });
    }

    if (record.out_time === "" || record.back_time === "") {
      return this.setState({ errorMessage: "推出時間、返出時間不可空白！" });
    }

    this.setState({ showSpinner: true });
    appActions.momCenter
      .updateBabyMomRoomingInRecord(record)
      .then(() => this.setState({ showSpinner: false }))
      .then(requestClose)
      .then(requestRefresh)
      .catch(err => console.warn(err));
  };
}

export default connect(
  (state, ownProps) => ({
    users: Selectors.momCenter.getUsers(state)
  }),
  ActionCreator
)(BabyMomRoomingInReocrdDetailDialog);
