import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import Selectors from "../../../src/Selectors";
import ActionCreator from "../../../src/ActionCreator";
import RepairmentRecordDetailDialog from "./RepairmentRecordDetailDialog";
import ConfirmDialog from "./ConfirmDialog";
import RepairmentHistoryRecordList from "./RepairmentHistoryRecordList";

const Wrapper = styled.div`
  height: 100vh;
  background-color: #e8e8e8;
  padding: 10px;
  display: flex;
`;

const Container = styled.div`
  padding: 20px;
  flex: 1;
  overflow: auto;
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(154, 154, 154, 0.5);

  & > .title {
    color: #777777;
    font-weight: bold;
    font-size: 16px;
  }

  & > .status-section {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 11px;
    max-width: 1040px;
  }
`;

const Status = styled.div`
  display: flex;
  align-items: center;

  & > .round {
    margin-right: 10px;
    border-radius: 50px;
    width: 15px;
    height: 15px;
    background-color: ${props => props.color || "gray"};
  }

  & > .label {
    margin-right: ${props => (props.last ? "5" : "30")}px;
    color: #777777;
    font-size: 14px;
  }
`;

const Clickable = styled.div`
  cursor: pointer;
  transition: transform 0.2s;

  & :hover {
    transform: scale(0.9);
  }

  ${props => props.css || ""};
`;

const StyledForm = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  & > .cell {
    margin: 0px 10px 10px 0px;
    border: 1px solid #88add8;
    width: 200px;
    height: 175px;
  }

  & .room-bar {
    display: flex;
    align-items: flex-end;
    padding: 2px 15px;
    height: 30px;
    background-color: #88add8;

    & > .room-number {
      margin-right: 97px;
      font-size: 18px;
      color: #ffffff;
    }

    & > .room-icon {
      margin-right: 5px;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 770px) {
    & > .cell {
      width: 170px;
      height: 130px;
    }

    & .room-bar {
      & > .room-number {
        margin-right: 69px;
      }
    }
  }
`;

const Content = styled.div`
  max-height: 128px;
  overflow: auto;

  @media screen and (max-width: 770px) {
    max-height: 90px;
  }
`;

const RepairmentRecord = styled.div`
  width: 190px;
  height: 30px;
  margin: 5px;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => (props.isIdle ? "#F3C7AE" : "#B0CFDB")};
  font-size: 14px;
  color: #777777;

  @media screen and (max-width: 770px) {
    width: 160px;
  }
`;

class RepairmentDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records: null,
      showSpinner: false,
      showDetailDialog: false,
      showHistoryRecordList: false,
      showConfirmDialog: false,
      selectedRoom: null,
      selectedRecord: null
    };
  }

  componentDidMount() {
    this._fetchRecords();
  }

  render() {
    let { rooms, appActions } = this.props;
    let {
      records,
      showSpinner,
      showDetailDialog,
      showConfirmDialog,
      selectedRoom,
      selectedRecord,
      showHistoryRecordList
    } = this.state;

    return (
      <Wrapper>
        <Container>
          {showHistoryRecordList ? (
            <RepairmentHistoryRecordList
              room={selectedRoom}
              goBack={() => {
                this.setState({ showHistoryRecordList: false });
                this._fetchRecords();
              }}
            />
          ) : showSpinner ? (
            <Widget.Spinner />
          ) : (
            <>
              <div className="title">修繕紀錄</div>

              <div className="status-section">
                <Status color="#F3C7AE">
                  <div className="round" />
                  <div className="label">未完成</div>
                </Status>
                <Status color="#B0CFDB">
                  <div className="round" />
                  <div className="label">處理中</div>
                </Status>
                <Status color="#E6E6E6" last>
                  <div className="round" />
                  <div className="label">已完成</div>
                </Status>
              </div>

              {rooms && records && (
                <StyledForm>
                  {rooms.map((room, i) => {
                    let checkedInRoom = records.filter(
                      record => room.id === record.room.id
                    );

                    return (
                      <div className="cell" key={i}>
                        <div className="room-bar">
                          <div className="room-number">{room.name}</div>

                          <div
                            className="room-icon"
                            onClick={() =>
                              this.setState({
                                showDetailDialog: true,
                                selectedRoom: room,
                                selectedRecord: null
                              })
                            }
                          >
                            <Icon.Add
                              color="white"
                              style={{ width: 20, height: 20 }}
                            />
                          </div>

                          <div
                            className="room-icon"
                            onClick={() =>
                              this.setState({
                                showHistoryRecordList: true,
                                selectedRoom: room
                              })
                            }
                          >
                            <img
                              src="/images/pages/icon-list.png"
                              style={{ width: 20, height: 20 }}
                            />
                          </div>
                        </div>

                        <Content>
                          {checkedInRoom.map((record, i) => {
                            return (
                              <RepairmentRecord
                                key={i}
                                isIdle={record.status === "idle"}
                              >
                                {record.summary}
                                <Widget.Row>
                                  <Clickable
                                    onClick={() =>
                                      this.setState({
                                        showDetailDialog: true,
                                        selectedRecord: record
                                      })
                                    }
                                  >
                                    <Icon.Edit
                                      color={"#4485D1"}
                                      style={{ width: 20, height: 20 }}
                                    />
                                  </Clickable>
                                  <Clickable
                                    onClick={() =>
                                      this.setState({
                                        showConfirmDialog: true,
                                        selectedRecord: record
                                      })
                                    }
                                  >
                                    <Icon.Delete
                                      color={"#4485D1"}
                                      style={{ width: 20, height: 20 }}
                                    />
                                  </Clickable>
                                </Widget.Row>
                              </RepairmentRecord>
                            );
                          })}
                        </Content>
                      </div>
                    );
                  })}
                  {showDetailDialog && (
                    <RepairmentRecordDetailDialog
                      record={selectedRecord}
                      room={selectedRoom}
                      requestClose={() =>
                        this.setState({ showDetailDialog: false })
                      }
                      requestRefresh={this._fetchRecords}
                    />
                  )}
                  {showConfirmDialog && (
                    <ConfirmDialog
                      text="確定要刪除嗎？此動作無法還原。"
                      record={selectedRecord}
                      requestClose={() =>
                        this.setState({ showConfirmDialog: false })
                      }
                      confirmAction={() => {
                        appActions.momCenter
                          .deleteRepairment(selectedRecord.id)
                          .then(this._fetchRecords)
                          .then(() =>
                            this.setState({ showConfirmDialog: false })
                          )
                          .catch(err => console.warn(err));
                      }}
                    />
                  )}
                </StyledForm>
              )}
            </>
          )}
        </Container>
      </Wrapper>
    );
  }

  _fetchRecords = () => {
    let { appActions } = this.props;

    this.setState({ showSpinner: true });
    appActions.momCenter
      .fetchRepairmentRecord()
      .then(records => this.setState({ records }))
      .then(() => this.setState({ showSpinner: false }))
      .catch(err => console.warn(err));
  };
}
export default connect(
  (state, ownProps) => ({
    rooms: Selectors.momCenter.getRooms(state)
  }),
  ActionCreator
)(RepairmentDashboard);
