import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../../../src/ActionCreator";
import Selectors from "../../../src/Selectors";
import Dialog from "../../../src/Components/Dialog";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";

class MomCheckinEvalDetailDialog extends React.Component {
  constructor(props) {
    super(props);

    let { mother, record } = this.props;
    let isCreateView = false;

    if (!record) {
      isCreateView = true;
      record = {
        mother: mother.id,
        staff: 1,
        care_date: "",
        care_time: "",
        content: ""
      };
    }

    this.state = {
      record: record,
      isCreateView,
      errorMsg: ""
    };
  }

  render() {
    let { mother, requestClose, users } = this.props;
    let { record, isCreateView, errorMsg } = this.state;

    return (
      <Dialog>
        <Wrapper>
          {isCreateView ? <h2>新增入住後關懷表</h2> : <h2>修改入住後關懷表</h2>}

          <Widget.FormField stacked>
            <label>關懷員工：</label>
            <select
              value={record.staff}
              onChange={e =>
                this.setState({ record: { ...record, staff: e.target.value } })
              }
            >
              {users.map(user => (
                <option key={user.id} value={user.id}>
                  {user.user.username}
                </option>
              ))}
            </select>
          </Widget.FormField>

          <Widget.FormField>
            <label>關懷日期：</label>
            <input
              type="date"
              value={record.care_date}
              onChange={e =>
                this.setState({
                  record: { ...record, care_date: e.target.value }
                })
              }
            />
          </Widget.FormField>

          <Widget.FormField>
            <label>關懷時間：</label>
            <input
              type="time"
              value={record.care_time}
              onChange={e =>
                this.setState({
                  record: { ...record, care_time: e.target.value }
                })
              }
            />
          </Widget.FormField>

          <Widget.FormField>
            <label>關懷內容：</label>
            <textarea
              value={record.content}
              onChange={e =>
                this.setState({
                  record: { ...record, content: e.target.value }
                })
              }
            />
          </Widget.FormField>

          {errorMsg && (
            <div style={{ margin: 20, color: "red" }}>{errorMsg}</div>
          )}

          {isCreateView ? (
            <Widget.Button onClick={this._confirmCreate}>
              確認新增
            </Widget.Button>
          ) : (
            <Widget.Button onClick={this._confirmUpdate}>
              確認修改
            </Widget.Button>
          )}
          <Close onClick={requestClose}>
            <Icon.Close />
          </Close>
        </Wrapper>
      </Dialog>
    );
  }

  _confirmCreate = () => {
    let { appActions, requestClose, requestRefresh } = this.props;
    let { record } = this.state;

    if (record.care_date === "" || record.care_time === "") {
      return this.setState({ errorMsg: "請填寫關懷時間及日期！" });
    }

    appActions.momCenter
      .createMomCheckinEval(record)
      .then(requestClose)
      .then(requestRefresh)
      .catch(err => console.warn(err));
  };

  _confirmUpdate = () => {
    let { appActions, requestClose, requestRefresh } = this.props;
    let { record } = this.state;

    appActions.momCenter
      .updateMomCheckinEval(record)
      .then(requestClose)
      .then(requestRefresh)
      .catch(err => console.warn(err));
  };
}

const Wrapper = styled.div`
  padding: 10px;
  position: relative;
`;

const Close = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

export default connect(
  (state, ownProps) => ({
    users: Selectors.momCenter.getUsers(state)
  }),
  ActionCreator
)(MomCheckinEvalDetailDialog);
