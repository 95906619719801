import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Selectors from "../../../src/Selectors";
import ActionCreator from "../../../src/ActionCreator";
import Dialog from "../../../src/Components/Dialog";
import * as Widget from "../../Components/Widget2";
import * as Icon from "../../../src/Components/Icon";

class BabyHealthRecordDetailDialog extends React.Component {
  constructor(props) {
    super(props);

    let { selectedBaby, record, users } = this.props;
    let isCreateView = false;

    if (!record) {
      record = {
        baby: selectedBaby.id,
        staff: users[0].id,
        record_date: "",
        record_time: "",
        note: ""
      };
      isCreateView = true;
    }

    this.state = {
      record: { ...record },
      showSpinner: false,
      errorMessage: "",
      isCreateView
    };
  }

  render() {
    let { requestClose, users } = this.props;
    let { record, showSpinner, errorMessage, isCreateView } = this.state;

    return (
      <Dialog>
        <Widget.DialogWrapper>
          {isCreateView ? (
            <Widget.DialogTitle>新增寶寶健康紀錄</Widget.DialogTitle>
          ) : (
            <Widget.DialogTitle>修改寶寶健康紀錄</Widget.DialogTitle>
          )}

          <Widget.FieldContainer>
            <Widget.FormField>
              <label>記錄日期：</label>
              <input
                type="date"
                value={record.record_date}
                onChange={e =>
                  this.setState({
                    record: { ...record, record_date: e.target.value }
                  })
                }
              />
            </Widget.FormField>

            <Widget.FormField>
              <label>記錄時間：</label>
              <input
                type="time"
                value={record.record_time}
                onChange={e =>
                  this.setState({
                    record: { ...record, record_time: e.target.value }
                  })
                }
              />
            </Widget.FormField>

            <Widget.FormField>
              <label>記錄員工：</label>
              <select
                value={record.staff}
                onChange={e =>
                  this.setState({
                    record: { ...record, staff: e.target.value }
                  })
                }
              >
                {users.map(user => (
                  <option key={user.id} value={user.id}>
                    {user.user.username}
                  </option>
                ))}
              </select>
            </Widget.FormField>

            <div>
              <div
                style={{ margin: "0px 15px", fontSize: 14, color: "#777777" }}
              >
                健康狀況
              </div>
              <Widget.FormFieldsBlock>
                <Widget.FormField stacked>
                  <label>BW(gm):</label>
                  <input
                    value={record.baby_weight}
                    onChange={e =>
                      this.setState({
                        record: { ...record, baby_weight: e.target.value }
                      })
                    }
                  />
                </Widget.FormField>

                <Widget.FormField stacked>
                  <label>BT(ºC):</label>
                  <input
                    value={record.baby_temperature}
                    onChange={e =>
                      this.setState({
                        record: { ...record, baby_temperature: e.target.value }
                      })
                    }
                  />
                </Widget.FormField>

                <Widget.FormField stacked>
                  <label>心跳:</label>
                  <input
                    value={record.baby_heartbeat}
                    onChange={e =>
                      this.setState({
                        record: { ...record, baby_heartbeat: e.target.value }
                      })
                    }
                  />
                </Widget.FormField>

                <Widget.FormField stacked>
                  <label>呼吸:</label>
                  <input
                    value={record.baby_breath}
                    onChange={e =>
                      this.setState({
                        record: { ...record, baby_breath: e.target.value }
                      })
                    }
                  />
                </Widget.FormField>
              </Widget.FormFieldsBlock>

              <Widget.FormFieldsBlock>
                <Widget.MultipleChoicesFormField>
                  <label>臍帶</label>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div className="choice-of-multiple">
                      <input
                        type="checkbox"
                        checked={record.omphalus_exist}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              omphalus_exist: e.target.checked
                            }
                          })
                        }
                      />
                      <label>存在</label>
                    </div>

                    <div className="choice-of-multiple">
                      <input
                        type="checkbox"
                        checked={record.omphalus_drop}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              omphalus_drop: e.target.checked
                            }
                          })
                        }
                      />
                      <label>脫落</label>
                    </div>

                    <div className="choice-of-multiple">
                      <input
                        type="checkbox"
                        checked={record.omphalus_wet}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              omphalus_wet: e.target.checked
                            }
                          })
                        }
                      />
                      <label>濕</label>
                    </div>

                    <div className="choice-of-multiple">
                      <input
                        type="checkbox"
                        checked={record.omphalus_dry}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              omphalus_dry: e.target.checked
                            }
                          })
                        }
                      />
                      <label>乾燥</label>
                    </div>

                    <div className="choice-of-multiple">
                      <input
                        type="checkbox"
                        checked={record.omphalus_yellow_liquid}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              omphalus_yellow_liquid: e.target.checked
                            }
                          })
                        }
                      />
                      <label>淡黃滲液</label>
                    </div>

                    <div className="choice-of-multiple">
                      <input
                        type="checkbox"
                        checked={record.omphalus_blood}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              omphalus_blood: e.target.checked
                            }
                          })
                        }
                      />
                      <label>滲血</label>
                    </div>
                  </div>
                </Widget.MultipleChoicesFormField>
              </Widget.FormFieldsBlock>

              <Widget.FormFieldsBlock>
                <label>母乳</label>

                <Widget.FormField stacked>
                  <label>親餵持續時間(分鐘):</label>
                  <input
                    value={record.breast_feeding_period}
                    onChange={e =>
                      this.setState({
                        record: {
                          ...record,
                          breast_feeding_period: e.target.value
                        }
                      })
                    }
                  />
                </Widget.FormField>

                <Widget.FormField stacked>
                  <label>瓶餵(c.c):</label>
                  <input
                    value={record.breast_feeding_bottle_amount}
                    onChange={e =>
                      this.setState({
                        record: {
                          ...record,
                          breast_feeding_bottle_amount: e.target.value
                        }
                      })
                    }
                  />
                </Widget.FormField>
              </Widget.FormFieldsBlock>

              <Widget.FormFieldsBlock>
                <label>配方奶</label>

                <Widget.FormField stacked>
                  <label>廠牌</label>
                  <input
                    value={record.formula_milk_brand}
                    onChange={e =>
                      this.setState({
                        record: {
                          ...record,
                          formula_milk_brand: e.target.value
                        }
                      })
                    }
                  />
                </Widget.FormField>

                <Widget.FormField stacked>
                  <label>瓶餵(c.c):</label>
                  <input
                    value={record.formula_milk_bottle_amount}
                    onChange={e =>
                      this.setState({
                        record: {
                          ...record,
                          formula_milk_bottle_amount: e.target.value
                        }
                      })
                    }
                  />
                </Widget.FormField>

                <Widget.FormField>
                  <input
                    type="checkbox"
                    checked={record.formula_milk_lactose_free}
                    onChange={e =>
                      this.setState({
                        record: {
                          ...record,
                          formula_milk_lactose_free: e.target.checked
                        }
                      })
                    }
                  />
                  <label>無乳糖</label>
                </Widget.FormField>

                <Widget.FormField>
                  <input
                    type="checkbox"
                    checked={record.formula_milk_hydrolysis}
                    onChange={e =>
                      this.setState({
                        record: {
                          ...record,
                          formula_milk_hydrolysis: e.target.checked
                        }
                      })
                    }
                  />
                  <label>水解</label>
                </Widget.FormField>

                <Widget.FormField stacked>
                  <label>其他</label>
                  <textarea
                    value={record.formula_milk_note}
                    onChange={e =>
                      this.setState({
                        record: { ...record, formula_milk_note: e.target.value }
                      })
                    }
                  />
                </Widget.FormField>
              </Widget.FormFieldsBlock>

              <Widget.FormFieldsBlock>
                <Widget.FormField stacked>
                  <label>吸吞力</label>
                  <select
                    value={record.suction}
                    onChange={e =>
                      this.setState({
                        record: { ...record, suction: e.target.value }
                      })
                    }
                  >
                    <option value="strong">強</option>
                    <option value="medium">中</option>
                    <option value="weak">弱</option>
                  </select>
                </Widget.FormField>

                <Widget.FormField stacked>
                  <label>活力</label>
                  <select
                    value={record.activity}
                    onChange={e =>
                      this.setState({
                        record: { ...record, activity: e.target.value }
                      })
                    }
                  >
                    <option value="good">佳</option>
                    <option value="common">可</option>
                    <option value="weak">弱</option>
                  </select>
                </Widget.FormField>

                <Widget.FormField stacked>
                  <label>皮膚顏色</label>
                  <select
                    value={record.skin_color}
                    onChange={e =>
                      this.setState({
                        record: { ...record, skin_color: e.target.value }
                      })
                    }
                  >
                    <option value="yellow">黃</option>
                    <option value="pastel-yellow">粉黃</option>
                    <option value="pink">粉紅</option>
                    <option value="pale">蒼白</option>
                  </select>
                </Widget.FormField>

                <Widget.FormField stacked>
                  <label>皮膚狀況</label>
                  <select
                    value={record.skin_situation}
                    onChange={e =>
                      this.setState({
                        record: { ...record, skin_situation: e.target.value }
                      })
                    }
                  >
                    <option value="dry-peeling">乾燥脫皮</option>
                    <option value="full">飽滿</option>
                  </select>
                </Widget.FormField>
              </Widget.FormFieldsBlock>

              <Widget.FormFieldsBlock>
                <Widget.FormField>
                  <input
                    type="checkbox"
                    checked={record.anus_red}
                    onChange={e =>
                      this.setState({
                        record: { ...record, anus_red: e.target.checked }
                      })
                    }
                  />
                  <label>肛門口紅</label>
                </Widget.FormField>

                <Widget.FormField>
                  <input
                    type="checkbox"
                    checked={record.milk_regurgitation}
                    onChange={e =>
                      this.setState({
                        record: {
                          ...record,
                          milk_regurgitation: e.target.checked
                        }
                      })
                    }
                  />
                  <label>溢奶</label>
                </Widget.FormField>

                <Widget.FormField>
                  <input
                    type="checkbox"
                    checked={record.milk_spitting}
                    onChange={e =>
                      this.setState({
                        record: { ...record, milk_spitting: e.target.checked }
                      })
                    }
                  />
                  <label>吐奶</label>
                </Widget.FormField>

                <Widget.FormField>
                  <input
                    type="checkbox"
                    checked={record.face_rash_check}
                    onChange={e =>
                      this.setState({
                        record: { ...record, face_rash_check: e.target.checked }
                      })
                    }
                  />
                  <label>臉上紅疹</label>
                </Widget.FormField>

                <Widget.FormField stacked>
                  <label>臉上紅疹部位</label>
                  <input
                    value={record.face_rash_position}
                    onChange={e =>
                      this.setState({
                        record: {
                          ...record,
                          face_rash_position: e.target.value
                        }
                      })
                    }
                  />
                </Widget.FormField>
              </Widget.FormFieldsBlock>

              <Widget.FormFieldsBlock>
                <Widget.MultipleChoicesFormField>
                  <label>嬰兒感控</label>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div className="choice-of-multiple">
                      <input
                        type="checkbox"
                        checked={record.infect_uri}
                        onChange={e =>
                          this.setState({
                            record: { ...record, infect_uri: e.target.checked }
                          })
                        }
                      />
                      <label>上呼吸道感染</label>
                    </div>

                    <div className="choice-of-multiple">
                      <input
                        type="checkbox"
                        checked={record.infect_uti}
                        onChange={e =>
                          this.setState({
                            record: { ...record, infect_uti: e.target.checked }
                          })
                        }
                      />
                      <label>泌尿道感染</label>
                    </div>

                    <div className="choice-of-multiple">
                      <input
                        type="checkbox"
                        checked={record.infect_oral_thrush}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              infect_oral_thrush: e.target.checked
                            }
                          })
                        }
                      />
                      <label>鵝口瘡</label>
                    </div>

                    <div className="choice-of-multiple">
                      <input
                        type="checkbox"
                        checked={record.infect_pink_eye}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              infect_pink_eye: e.target.checked
                            }
                          })
                        }
                      />
                      <label>結膜炎</label>
                    </div>

                    <div className="choice-of-multiple">
                      <input
                        type="checkbox"
                        checked={record.infect_enterovirus}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              infect_enterovirus: e.target.checked
                            }
                          })
                        }
                      />
                      <label>腸病毒</label>
                    </div>

                    <div className="choice-of-multiple">
                      <input
                        type="checkbox"
                        checked={record.infect_red_hip}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              infect_red_hip: e.target.checked
                            }
                          })
                        }
                      />
                      <label>紅臀</label>
                    </div>

                    <div className="choice-of-multiple">
                      <input
                        type="checkbox"
                        checked={record.infect_herpes_pustulosis}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              infect_herpes_pustulosis: e.target.checked
                            }
                          })
                        }
                      />
                      <label>膿皰疹</label>
                    </div>
                  </div>
                </Widget.MultipleChoicesFormField>

                <Widget.FormField stacked>
                  <label>其他</label>
                  <textarea
                    value={record.infect_note}
                    onChange={e =>
                      this.setState({
                        record: { ...record, infect_note: e.target.value }
                      })
                    }
                  />
                </Widget.FormField>
              </Widget.FormFieldsBlock>

              <Widget.FormFieldsBlock>
                <Widget.FormField stacked>
                  <label>大便(次)</label>
                  <input
                    value={record.baby_stool}
                    onChange={e =>
                      this.setState({
                        record: { ...record, baby_stool: e.target.value }
                      })
                    }
                  />
                </Widget.FormField>

                <Widget.FormField stacked>
                  <label>小便（次）</label>
                  <input
                    value={record.baby_urine}
                    onChange={e =>
                      this.setState({
                        record: { ...record, baby_urine: e.target.value }
                      })
                    }
                  />
                </Widget.FormField>
              </Widget.FormFieldsBlock>
            </div>

            <div
              style={{
                marginTop: 15,
                borderTop: "2px solid #eaeaea",
                width: "100%"
              }}
            >
              <Widget.FormField stacked>
                <label>備註:</label>
                <input
                  value={record.note}
                  onChange={e =>
                    this.setState({
                      record: { ...record, note: e.target.value }
                    })
                  }
                />
              </Widget.FormField>
            </div>
          </Widget.FieldContainer>

          {errorMessage && (
            <div style={{ color: "red", padding: "10px" }}>{errorMessage}</div>
          )}

          <div
            style={{ margin: 10, display: "flex", justifyContent: "flex-end" }}
          >
            {showSpinner ? (
              <Widget.Spinner />
            ) : isCreateView ? (
              <Widget.Button onClick={this._confirmCreate}>
                確認新增
              </Widget.Button>
            ) : (
              <Widget.Button onClick={this._confirmUpdate}>
                確認修改
              </Widget.Button>
            )}

            <div onClick={requestClose}>
              <Widget.Button
                color={"#ffffff"}
                textColor={"#777777"}
                css={"border: 1px solid #DFDFDF"}
              >
                取消
              </Widget.Button>
            </div>
          </div>
        </Widget.DialogWrapper>
      </Dialog>
    );
  }

  _confirmCreate = () => {
    let { appActions, requestClose } = this.props;
    let { record } = this.state;

    if (
      record.record_date === null ||
      record.record_time === null ||
      record.record_date === "" ||
      record.record_time === ""
    ) {
      return this.setState({ errorMessage: "記錄日期及時間不可為空！" });
    }

    this.setState({ showSpinner: true });
    appActions.momCenter
      .createBabyHealthRecord(record)
      .then(() => this.setState({ showSpinner: false }))
      .then(requestClose)
      .catch(err => console.warn(err));
  };

  _confirmUpdate = () => {
    let { appActions, requestClose } = this.props;
    let { record } = this.state;

    if (record.record_date === "" || record.record_time === "") {
      return this.setState({ errorMessage: "記錄日期及時間不可為空！" });
    }

    this.setState({ showSpinner: false });
    appActions.momCenter
      .updateBabyHealthRecord(record)
      .then(() => this.setState({ showSpinner: false }))
      .then(requestClose)
      .catch(err => console.warn(err));
  };
}

const Wrapper = styled.div`
  padding: 20px;
  position: relative;
`;

const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

export default connect(
  (state, ownProps) => ({
    users: Selectors.momCenter.getUsers(state)
  }),
  ActionCreator
)(BabyHealthRecordDetailDialog);
