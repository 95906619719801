import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../../../src/ActionCreator";
import Selectors from "../../../src/Selectors";
import Dialog from "../../../src/Components/Dialog";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";

class MomBillingRecordDetailDialog extends React.Component {
  constructor(props) {
    super(props);

    let isCreateView = false;
    let { record, mother } = this.props;

    if (!record) {
      isCreateView = true;
      record = {
        mother: mother.id,
        title: "",
        price: 0,
        spare_part: null,
        spare_part_counts: 0,
        is_paid: false,
        note: ""
      };
    }

    this.state = {
      record: record,
      isCreateView
    };
  }

  render() {
    let { spareParts, requestClose } = this.props;
    let { record, isCreateView } = this.state;
    const selectSparePart = spareParts.find(
      sparePart => sparePart.id === parseInt(record.spare_part)
    );

    return (
      <Dialog>
        <Wrapper>
          {isCreateView ? <h2>新增帳單記錄</h2> : <h2>修改帳單記錄</h2>}

          <Widget.FormField>
            <label>標題</label>
            <input
              value={record.title}
              onChange={e =>
                this.setState({ record: { ...record, title: e.target.value } })
              }
            />
          </Widget.FormField>

          <Widget.FormField>
            <label>價格</label>
            <input
              type="number"
              value={record.price}
              onChange={e =>
                this.setState({ record: { ...record, price: e.target.value } })
              }
            />
          </Widget.FormField>

          {selectSparePart && (
            <Message>
              備品單價：{selectSparePart.unit_price}元， 建議價格：
              {selectSparePart.unit_price * record.spare_part_counts}
            </Message>
          )}

          <Widget.FormField>
            <label>備品名稱</label>
            <select
              value={record.spare_part}
              onChange={e =>
                this.setState({
                  record: { ...record, spare_part: e.target.value }
                })
              }
            >
              <option>{"----"}</option>
              {spareParts.map(sparePart => (
                <option value={sparePart.id}>{sparePart.name}</option>
              ))}
            </select>
          </Widget.FormField>

          <Widget.FormField>
            <label>備品數量</label>
            <input
              type="number"
              value={record.spare_part_counts}
              onChange={e =>
                this.setState({
                  record: { ...record, spare_part_counts: e.target.value }
                })
              }
            />
          </Widget.FormField>

          <Widget.FormField>
            <label>付款狀態</label>
            <select
              value={record.is_paid}
              onChange={e =>
                this.setState({
                  record: { ...record, is_paid: e.target.value }
                })
              }
            >
              <option value={true}>已付款</option>
              <option value={false}>未付款</option>
            </select>
          </Widget.FormField>

          <Widget.FormField>
            <label>備註</label>
            <textarea
              value={record.note}
              onChange={e =>
                this.setState({ record: { ...record, note: e.target.value } })
              }
            />
          </Widget.FormField>

          {isCreateView ? (
            <Widget.Button onClick={this._confirmCreate}>
              確認新增
            </Widget.Button>
          ) : (
            <Widget.Button onClick={this._confirmUpdate}>
              確認修改
            </Widget.Button>
          )}

          <Close onClick={requestClose}>
            <Icon.Close />
          </Close>
        </Wrapper>
      </Dialog>
    );
  }

  _confirmCreate = () => {
    let { appActions, requestClose, requestRefresh } = this.props;
    let { record } = this.state;

    appActions.momCenter
      .createMomBillingRecord(record)
      .then(requestClose)
      .then(requestRefresh)
      .catch(err => console.warn(err));
  };

  _confirmUpdate = () => {
    let { appActions, requestClose, requestRefresh } = this.props;
    let { record } = this.state;

    appActions.momCenter
      .updateMomBillingRecord(record)
      .then(requestClose)
      .then(requestRefresh)
      .catch(err => console.warn(err));
  };
}

const Wrapper = styled.div`
  padding: 10px;
  position: relative;
`;

const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const Message = styled.div`
  margin: 15px;
  font-size: 10px;
  color: lightgray;
`;

export default connect(
  (state, ownProps) => ({
    spareParts: Selectors.momCenter.getSpareParts(state)
  }),
  ActionCreator
)(MomBillingRecordDetailDialog);
