import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import ActionCreator from "../../../src/ActionCreator";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import ConfirmDialog from "./ConfirmDialog";
import BabyLivingRecordDetailDialog from "./BabyLivingRecordDetailDialog";

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
`;

class BabyLivingRecordList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records: null,
      selectedRecord: null,
      showSpinner: false,
      showDetailDialog: false,
      showConfirmDialog: false
    };
  }

  componentDidMount() {
    this._fetchRecords();
  }

  render() {
    let { appActions, selectedBaby } = this.props;
    let {
      showSpinner,
      records,
      selectedRecord,
      showDetailDialog,
      showConfirmDialog
    } = this.state;

    return (
      <Wrapper>
        <Widget.Row>
          <div
            style={{
              display: "flex",
              flex: 1,
              color: "#777777",
              fontWeight: "500",
              padding: 10
            }}
          >
            搜尋結果
          </div>
          <div style={{ margin: 10, display: "flex" }}>
            <Widget.Clickable
              onClick={() => {
                this.setState({
                  selectedRecord: null,
                  showDetailDialog: true
                });
              }}
            >
              <Widget.Button>新增排保溫箱紀錄</Widget.Button>
            </Widget.Clickable>
            <Widget.Clickable onClick={this._fetchRecords}>
              <Widget.Button className="white-button">重新整理</Widget.Button>
            </Widget.Clickable>
          </div>
        </Widget.Row>

        {showSpinner && <Widget.Spinner />}

        {!showSpinner && records != null && records.length === 0 && (
          <Widget.HintMessage>尚未建立該寶寶相關紀錄!</Widget.HintMessage>
        )}

        {!showSpinner && records && (
          <Widget.Table>
            <Widget.TableContent lessTable>
              <Widget.Fields lessTable>
                <div className="field">保溫箱名稱</div>
                <div className="date-field">起始日期</div>
                <div className="date-field">結束日期</div>
                <div className="field">天數</div>
                <div className="padding-field" />
                <div className="field" />
                <div className="field" />
              </Widget.Fields>

              {records &&
                records.map((record, i) => (
                  <div
                    className={i % 2 === 0 ? "row-even" : "row-odd"}
                    key={"record" + i}
                  >
                    <div className="row-item">
                      {record.incubator.incubator_type.name}-
                      {record.incubator.name}
                    </div>
                    <div className="date-item">{record.from_date}</div>
                    <div className="date-item">{record.end_date}</div>
                    <div className="row-item">{record.duration}</div>
                    <div className="row-item-padding" />
                    <div className="row-item">
                      <div
                        style={{ cursor: "pointer", color: "#4485D1" }}
                        onClick={() =>
                          this.setState({
                            selectedRecord: record,
                            showDetailDialog: true
                          })
                        }
                      >
                        <Icon.Edit color={"#4485D1"} />
                        編輯
                      </div>
                    </div>
                    <div className="row-item">
                      <div
                        style={{ cursor: "pointer", color: "#4485D1" }}
                        onClick={() =>
                          this.setState({
                            selectedRecord: record,
                            showConfirmDialog: true
                          })
                        }
                      >
                        <Icon.Delete color={"#4485D1"} />
                        刪除
                      </div>
                    </div>
                  </div>
                ))}
            </Widget.TableContent>
          </Widget.Table>
        )}

        {showDetailDialog && (
          <BabyLivingRecordDetailDialog
            requestClose={() => this.setState({ showDetailDialog: false })}
            selectedBaby={selectedBaby}
            record={selectedRecord}
            requestRefresh={this._fetchRecords}
          />
        )}
        {showConfirmDialog && (
          <ConfirmDialog
            text="確定要刪除嗎？此動作無法還原。"
            requestClose={() => this.setState({ showConfirmDialog: false })}
            confirmAction={() =>
              appActions.momCenter
                .deleteBabyLivingRecord(selectedRecord.id)
                .then(this._fetchRecords)
                .then(() => this.setState({ showConfirmDialog: false }))
                .catch(err => console.warn(err))
            }
          />
        )}
      </Wrapper>
    );
  }
  _fetchRecords = () => {
    let { appActions, selectedBaby } = this.props;

    this.setState({ showSpinner: true });
    appActions.momCenter
      .fetchIncubatorByBabyId(selectedBaby.id)
      .then(records => this.setState({ records }))
      .then(() => this.setState({ showSpinner: false }))
      .catch(err => {
        console.warn(err);
        this.setState({ showSpinner: false });
      });
  };
}
export default connect(
  null,
  ActionCreator
)(BabyLivingRecordList);
