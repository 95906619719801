import React from "react";
import { connect } from "react-redux";
import ActionCreator from "../../../src/ActionCreator";
import styled from "styled-components";
import Dialog from "../../../src/Components/Dialog";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import { Absolute } from "../../../src/Components/Css";

class MomPrecheckinEvalDetailDialog extends React.Component {
  constructor(props) {
    super(props);

    let { record, mother, appActions } = this.props;
    let isCreateView = false;

    if (!record) {
      isCreateView = true;
      record = {
        hospital: "",
        hospital_payment: "own",
        born_method: "natural",
        born_date: "",
        born_weight: "0",
        boys: "0",
        girls: "0",
        note: "",
        mother: mother.id
      };
    }

    this.state = {
      isCreateView,
      record: { ...record }
    };

    this.createApi = appActions.momCenter.createPrecheckinEval;
    this.updateApi = appActions.momCenter.updatePrecheckinEval;
  }

  render() {
    let { onClose } = this.props;
    let { isCreateView, record, errorMessage } = this.state;

    return (
      <Dialog>
        <Widget.DialogWrapper>
          {isCreateView ? (
            <Widget.DialogTitle>新增入住前評估表</Widget.DialogTitle>
          ) : (
            <Widget.DialogTitle>修改入住前評估表</Widget.DialogTitle>
          )}
          <Widget.FieldContainer>
            <div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  borderBottom: "2px solid #eaeaea",
                  marginBottom: 10
                }}
              >
                <h4
                  style={{
                    width: "100%",
                    display: "flex",
                    margin: "0px 15px",
                    paddingBottom: 10,
                    color: "#9a9a9a"
                  }}
                >
                  基本資料
                </h4>
                <Widget.FormFieldsBlock>
                  <Widget.FormField stacked>
                    <label>身體狀態</label>
                    <select
                      value={record.body_status}
                      onChange={e =>
                        this.setState({
                          record: { ...record, body_status: e.target.value }
                        })
                      }
                    >
                      <option value={"freedom-of-movement"}>行動自如</option>
                      <option value={"need-help"}>需協助</option>
                      <option value={"need-props"}>需借助道具</option>
                      <option value={"others"}>其他</option>
                    </select>
                  </Widget.FormField>
                  <Widget.FormField stacked>
                    <label>其他(身體狀態)</label>
                    <textarea
                      value={record.body_status_note}
                      onChange={e =>
                        this.setState({
                          record: {
                            ...record,
                            body_status_note: e.target.value
                          }
                        })
                      }
                    />
                  </Widget.FormField>
                </Widget.FormFieldsBlock>
                <Widget.FormFieldsBlock>
                  <Widget.FormField stacked>
                    <label>生產醫院: </label>
                    <input
                      value={record.hospital}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            hospital: event.target.value
                          }
                        })
                      }
                    />
                  </Widget.FormField>

                  <Widget.FormField stacked>
                    <label>醫院付費方式: </label>
                    <select
                      value={record.hospital_payment}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            hospital_payment: event.target.value
                          }
                        })
                      }
                    >
                      <option value={"own"}>own</option>
                      <option value={"insurance"}>insurance</option>
                    </select>
                  </Widget.FormField>
                </Widget.FormFieldsBlock>
                <Widget.FormFieldsBlock>
                  <Widget.FormField stacked>
                    <label>生產方式: </label>
                    <select
                      value={record.born_method}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            born_method: event.target.value
                          }
                        })
                      }
                    >
                      <option value={"natural"}>自然生產</option>
                      <option value={"c-section"}>剖腹生產</option>
                      <option value={"vacuum-suction"}>真空吸引</option>
                    </select>
                  </Widget.FormField>

                  <Widget.FormField stacked>
                    <label>胎次</label>
                    <input
                      value={record.parity}
                      onChange={e =>
                        this.setState({
                          record: { ...record, parity: e.target.value }
                        })
                      }
                    />
                  </Widget.FormField>

                  <Widget.FormField stacked>
                    <label>懷孕週數</label>
                    <input
                      value={record.pregnant_period}
                      onChange={e =>
                        this.setState({
                          record: { ...record, pregnant_period: e.target.value }
                        })
                      }
                    />
                  </Widget.FormField>
                </Widget.FormFieldsBlock>
                <Widget.FormFieldsBlock>
                  <Widget.FormField stacked>
                    <label>生產日期: </label>
                    <input
                      type="date"
                      value={record.born_date}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            born_date: event.target.value
                          }
                        })
                      }
                    />
                  </Widget.FormField>

                  <Widget.FormField stacked>
                    <label>寶寶出生體重: </label>
                    <input
                      type="number"
                      min="0"
                      value={record.born_weight}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            born_weight: event.target.value
                          }
                        })
                      }
                    />
                  </Widget.FormField>

                  <Widget.FormField stacked>
                    <label>男寶寶: </label>
                    <input
                      type="number"
                      min="0"
                      value={record.boys}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            boys: event.target.value
                          }
                        })
                      }
                    />
                  </Widget.FormField>

                  <Widget.FormField stacked>
                    <label>女寶寶: </label>
                    <input
                      type="number"
                      min="0"
                      value={record.girls}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            girls: event.target.value
                          }
                        })
                      }
                    />
                  </Widget.FormField>
                </Widget.FormFieldsBlock>
                <Widget.FormFieldsBlock>
                  <Widget.MultipleChoicesFormField>
                    <label>餵奶方式: </label>
                    <div className="choice-of-multiple">
                      <input
                        type="checkbox"
                        checked={record.feed_type_mother_milk}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              feed_type_mother_milk: e.target.checked
                            }
                          })
                        }
                      />
                      <label>純母奶</label>
                    </div>
                    <div className="choice-of-multiple">
                      <input
                        type="checkbox"
                        checked={record.feed_type_formula_milk}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              feed_type_formula_milk: e.target.checked
                            }
                          })
                        }
                      />
                      <label>配方奶</label>
                    </div>
                    <div className="choice-of-multiple">
                      <input
                        type="checkbox"
                        checked={record.feed_type_mix_breastfeeding}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              feed_type_mix_breastfeeding: e.target.checked
                            }
                          })
                        }
                      />
                      <label>混合哺乳</label>
                    </div>
                    <div className="choice-of-multiple">
                      <input
                        type="checkbox"
                        checked={record.feed_type_breastfeeding}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              feed_type_breastfeeding: e.target.checked
                            }
                          })
                        }
                      />
                      <label>親餵</label>
                    </div>
                  </Widget.MultipleChoicesFormField>
                  <Widget.FormField stacked>
                    <label>退奶方式: </label>
                    <select
                      value={record.retrieve_type}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            retrieve_type: event.target.value
                          }
                        })
                      }
                    >
                      <option value={"self-wraning-the-milk"}>自退奶</option>
                      <option value={"oral-antipyretics"}>口服退奶藥</option>
                      <option value={"milk-needle"}>打避奶針</option>
                    </select>
                  </Widget.FormField>

                  <Widget.FormField stacked>
                    <label>擠奶方式: </label>
                    <select
                      value={record.milking_type}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            milking_type: event.target.value
                          }
                        })
                      }
                    >
                      <option value={"hand-milking"}>手擠乳</option>
                      <option value={"hand-miking-tool"}>手動擠奶器</option>
                      <option value={"auto-milking-tool"}>電動擠奶器</option>
                    </select>
                  </Widget.FormField>
                </Widget.FormFieldsBlock>
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  borderBottom: "2px solid #eaeaea",
                  marginBottom: 10
                }}
              >
                <h4
                  style={{
                    width: "100%",
                    display: "flex",
                    margin: "0px 15px",
                    paddingBottom: 10,
                    color: "#9a9a9a"
                  }}
                >
                  身體評估
                </h4>
                <Widget.FormFieldsBlock>
                  <Widget.FormField stacked>
                    <label>T(ºC) : </label>
                    <input
                      value={record.temperature}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            temperature: event.target.value
                          }
                        })
                      }
                    />
                  </Widget.FormField>
                  <Widget.FormField stacked>
                    <label>P(次/分) : </label>
                    <input
                      value={record.pulse}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            pulse: event.target.value
                          }
                        })
                      }
                    />
                  </Widget.FormField>
                  <Widget.FormField stacked>
                    <label>R(次/分) : </label>
                    <input
                      value={record.respiration}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            respiration: event.target.value
                          }
                        })
                      }
                    />
                  </Widget.FormField>
                  <Widget.FormField stacked>
                    <label>BP(mmHg) : </label>
                    <input
                      value={record.blood_pressure}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            blood_pressure: event.target.value
                          }
                        })
                      }
                    />
                  </Widget.FormField>
                </Widget.FormFieldsBlock>
                <Widget.FormFieldsBlock>
                  <Widget.FormField stacked>
                    <label>乳房: </label>
                    <select
                      value={record.breast}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            breast: event.target.value
                          }
                        })
                      }
                    >
                      <option value={"soft"}>軟</option>
                      <option value={"abundance"}>充盈</option>
                      <option value={"block-after-feeding"}>
                        親哺後有硬塊
                      </option>
                      <option value={"soft-after-feeding"}>親哺後可變軟</option>
                      <option value={"others"}>其他</option>
                    </select>
                  </Widget.FormField>
                  <Widget.FormField stacked>
                    <label>其他(乳房)</label>
                    <textarea
                      value={record.breast_others_note}
                      onChange={e =>
                        this.setState({
                          record: {
                            ...record,
                            breast_others_note: e.target.value
                          }
                        })
                      }
                    />
                  </Widget.FormField>
                  <Widget.FormField stacked>
                    <label>乳頭: </label>
                    <select
                      value={record.nipple}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            nipple: event.target.value
                          }
                        })
                      }
                    >
                      <option value={"Rbroken-skin-R"}>R破皮</option>
                      <option value={"Lbroken-skin-L"}>L破皮</option>
                      <option value={"Rcrust-R"}>R結痂</option>
                      <option value={"Lcrust-L"}>L結痂</option>
                      <option value={"Rhealed-R"}>R痊癒</option>
                      <option value={"Lhealed-L"}>L痊癒</option>
                      <option value={"others"}>其他</option>
                    </select>
                  </Widget.FormField>
                  <Widget.FormField stacked>
                    <label>其他(乳頭)</label>
                    <textarea
                      value={record.nipple_others_note}
                      onChange={e =>
                        this.setState({
                          record: {
                            ...record,
                            nipple_others_note: e.target.value
                          }
                        })
                      }
                    />
                  </Widget.FormField>
                </Widget.FormFieldsBlock>
                <Widget.FormFieldsBlock>
                  <Widget.FormField stacked>
                    <label>宮底:(Fb) </label>
                    <input
                      value={record.uterus_bottom_u}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            uterus_bottom_u: event.target.value
                          }
                        })
                      }
                    />
                  </Widget.FormField>
                  <Widget.FormField stacked>
                    <label>宮縮: </label>
                    <select
                      value={record.uterus_bottom}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            uterus_bottom: event.target.value
                          }
                        })
                      }
                    >
                      <option value={"hard"}>硬</option>
                      <option value={"soft"}>鬆弛</option>
                      <option value={"hard-after-massage"}>按摩後可變硬</option>
                    </select>
                  </Widget.FormField>

                  <Widget.FormField stacked>
                    <label>惡露: </label>
                    <select
                      value={record.uterus_face}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            uterus_face: event.target.value
                          }
                        })
                      }
                    >
                      <option value={"red"}>鮮紅</option>
                      <option value={"dark-red"}>暗紅</option>
                      <option value={"light-red"}>淡紅</option>
                      <option value={"brown"}>黃褐</option>
                      <option value={"white"}>白</option>
                    </select>
                  </Widget.FormField>

                  <Widget.FormField stacked>
                    <label>惡露量: </label>
                    <select
                      value={record.uterus_qt}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            uterus_qt: event.target.value
                          }
                        })
                      }
                    >
                      <option value={"less"}>少</option>
                      <option value={"normal"}>中</option>
                      <option value={"much"}>多</option>
                    </select>
                  </Widget.FormField>
                </Widget.FormFieldsBlock>
                <Widget.FormFieldsBlock>
                  <Widget.FormField stacked>
                    <label>傷口情形(C/S): </label>
                    <select
                      value={record.wound_cs}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            wound_cs: event.target.value
                          }
                        })
                      }
                    >
                      <option value={"flat"}>平整</option>
                      <option value={"redness"}>紅腫</option>
                      <option value={"extudate"}>滲液</option>
                      <option value={"odor"}>異味</option>
                    </select>
                  </Widget.FormField>
                  <Widget.FormField>
                    <label>傷口情形(覆蓋): </label>
                    <input
                      type="checkbox"
                      checked={record.wound_coverage}
                      onChange={e =>
                        this.setState({
                          record: {
                            ...record,
                            wound_coverage: e.target.checked
                          }
                        })
                      }
                    />
                  </Widget.FormField>
                  <Widget.FormField stacked>
                    <label>有(覆蓋)</label>
                    <textarea
                      value={record.wound_coverage_note}
                      onChange={e =>
                        this.setState({
                          record: {
                            ...record,
                            wound_coverage_note: e.target.value
                          }
                        })
                      }
                    />
                  </Widget.FormField>
                </Widget.FormFieldsBlock>
                <Widget.FormFieldsBlock>
                  <Widget.FormField stacked>
                    <label>傷口情形(NSD): </label>
                    <select
                      value={record.wound_nsd}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            wound_nsd: event.target.value
                          }
                        })
                      }
                    >
                      <option value={"EP"}>EP</option>
                      <option value={"L/W"}>L/W</option>
                      <option value={"flat"}>平整</option>
                      <option value={"pain"}>疼痛</option>
                      <option value={"redness"}>紅腫</option>
                      <option value={"extudate"}>滲液</option>
                      <option value={"ointment"}>藥膏</option>
                    </select>
                  </Widget.FormField>
                  <Widget.FormField stacked>
                    <label>藥膏(NSD)</label>
                    <textarea
                      value={record.wound_nsd_note}
                      onChange={e =>
                        this.setState({
                          record: { ...record, wound_nsd_note: e.target.value }
                        })
                      }
                    />
                  </Widget.FormField>
                </Widget.FormFieldsBlock>
                <Widget.FormFieldsBlock>
                  <Widget.FormField stacked>
                    <label>排尿: </label>
                    <select
                      value={record.urination}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            urination: event.target.value
                          }
                        })
                      }
                    >
                      <option value={"normal"}>正常</option>
                      <option value={"less-urination"}>少尿</option>
                      <option value={"frequent-urination"}>頻尿</option>
                      <option value={"pain-urination"}>排尿痛或灼熱感</option>
                      <option value={"urinary-tube"}>尿管</option>
                    </select>
                  </Widget.FormField>
                  <Widget.FormField stacked>
                    <label>排便: </label>
                    <select
                      value={record.defecation}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            defecation: event.target.value
                          }
                        })
                      }
                    >
                      <option value={"normal"}>正常</option>
                      <option value={"constipation"}>便秘</option>
                      <option value={"diarrhea"}>腹瀉</option>
                      <option value={"oral-softener"}>口服軟便劑</option>
                      <option value={"others"}>其他</option>
                    </select>
                  </Widget.FormField>
                </Widget.FormFieldsBlock>
                <Widget.FormFieldsBlock>
                  <Widget.FormField>
                    <label>痔瘡有無: </label>
                    <input
                      type="checkbox"
                      checked={record.homorhoids}
                      onChange={e =>
                        this.setState({
                          record: { ...record, homorhoids: e.target.checked }
                        })
                      }
                    />
                  </Widget.FormField>
                  <Widget.FormField stacked>
                    <label>治療方式: </label>
                    <select
                      value={record.homorhoids_status}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            homorhoids_status: event.target.value
                          }
                        })
                      }
                    >
                      <option value={"sit-bath"}>坐浴</option>
                      <option value={"ointment"}>藥膏</option>
                    </select>
                  </Widget.FormField>
                  <Widget.FormField stacked>
                    <label>治療方式(次/天)</label>
                    <textarea
                      value={record.homorhoids_status_note}
                      onChange={e =>
                        this.setState({
                          record: {
                            ...record,
                            homorhoids_status_note: e.target.value
                          }
                        })
                      }
                    />
                  </Widget.FormField>
                </Widget.FormFieldsBlock>
                <Widget.FormFieldsBlock>
                  <Widget.FormField stacked>
                    <label>呼吸: </label>
                    <select
                      value={record.breath}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            breath: event.target.value
                          }
                        })
                      }
                    >
                      <option value={"normal"}>正常</option>
                      <option value={"asthma"}>氣喘</option>
                      <option value={"running-nose"}>流鼻水</option>
                      <option value={"cough"}>咳嗽</option>
                      <option value={"nasal-congestion"}>鼻塞</option>
                      <option
                        value={
                          "afmily-member-suspecious-having-cold-or-infected"
                        }
                      >
                        家人中一周內曾疑似感冒或感染症狀
                      </option>
                    </select>
                  </Widget.FormField>
                  <Widget.FormField stacked>
                    <label>睡眠: </label>
                    <select
                      value={record.sleep}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            sleep: event.target.value
                          }
                        })
                      }
                    >
                      <option value={"normal"}>正常</option>
                      <option value={"late-sleep"}>日夜顛倒</option>
                      <option value={"light-sleeper"}>易驚醒</option>
                      <option value={"sleepy"}>常想入睡</option>
                      <option value={"hard-to-sleep"}>難入睡</option>
                    </select>
                  </Widget.FormField>
                  <Widget.FormField stacked>
                    <label>自我照顧能力: </label>
                    <select
                      value={record.self_care}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            self_care: event.target.value
                          }
                        })
                      }
                    >
                      <option value={"self-handled"}>自理</option>
                      <option value={"partially-depen"}>部分依賴</option>
                      <option value={"fully-depend"}>完全依賴</option>
                    </select>
                  </Widget.FormField>
                </Widget.FormFieldsBlock>
                <Widget.FormFieldsBlock>
                  <Widget.FormField stacked>
                    <label>精神狀態: </label>
                    <select
                      value={record.mental_state}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            mental_state: event.target.value
                          }
                        })
                      }
                    >
                      <option value={"calm"}>平靜</option>
                      <option value={"anxious"}>焦慮</option>
                      <option value={"outgoing"}>活潑</option>
                      <option value={"excitment"}>激動</option>
                      <option value={"indifferent"}>冷漠</option>
                    </select>
                  </Widget.FormField>
                  <Widget.FormField stacked>
                    <label>愛丁堡產後憂鬱量表分數:(edps) </label>
                    <input
                      value={record.mental_state_epds_first_score}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            mental_state_epds_first_score: event.target.value
                          }
                        })
                      }
                    />
                  </Widget.FormField>
                  <Widget.FormField stacked>
                    <label>異常者第二次評量日期: </label>
                    <input
                      type="date"
                      value={record.mental_state_epds_second_date}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            mental_state_epds_second_date: event.target.value
                          }
                        })
                      }
                    />
                  </Widget.FormField>
                  <Widget.FormField stacked>
                    <label>異常者第二次評量分數:(edps) </label>
                    <input
                      value={record.mental_state_epds_second_score}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            mental_state_epds_second_score: event.target.value
                          }
                        })
                      }
                    />
                  </Widget.FormField>
                </Widget.FormFieldsBlock>
              </div>

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  borderBottom: "2px solid #eaeaea",
                  marginBottom: 10
                }}
              >
                <h4
                  style={{
                    width: "100%",
                    display: "flex",
                    margin: "0px 15px",
                    paddingBottom: 10,
                    color: "#9a9a9a"
                  }}
                >
                  病史
                </h4>
                <Widget.FormFieldsBlock>
                  <Widget.FormField stacked>
                    <label>食物過敏: </label>
                    <input
                      type="checkbox"
                      checked={record.allergy_food_check}
                      onChange={e =>
                        this.setState({
                          record: {
                            ...record,
                            allergy_food_check: e.target.checked
                          }
                        })
                      }
                    />
                  </Widget.FormField>
                  <Widget.FormField stacked>
                    <label>過敏的食物: </label>
                    <textarea
                      value={record.allergy_food}
                      onChange={e =>
                        this.setState({
                          record: { ...record, allergy_food: e.target.value }
                        })
                      }
                    />
                  </Widget.FormField>
                  <Widget.FormField stacked>
                    <label>藥物過敏: </label>
                    <input
                      type="checkbox"
                      checked={record.allergy_drug_check}
                      onChange={e =>
                        this.setState({
                          record: {
                            ...record,
                            allergy_drug_check: e.target.checked
                          }
                        })
                      }
                    />
                  </Widget.FormField>
                  <Widget.FormField stacked>
                    <label>過敏的藥物: </label>
                    <textarea
                      value={record.allergy_drug}
                      onChange={e =>
                        this.setState({
                          record: { ...record, allergy_drug: e.target.value }
                        })
                      }
                    />
                  </Widget.FormField>
                </Widget.FormFieldsBlock>

                <Widget.FormFieldsBlock>
                  <Widget.MultipleChoicesFormField>
                    <label>妊娠產後病史(可複選):</label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap"
                      }}
                    >
                      <div className="choice-of-multiple">
                        <input
                          type="checkbox"
                          checked={record.post_pregnancy_history_none}
                          onChange={e =>
                            this.setState({
                              record: {
                                ...record,
                                feed_type_mother_milk: e.target.checked
                              }
                            })
                          }
                        />
                        <label>無</label>
                      </div>
                      <div className="choice-of-multiple">
                        <input
                          type="checkbox"
                          checked={
                            record.post_pregnancy_history_gestational_hypertension
                          }
                          onChange={e =>
                            this.setState({
                              record: {
                                ...record,
                                post_pregnancy_history_gestational_hypertension:
                                  e.target.checked
                              }
                            })
                          }
                        />
                        <label>妊娠高血壓</label>
                      </div>
                      <div className="choice-of-multiple">
                        <input
                          type="checkbox"
                          checked={record.post_pregnancy_history_gdm}
                          onChange={e =>
                            this.setState({
                              record: {
                                ...record,
                                post_pregnancy_history_gdm: e.target.checked
                              }
                            })
                          }
                        />
                        <label>妊娠糖尿病</label>
                      </div>
                      <div className="choice-of-multiple">
                        <input
                          type="checkbox"
                          checked={record.post_pregnancy_history_abruption}
                          onChange={e =>
                            this.setState({
                              record: {
                                ...record,
                                post_pregnancy_history_abruption:
                                  e.target.checked
                              }
                            })
                          }
                        />
                        <label>早期剝離</label>
                      </div>
                      <div className="choice-of-multiple">
                        <input
                          type="checkbox"
                          checked={record.post_pregnancy_history_prom}
                          onChange={e =>
                            this.setState({
                              record: {
                                ...record,
                                post_pregnancy_history_prom: e.target.checked
                              }
                            })
                          }
                        />
                        <label>早期破水</label>
                      </div>
                      <div className="choice-of-multiple">
                        <input
                          type="checkbox"
                          checked={record.post_pregnancy_history_fetal}
                          onChange={e =>
                            this.setState({
                              record: {
                                ...record,
                                post_pregnancy_history_fetal: e.target.checked
                              }
                            })
                          }
                        />
                        <label>安胎</label>
                      </div>
                      <div className="choice-of-multiple">
                        <input
                          type="checkbox"
                          checked={
                            record.post_pregnancy_history_placenta_praevia
                          }
                          onChange={e =>
                            this.setState({
                              record: {
                                ...record,
                                post_pregnancy_history_placenta_praevia:
                                  e.target.checked
                              }
                            })
                          }
                        />
                        <label>前置胎盤</label>
                      </div>
                      <div className="choice-of-multiple">
                        <input
                          type="checkbox"
                          checked={record.post_pregnancy_history_edema}
                          onChange={e =>
                            this.setState({
                              record: {
                                ...record,
                                post_pregnancy_history_edema: e.target.checked
                              }
                            })
                          }
                        />
                        <label>水腫</label>
                      </div>
                      <div className="choice-of-multiple">
                        <input
                          type="checkbox"
                          checked={record.post_pregnancy_history_pph}
                          onChange={e =>
                            this.setState({
                              record: {
                                ...record,
                                post_pregnancy_history_pph: e.target.checked
                              }
                            })
                          }
                        />
                        <label>產後大出血</label>
                      </div>
                      <div className="choice-of-multiple">
                        <input
                          type="checkbox"
                          checked={
                            record.post_pregnancy_history_vaginal_laceration
                          }
                          onChange={e =>
                            this.setState({
                              record: {
                                ...record,
                                post_pregnancy_history_vaginal_laceration:
                                  e.target.checked
                              }
                            })
                          }
                        />
                        <label>陰道裂傷</label>
                      </div>
                      <div className="choice-of-multiple">
                        <input
                          type="checkbox"
                          checked={record.post_pregnancy_history_others}
                          onChange={e =>
                            this.setState({
                              record: {
                                ...record,
                                post_pregnancy_history_others: e.target.checked
                              }
                            })
                          }
                        />
                        <label>其他</label>
                      </div>
                    </div>
                  </Widget.MultipleChoicesFormField>

                  <Widget.FormField stacked>
                    <label>其他(妊娠產後病史): </label>
                    <textarea
                      value={record.post_pregnancy_history_note}
                      onChange={e =>
                        this.setState({
                          record: {
                            ...record,
                            post_pregnancy_history_note: e.target.value
                          }
                        })
                      }
                    />
                  </Widget.FormField>
                </Widget.FormFieldsBlock>

                <Widget.FormFieldsBlock>
                  <Widget.MultipleChoicesFormField>
                    <label>內科病史:</label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap"
                      }}
                    >
                      <div className="choice-of-multiple">
                        <input
                          type="checkbox"
                          checked={record.in_medicine_history_none}
                          onChange={e =>
                            this.setState({
                              record: {
                                ...record,
                                in_medicine_history_none: e.target.checked
                              }
                            })
                          }
                        />
                        <label>無</label>
                      </div>
                      <div className="choice-of-multiple">
                        <input
                          type="checkbox"
                          checked={record.in_medicine_history_hypertension}
                          onChange={e =>
                            this.setState({
                              record: {
                                ...record,
                                in_medicine_history_hypertension:
                                  e.target.checked
                              }
                            })
                          }
                        />
                        <label>高血壓</label>
                      </div>
                      <div className="choice-of-multiple">
                        <input
                          type="checkbox"
                          checked={record.in_medicine_history_heart_disease}
                          onChange={e =>
                            this.setState({
                              record: {
                                ...record,
                                in_medicine_history_heart_disease:
                                  e.target.checked
                              }
                            })
                          }
                        />
                        <label>心臟病</label>
                      </div>
                      <div className="choice-of-multiple">
                        <input
                          type="checkbox"
                          checked={record.in_medicine_history_diabetes}
                          onChange={e =>
                            this.setState({
                              record: {
                                ...record,
                                in_medicine_history_diabetes: e.target.checked
                              }
                            })
                          }
                        />
                        <label>糖尿病</label>
                      </div>
                      <div className="choice-of-multiple">
                        <input
                          type="checkbox"
                          checked={record.in_medicine_history_asthma}
                          onChange={e =>
                            this.setState({
                              record: {
                                ...record,
                                in_medicine_history_asthma: e.target.checked
                              }
                            })
                          }
                        />
                        <label>氣喘</label>
                      </div>
                      <div className="choice-of-multiple">
                        <input
                          type="checkbox"
                          checked={record.in_medicine_history_cancer}
                          onChange={e =>
                            this.setState({
                              record: {
                                ...record,
                                in_medicine_history_cancer: e.target.checked
                              }
                            })
                          }
                        />
                        <label>癌症</label>
                      </div>
                      <div className="choice-of-multiple">
                        <input
                          type="checkbox"
                          checked={
                            record.in_medicine_history_long_term_medication
                          }
                          onChange={e =>
                            this.setState({
                              record: {
                                ...record,
                                in_medicine_history_long_term_medication:
                                  e.target.checked
                              }
                            })
                          }
                        />
                        <label>長期服藥</label>
                      </div>
                      <div className="choice-of-multiple">
                        <input
                          type="checkbox"
                          checked={record.in_medicine_history_others}
                          onChange={e =>
                            this.setState({
                              record: {
                                ...record,
                                in_medicine_history_others: e.target.checked
                              }
                            })
                          }
                        />
                        <label>其他</label>
                      </div>
                    </div>
                  </Widget.MultipleChoicesFormField>

                  <Widget.FormField stacked>
                    <label>其他(內科病史): </label>
                    <textarea
                      value={record.in_medicine_history_note}
                      onChange={e =>
                        this.setState({
                          record: {
                            ...record,
                            in_medicine_history_note: e.target.value
                          }
                        })
                      }
                    />
                  </Widget.FormField>
                </Widget.FormFieldsBlock>

                <Widget.FormFieldsBlock>
                  <Widget.FormField stacked>
                    <label>外科病史: </label>
                    <select
                      value={record.ext_medicine_history}
                      onChange={event =>
                        this.setState({
                          record: {
                            ...record,
                            ext_medicine_history: event.target.value
                          }
                        })
                      }
                    >
                      <option value={"none"}>無</option>
                      <option value={"surgery-history"}>
                        有關外傷手術外科疾病史
                      </option>
                    </select>
                  </Widget.FormField>

                  <Widget.FormField stacked>
                    <label>其他(外科病史): </label>
                    <textarea
                      value={record.ext_medicine_history_note}
                      onChange={e =>
                        this.setState({
                          record: {
                            ...record,
                            ext_medicine_history_note: e.target.value
                          }
                        })
                      }
                    />
                  </Widget.FormField>
                </Widget.FormFieldsBlock>
              </div>
            </div>

            <Widget.FormField stacked>
              <label>備註: </label>
              <input
                type="text"
                value={record.note}
                onChange={event =>
                  this.setState({
                    record: {
                      ...record,
                      note: event.target.value
                    }
                  })
                }
              />
            </Widget.FormField>
          </Widget.FieldContainer>
          {errorMessage && (
            <div style={{ color: "red", padding: "10px" }}>{errorMessage}</div>
          )}
          <div
            style={{ margin: 10, display: "flex", justifyContent: "flex-end" }}
          >
            {isCreateView ? (
              <Widget.Button onClick={this._submitCreate}>
                確認新增
              </Widget.Button>
            ) : (
              <Widget.Button onClick={this._submitEdit}>確認修改</Widget.Button>
            )}
            <div onClick={onClose}>
              <Widget.Button
                color={"#ffffff"}
                textColor={"#777777"}
                css={"border: 1px solid #DFDFDF"}
              >
                取消
              </Widget.Button>
            </div>
          </div>
        </Widget.DialogWrapper>
      </Dialog>
    );
  }

  _submitCreate = () => {
    let { requestUpdate, onClose } = this.props;
    let { record } = this.state;

    return this.createApi(record)
      .then(onClose)
      .then(requestUpdate)
      .catch(() => this.setState({ errorMessage: "新增失敗！請再試一次！" }));
  };

  _submitEdit = () => {
    let { requestUpdate, onClose } = this.props;
    let { record } = this.state;

    return this.updateApi(record)
      .then(onClose)
      .then(requestUpdate)
      .catch(() => this.setState({ errorMessage: "修改失敗！請再試一次！" }));
  };
}

export default connect(
  null,
  ActionCreator
)(MomPrecheckinEvalDetailDialog);
