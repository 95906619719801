import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import Selectors from "../../../src/Selectors";
import ActionCreator from "../../../src/ActionCreator";
import MomListingDialog from "./MomListingDialog";
import MomInfoForm from "./MomInfoForm";
import MomVisitList from "./MomVisitList";
import ContractList from "./ContractList";
import MomLivingRecordList from "./MomLivingRecordList";
import RoomSchedulingPanel from "./RoomSchedulingPanel";
import MomPrecheckinEvalList from "./MomPrecheckinEvalList";
import MomCheckinEvalList from "./MomCheckinEvalList";
import MomCaringRecordList from "./MomCaringRecordList";
import MomHealthRecordList from "./MomHealthRecordList";
import MomBorrowRecordList from "./MomBorrowRecordList";
import MomCheckinoutRecord from "./MomCheckinoutRecord";
import MomBillingRecordList from "./MomBillingRecordList";
import MomMealMenuList from "./MomMealMenuList";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
`;

const SearchArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  background-color: #e8e8e8;
`;

const StatusBar = styled.div`
  margin: 0px 20px 15px 0px;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(154, 154, 154, 0.5);
  width: ${props => (props.large ? "640px" : "568px")};
  height: 70px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;

  & > .customerInfo {
    width: 160px;
    font-size: 14px;
    color: #777777;

    @media screen and (max-width: 770px) {
      width: 100px;
    }
  }

  & > .title {
    margin-right: 30px;
    border-right: 1px solid #eaeaea;
    padding: 10px;
    max-width: 58px;
    text-align: center;
    font-size: 14px;
    color: #777777;
  }

  & > .searchInput {
    display: flex;
    align-items: center;
    border: 1px solid #e9eff4;
    border-radius: 4px;
    margin-right: 20px;
    padding-left: 10px;
    width: 220px;
    height: 40px;
  }

  & > div > input {
    border: 0px;
    padding-left: 10px;
    font-size: 14px;
    color: #777777;

    ::placeholder {
      color: lightgray;
    }

    :focus {
      outline: none;
    }
  }

  @media screen and (max-width: 770px) {
    width: 550px;
  }
`;

const Message = styled.div`
  padding: 20px;
  font-size: 14px;
  color: #777777;
`;

const UiTabs = {
  mother: "mother",
  contract: "contract",
  room: "room",
  precheckinEval: "precheckinEval",
  checkinEval: "checkinEval",
  caringRecord: "caringRecord",
  healthRecord: "healthRecord",
  borrowRecord: "borrowRecord",
  checkinoutRecord: "checkinoutRecord",
  billingRecord: "billingRecord",
  mealMenu: "mealMenu"
};

const UiState = {
  normal: "normal",
  searching: "searching", // show MomListingDialog
  creating: "creating", // show MomListingDialog, with create flag
  selected: "selected" // show mom detail & back to search
};

class MomDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        name: "",
        phone: ""
      },
      showRoomSchedulingPanel: false
    };
  }

  render() {
    let { values, showRoomSchedulingPanel } = this.state;

    let {
      dashboardState: { selectedTab, uiState, selectedMother },
      appActions
    } = this.props;

    return (
      <Wrapper>
        <SearchArea>
          {uiState === UiState.selected ? (
            <StatusBar large>
              <div className="customerInfo" style={{ marginLeft: 20 }}>
                {selectedMother.name}
                女士
              </div>
              <div className="customerInfo">客戶編號： {selectedMother.id}</div>
              <div className="customerInfo">
                客戶電話： {selectedMother.phone}
              </div>
              <Widget.Button
                className="white-button"
                css="margin: 0px 10px 0px; width: 100px;"
                onClick={() =>
                  appActions.momCenter.setMomDashboardState({
                    selectedMother: null,
                    uiState: UiState.normal
                  })
                }
              >
                返回搜尋
              </Widget.Button>
            </StatusBar>
          ) : (
            <StatusBar>
              <div className="title">搜尋客戶</div>

              <div className="searchInput">
                <Icon.Search color="lightgray" />
                <input
                  placeholder={"姓名或電話"}
                  value={values.name || values.phone}
                  onChange={e => {
                    if (isNaN(e.target.value)) {
                      return this.setState({
                        values: { ...values, name: e.target.value }
                      });
                    }
                    return this.setState({
                      values: { name: "", phone: e.target.value }
                    });
                  }}
                />
              </div>

              <Widget.Button
                css="margin: 0px 10px 0px 0px; width: 100px;"
                onClick={() =>
                  appActions.momCenter.setMomDashboardState({
                    uiState: UiState.searching
                  })
                }
              >
                搜尋客戶
              </Widget.Button>

              <Widget.Button
                className="white-button"
                css="margin: 0px 10px 0px 0px; width: 100px;"
                onClick={() =>
                  appActions.momCenter.setMomDashboardState({
                    uiState: UiState.creating
                  })
                }
              >
                新增客戶
              </Widget.Button>
            </StatusBar>
          )}

          <Widget.Button
            className="white-button"
            css="margin: 0px 0px 15px; width: 100px;"
            onClick={() => this.setState({ showRoomSchedulingPanel: true })}
          >
            排房詳情
          </Widget.Button>
        </SearchArea>

        <Widget.TabContainer>
          <div style={{ backgroundColor: "#E8E8E8" }}>
            <Widget.TabItem
              selected={selectedTab === UiTabs.mother}
              onClick={() =>
                appActions.momCenter.setMomDashboardState({
                  selectedTab: UiTabs.mother
                })
              }
            >
              基本資料
            </Widget.TabItem>

            <Widget.TabItem
              selected={selectedTab === UiTabs.contract}
              onClick={() =>
                appActions.momCenter.setMomDashboardState({
                  selectedTab: UiTabs.contract
                })
              }
            >
              合約
            </Widget.TabItem>

            <Widget.TabItem
              selected={selectedTab === UiTabs.room}
              onClick={() =>
                appActions.momCenter.setMomDashboardState({
                  selectedTab: UiTabs.room
                })
              }
            >
              排房
            </Widget.TabItem>

            <Widget.TabItem
              selected={selectedTab === UiTabs.precheckinEval}
              onClick={() =>
                appActions.momCenter.setMomDashboardState({
                  selectedTab: UiTabs.precheckinEval
                })
              }
            >
              入住前評估表
            </Widget.TabItem>

            <Widget.TabItem
              selected={selectedTab === UiTabs.checkinEval}
              onClick={() =>
                appActions.momCenter.setMomDashboardState({
                  selectedTab: UiTabs.checkinEval
                })
              }
            >
              入住後關懷表
            </Widget.TabItem>

            <Widget.TabItem
              selected={selectedTab === UiTabs.healthRecord}
              onClick={() =>
                appActions.momCenter.setMomDashboardState({
                  selectedTab: UiTabs.healthRecord
                })
              }
            >
              健康紀錄
            </Widget.TabItem>

            <Widget.TabItem
              selected={selectedTab === UiTabs.caringRecord}
              onClick={() =>
                appActions.momCenter.setMomDashboardState({
                  selectedTab: UiTabs.caringRecord
                })
              }
            >
              關懷紀錄
            </Widget.TabItem>
            <Widget.TabItem
              selected={selectedTab === UiTabs.borrowRecord}
              onClick={() =>
                appActions.momCenter.setMomDashboardState({
                  selectedTab: UiTabs.borrowRecord
                })
              }
            >
              借物紀錄
            </Widget.TabItem>

            <Widget.TabItem
              selected={selectedTab === UiTabs.checkinoutRecord}
              onClick={() =>
                appActions.momCenter.setMomDashboardState({
                  selectedTab: UiTabs.checkinoutRecord
                })
              }
            >
              入住紀錄
            </Widget.TabItem>
            <Widget.TabItem
              selected={selectedTab === UiTabs.billingRecord}
              onClick={() =>
                appActions.momCenter.setMomDashboardState({
                  selectedTab: UiTabs.billingRecord
                })
              }
            >
              帳單紀錄
            </Widget.TabItem>
            <Widget.TabItem
              selected={selectedTab === UiTabs.mealMenu}
              onClick={() =>
                appActions.momCenter.setMomDashboardState({
                  selectedTab: UiTabs.mealMenu
                })
              }
            >
              膳食關懷紀錄
            </Widget.TabItem>
          </div>

          <div className="content">
            {(() => {
              switch (selectedTab) {
                case UiTabs.mother:
                  return (
                    (selectedMother && (
                      <>
                        <MomInfoForm
                          key={selectedMother.updated}
                          mother={selectedMother}
                          css="margin-bottom: 30px;"
                        />
                        <MomVisitList
                          key={selectedMother.id}
                          mother={selectedMother}
                        />
                      </>
                    )) || <Message>尚無資料，請搜尋客戶。</Message>
                  );

                case UiTabs.contract:
                  return (
                    <>
                      {(selectedMother && (
                        <ContractList
                          key={selectedMother.id}
                          mother={selectedMother}
                        />
                      )) || <Message>尚無資料，請搜尋客戶。</Message>}
                    </>
                  );

                case UiTabs.room:
                  return (
                    <>
                      {(selectedMother && (
                        <MomLivingRecordList
                          key={selectedMother.id}
                          mother={selectedMother}
                        />
                      )) || <Message>尚無資料，請搜尋客戶。</Message>}
                    </>
                  );

                case UiTabs.precheckinEval:
                  return (
                    <>
                      {(selectedMother && (
                        <MomPrecheckinEvalList
                          key={selectedMother.id}
                          mother={selectedMother}
                        />
                      )) || <Message>尚無資料，請搜尋客戶。</Message>}
                    </>
                  );

                case UiTabs.checkinEval:
                  return (
                    <>
                      {(selectedMother && (
                        <MomCheckinEvalList
                          key={selectedMother.id}
                          mother={selectedMother}
                        />
                      )) || <Message>尚無資料，請搜尋客戶。</Message>}
                    </>
                  );

                case UiTabs.healthRecord:
                  return (
                    <>
                      {(selectedMother && (
                        <MomHealthRecordList
                          key={selectedMother.id}
                          mother={selectedMother}
                        />
                      )) || <Message>尚無資料，請搜尋客戶。</Message>}
                    </>
                  );

                case UiTabs.caringRecord:
                  return (
                    <>
                      {(selectedMother && (
                        <MomCaringRecordList
                          key={selectedMother.id}
                          mother={selectedMother}
                        />
                      )) || <Message>尚無資料，請搜尋客戶。</Message>}
                    </>
                  );

                case UiTabs.borrowRecord:
                  return (
                    <>
                      {(selectedMother && (
                        <MomBorrowRecordList
                          key={selectedMother.id}
                          mother={selectedMother}
                        />
                      )) || <Message>尚無資料，請搜尋客戶。</Message>}
                    </>
                  );

                case UiTabs.checkinoutRecord:
                  return (
                    <>
                      {(selectedMother && (
                        <MomCheckinoutRecord
                          key={selectedMother.id}
                          mother={selectedMother}
                        />
                      )) || <Message>尚無資料，請搜尋客戶。</Message>}
                    </>
                  );

                case UiTabs.billingRecord:
                  return (
                    <>
                      {(selectedMother && (
                        <MomBillingRecordList
                          key={selectedMother.id}
                          mother={selectedMother}
                        />
                      )) || <Message>尚無資料，請搜尋客戶。</Message>}
                    </>
                  );

                case UiTabs.mealMenu:
                  return (
                    <>
                      {(selectedMother && (
                        <MomMealMenuList
                          key={selectedMother.id}
                          mother={selectedMother}
                        />
                      )) || <Message>尚無資料，請搜尋客戶。</Message>}
                    </>
                  );

                default:
                  return null;
              }
            })()}
          </div>
        </Widget.TabContainer>

        {(uiState === UiState.searching || uiState === UiState.creating) && (
          <MomListingDialog
            create={uiState === UiState.creating}
            name={values.name}
            phone={values.phone}
            requestClose={() =>
              appActions.momCenter.setMomDashboardState({
                uiState: UiState.normal
              })
            }
            selectMother={mother => {
              appActions.momCenter.setMomDashboardState({
                uiState: UiState.selected,
                selectedTab: UiTabs.mother,
                selectedMother: mother
              });
            }}
          />
        )}

        {showRoomSchedulingPanel && (
          <RoomSchedulingPanel
            requestClose={() =>
              this.setState({ showRoomSchedulingPanel: false })
            }
            selectMother={mother => {
              appActions.momCenter.setMomDashboardState({
                uiState: UiState.selected,
                selectedTab: UiTabs.mother,
                selectedMother: mother
              });
            }}
          />
        )}
      </Wrapper>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
    dashboardState: Selectors.momCenter.getMomDashboardState(state)
  }),
  ActionCreator
)(MomDashboard);
