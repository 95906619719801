import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import ActionCreator from "../../../src/ActionCreator";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import ConfirmDialog from "./ConfirmDialog";
import MomCheckinoutRecordDetailDialog from "./MomCheckinoutRecordDetailDialog";

class MomCheckinoutRecord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: false,
      records: null,
      showConfirmDialog: false,
      selectedRecord: null,
      showDetailDialog: false
    };
  }

  componentDidMount() {
    this._fetchRecords();
  }

  render() {
    let { records, showSpinner } = this.state;

    return (
      <Wrapper>
        <Widget.Row>
          <div
            style={{
              display: "flex",
              flex: 1,
              color: "#777777",
              fontWeight: "500",
              padding: 10
            }}
          >
            搜尋結果
          </div>
          <div style={{ margin: 10, display: "flex" }}>
            <Widget.Clickable
              onClick={() =>
                this.setState({ showDetailDialog: true, selectedRecord: null })
              }
            >
              <Widget.Button>新增入住紀錄</Widget.Button>
            </Widget.Clickable>

            <Widget.Clickable onClick={this._fetchRecords}>
              <Widget.Button className="white-button">重新整理</Widget.Button>
            </Widget.Clickable>
          </div>
        </Widget.Row>

        {!showSpinner && records != null && records.length === 0 && (
          <Widget.HintMessage>尚未建立此客戶相關紀錄!</Widget.HintMessage>
        )}

        {showSpinner ? (
          <Widget.Spinner />
        ) : (
          <Widget.Table>
            <Widget.TableContent lessTable>
              <Widget.Fields lessTable>
                <div className="field">id</div>
                <div className="date-field">狀態</div>
                <div className="field">日期</div>
                <div className="field">時間</div>
                <div className="field-padding" />
                <div className="field" />
                <div className="field" />
              </Widget.Fields>
              {records &&
                records.map((record, i) => (
                  <div className={i % 2 === 0 ? "row-even" : "row-odd"}>
                    <div className="row-item">{record.id}</div>
                    <div className="date-item">{record.type}</div>
                    <div className="date-item">
                      {record.created.split("T")[0]}
                    </div>
                    <div className="date-item">
                      {`${record.created.split("T")[1].split(":")[0]}:${
                        record.created.split("T")[1].split(":")[1]
                      }`}
                    </div>
                    <div className="row-item-padding" />
                  </div>
                ))}
            </Widget.TableContent>
          </Widget.Table>
        )}
      </Wrapper>
    );
  }

  _fetchRecords = () => {
    let { appActions, mother } = this.props;
    this.setState({ showSpinner: true });

    appActions.momCenter
      .fetchMomCheckinoutRecord(mother.id)
      .then(records => this.setState({ records }))
      .then(() => this.setState({ showSpinner: false }))
      .catch(err => console.warn(err));
  };
}

const Wrapper = styled.div``;

export default connect(
  null,
  ActionCreator
)(MomCheckinoutRecord);
