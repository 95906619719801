import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import moment from "moment";
import * as Widget from "../../../src/Components/Widget2";
import ActionCreator from "../../../src/ActionCreator";

const Wrapper = styled.div`
  position: relative;
  ${props => props.css || ""};
`;

const Container = styled.div`
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 20px;
`;

const Title = styled.h3`
  margin-bottom: 10px;
  color: #777777;
  ${props => props.css || ""};
`;

const Subtitle = styled.div`
  margin: 10px;
  width: 100px;
  font-size: 16px;
  color: #777777;
`;

const UpdateTime = styled.div`
  font-size: 12px;
  color: #dbdbdb;
`;

const Error = styled.div`
  margin-bottom: 10px;
  text-align: right;
  font-size: 14px;
  color: red;
`;

class MomInfoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mother: { ...this.props.mother },
      prevMotherData: null,
      isEditing: false,
      showSpinner: false,
      errorMessage: ""
    };
  }

  render() {
    let { css } = this.props;
    let { mother, isEditing, showSpinner, errorMessage } = this.state;

    return (
      <Wrapper css={css}>
        <Container>
          <Title css="margin-bottom: 20px;">基本資料</Title>
          <Widget.Row
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Subtitle>個人資料</Subtitle>
            {!showSpinner && !isEditing && (
              <Widget.Button
                className="white-button"
                css="width: 100px;"
                onClick={() =>
                  this.setState({
                    isEditing: !isEditing,
                    prevMotherData: { ...mother }
                  })
                }
              >
                修改
              </Widget.Button>
            )}

            {isEditing && (
              <div>
                <div style={{ marginBottom: 10 }}>
                  <Widget.Button
                    className="white-button"
                    css="width: 100px;"
                    onClick={() => {
                      this.setState({
                        isEditing: false,
                        mother: this.state.prevMotherData
                      });
                    }}
                  >
                    取消
                  </Widget.Button>
                  <Widget.Button
                    css="width: 100px;"
                    onClick={this._updateMother}
                  >
                    儲存
                  </Widget.Button>
                </div>

                {errorMessage && <Error>{errorMessage}</Error>}
              </div>
            )}
          </Widget.Row>
          <Widget.Col>
            <Widget.Row wrap="true" css="margin-bottom: 25px;">
              <Widget.FormField stacked css="margin-right: 40px;">
                <label>姓名</label>
                <input
                  value={mother.name}
                  onChange={e =>
                    this.setState({
                      mother: { ...mother, name: e.target.value }
                    })
                  }
                  disabled={!isEditing}
                />
              </Widget.FormField>

              <Widget.FormField stacked css="margin-right: 40px;">
                <label>手機</label>
                <input
                  value={mother.phone}
                  onChange={e =>
                    this.setState({
                      mother: { ...mother, phone: e.target.value }
                    })
                  }
                  disabled={!isEditing}
                />
              </Widget.FormField>

              <Widget.FormField stacked css="margin-right: 40px;">
                <label>市話</label>
                <input
                  value={mother.home_phone || ""}
                  onChange={e =>
                    this.setState({
                      mother: { ...mother, home_phone: e.target.value }
                    })
                  }
                  disabled={!isEditing}
                />
              </Widget.FormField>

              <Widget.FormField stacked css="margin-right: 40px;">
                <label>身份證字號</label>
                <input
                  value={mother.id_card_number || ""}
                  onChange={e =>
                    this.setState({
                      mother: { ...mother, id_card_number: e.target.value }
                    })
                  }
                  disabled={!isEditing}
                />
              </Widget.FormField>

              <Widget.FormField
                stacked
                inputWidth="320px"
                css="margin-right: 40px;"
              >
                <label>LINE ID</label>
                <input
                  value={mother.line_id}
                  onChange={e =>
                    this.setState({
                      mother: { ...mother, line_id: e.target.value }
                    })
                  }
                  disabled={!isEditing}
                />
              </Widget.FormField>

              <Widget.FormField
                stacked
                inputWidth="320px"
                css="margin-right: 40px;"
              >
                <label>電郵</label>
                <input
                  value={mother.email || ""}
                  onChange={e =>
                    this.setState({
                      mother: { ...mother, email: e.target.value }
                    })
                  }
                  disabled={!isEditing}
                />
              </Widget.FormField>

              <Widget.FormField stacked inputWidth="320px">
                <label>通訊地址</label>
                <input
                  value={mother.address || ""}
                  onChange={e =>
                    this.setState({
                      mother: { ...mother, address: e.target.value }
                    })
                  }
                  disabled={!isEditing}
                />
              </Widget.FormField>

              <Widget.FormField stacked css="margin-right: 40px;">
                <label>媽媽身份</label>
                <select
                  value={mother.guest_level}
                  onChange={e =>
                    this.setState({
                      mother: { ...mother, guest_level: e.target.value }
                    })
                  }
                  disabled={!isEditing}
                >
                  <option value="general">一般客戶</option>
                  <option value="special">特殊客戶</option>
                </select>
              </Widget.FormField>

              <Widget.FormField stacked css="margin-right: 40px;">
                <label>生日</label>
                <input
                  value={mother.birthday}
                  type="date"
                  onChange={e =>
                    this.setState({
                      mother: { ...mother, birthday: e.target.value }
                    })
                  }
                  disabled={!isEditing}
                />
              </Widget.FormField>

              <Widget.FormField stacked>
                <label>預產期</label>
                <input
                  value={mother.expected_date || ""}
                  onChange={e =>
                    this.setState({
                      mother: { ...mother, expected_date: e.target.value }
                    })
                  }
                  disabled={!isEditing}
                />
              </Widget.FormField>

              <Widget.FormField stacked css="margin-right: 40px;">
                <label>預計生產方式</label>
                <select
                  value={mother.delivery_method}
                  onChange={e =>
                    this.setState({
                      mother: { ...mother, delivery: e.target.value }
                    })
                  }
                  disabled={!isEditing}
                >
                  <option value="c-section">剖腹產</option>
                  <option value="vaginal delivery">自然產</option>
                </select>
              </Widget.FormField>

              <Widget.FormField stacked css="margin-right: 40px;">
                <label>預計生產醫院</label>
                <input
                  value={mother.hospital}
                  onChange={e =>
                    this.setState({
                      mother: { ...mother, hospital: e.target.value }
                    })
                  }
                  disabled={!isEditing}
                />
              </Widget.FormField>

              <Widget.FormField stacked>
                <label>入住護理經驗</label>
                <select
                  value={mother.experience}
                  onChange={e =>
                    this.setState({
                      mother: { ...mother, experience: e.target.value }
                    })
                  }
                  disabled={!isEditing}
                >
                  <option value="yes">有</option>
                  <option value="no">否</option>
                </select>
              </Widget.FormField>

              <Widget.FormField stacked css="margin-right: 40px;">
                <label>爸爸年齡</label>
                <input
                  value={mother.father_age}
                  onChange={e =>
                    this.setState({
                      mother: { ...mother, father_age: e.target.value }
                    })
                  }
                  disabled={!isEditing}
                />
              </Widget.FormField>
              <Widget.FormField stacked css="margin-right: 40px;">
                <label>來源</label>
                <select
                  value={mother.source}
                  onChange={e =>
                    this.setState({
                      mother: { ...mother, source: e.target.value }
                    })
                  }
                  disabled={!isEditing}
                >
                  <option value="website">官網</option>
                  <option value="recommend">親友介紹</option>
                  <option value="advertisement">廣告</option>
                  <option value="passby">路過</option>
                </select>
              </Widget.FormField>

              <Widget.FormField stacked>
                <label>備註</label>
                <input
                  value={mother.note}
                  onChange={e =>
                    this.setState({
                      mother: { ...mother, note: e.target.value }
                    })
                  }
                  disabled={!isEditing}
                />
              </Widget.FormField>
            </Widget.Row>
          </Widget.Col>
        </Container>

        <Container>
          <Subtitle>聯絡人資料</Subtitle>
          <Widget.Col>
            <Widget.Row wrap="true" css="margin-bottom: 25px;">
              <Widget.FormField stacked css="margin-right: 40px;">
                <label>姓名</label>
                <input
                  value={mother.sub_name}
                  onChange={e =>
                    this.setState({
                      mother: { ...mother, sub_name: e.target.value }
                    })
                  }
                  disabled={!isEditing}
                />
              </Widget.FormField>

              <Widget.FormField stacked css="margin-right: 40px;">
                <label>手機</label>
                <input
                  value={mother.sub_mobile}
                  onChange={e =>
                    this.setState({
                      mother: { ...mother, sub_mobile: e.target.value }
                    })
                  }
                  disabled={!isEditing}
                />
              </Widget.FormField>

              <Widget.FormField stacked>
                <label>市話</label>
                <input
                  value={mother.sub_home}
                  onChange={e =>
                    this.setState({
                      mother: { ...mother, sub_home: e.target.value }
                    })
                  }
                  disabled={!isEditing}
                />
              </Widget.FormField>

              <Widget.FormField
                stacked
                inputWidth="320px"
                css="margin-right: 40px;"
              >
                <label>LINE ID</label>
                <input
                  value={mother.sub_line_id}
                  onChange={e =>
                    this.setState({
                      mother: { ...mother, sub_line_id: e.target.value }
                    })
                  }
                  disabled={!isEditing}
                />
              </Widget.FormField>

              <Widget.FormField
                stacked
                inputWidth="320px"
                css="margin-right: 40px;"
              >
                <label>聯絡人電郵</label>
                <input
                  value={mother.sub_email}
                  onChange={e =>
                    this.setState({
                      mother: { ...mother, sub_email: e.target.value }
                    })
                  }
                  disabled={!isEditing}
                />
              </Widget.FormField>

              <Widget.FormField
                stacked
                inputWidth="320px"
                css="margin-right: 40px;"
              >
                <label>聯絡人地址</label>
                <input
                  value={mother.sub_address}
                  onChange={e =>
                    this.setState({
                      mother: { ...mother, sub_address: e.target.value }
                    })
                  }
                  disabled={!isEditing}
                />
              </Widget.FormField>

              <Widget.FormField stacked css="margin-right: 40px;">
                <label>與媽媽關係</label>
                <select
                  value={mother.relationship}
                  onChange={e =>
                    this.setState({
                      mother: { ...mother, relationship: e.target.value }
                    })
                  }
                  disabled={!isEditing}
                >
                  <option value="family">親人</option>
                  <option value="friend">朋友</option>
                  <option value="other">其他</option>
                </select>
              </Widget.FormField>

              <Widget.FormField stacked>
                <label>性別</label>
                <select
                  value={mother.gender}
                  onChange={e =>
                    this.setState({
                      mother: { ...mother, gender: e.target.value }
                    })
                  }
                  disabled={!isEditing}
                >
                  <option value="male">男</option>
                  <option value="female">女</option>
                </select>
              </Widget.FormField>
            </Widget.Row>
          </Widget.Col>
        </Container>

        <Widget.Row justify="flex-end">
          <UpdateTime>
            上次更新時間：
            {moment(mother.updated).format("YYYY-MM-DD HH:mm")}
          </UpdateTime>

          {showSpinner && <Widget.Spinner />}
        </Widget.Row>
      </Wrapper>
    );
  }

  _updateMother = () => {
    let { appActions } = this.props;
    let { mother } = this.state;
    let reEng = /^[A-Z]$/;

    if (mother.id_card_number === null || mother.id_card_number === "") {
      return this.setState({ errorMessage: "身份證字號為必填！" });
    }

    if (!reEng.test(mother.id_card_number.charAt(0))) {
      return this.setState({ errorMessage: "身份證字號首字為英文大寫！" });
    }

    if (mother.id_card_number.length != 10) {
      return this.setState({ errorMessage: "身份證字號需為10碼！" });
    }

    this.setState({
      isEditing: false,
      showSpinner: true
    });

    appActions.momCenter
      .updateMother(mother)
      .then(updatedMother => {
        this.setState({
          mother: updatedMother,
          showSpinner: false
        });
      })
      .then(() => console.log("mom info success!"))
      .catch(err => {
        this.setState({
          isEditing: true, // let ui still in edit mode, so user can cancel editing
          showSpinner: false
        });
        console.warn(err);
      });
  };
}

export default connect(
  state => ({}),
  ActionCreator
)(MomInfoForm);
