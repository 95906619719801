import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../../../src/ActionCreator";
import Dialog from "../../../src/Components/Dialog";
import * as Widget from "../../../src/Components/Widget2";

class SparePartAddRecordDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: 1,
      operation: "add",
      reason: "none",
      errorMessage: "",
      showSpinner: false,
      showSuccessMsg: ""
    };
  }

  render() {
    let { name, onClose } = this.props;
    let {
      quantity,
      operation,
      reason,
      errorMessage,
      showSpinner,
      showSuccessMsg
    } = this.state;

    return (
      <Dialog>
        
        <Widget.DialogWrapper>
          <Title>{name} 新增進銷存</Title>
          {!showSuccessMsg ? (
            <>
              <Widget.FieldContainer
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                <Widget.FormField stacked>
                  <label>核銷: </label>
                  <select
                    value={operation}
                    onChange={event =>
                      this.setState({ operation: event.target.value })
                    }
                  >
                    <option value="add">add</option>
                    <option value="remove">remove</option>
                  </select>
                </Widget.FormField>

                <Widget.FormField stacked>
                  <label>數量: </label>
                  <input
                    type="number"
                    min="1"
                    value={quantity}
                    onChange={event =>
                      this.setState({ quantity: event.target.value })
                    }
                  />
                </Widget.FormField>

                <Widget.FormField stacked>
                  <LabelText>原因: </LabelText>
                  <select
                    value={reason}
                    onChange={event =>
                      this.setState({ reason: event.target.value })
                    }
                  >
                    <option value="none">none</option>
                    <option value="use">use</option>
                    <option value="sell">sell</option>
                  </select>
                </Widget.FormField>
              </Widget.FieldContainer>
              <div
                style={{
                  margin: 10,
                  display: "flex",
                  justifyContent: "flex-end"
                }}
              >
                {errorMessage && <Text err={true}>{errorMessage}</Text>}
                {showSpinner ? (
                  <Widget.Center>
                    <Widget.Spinner />
                  </Widget.Center>
                ) : (
                  <Widget.Row justify="center">
                    <Widget.Button className="white-button" onClick={onClose}>
                      取消
                    </Widget.Button>
                    <Widget.Button onClick={this._Submit}>新增</Widget.Button>
                  </Widget.Row>
                )}
              </div>
            </>
          ) : (
            <Widget.Center>
              <Text>{showSuccessMsg}</Text>
              <Widget.FlatButton onClick={onClose}>關閉</Widget.FlatButton>
            </Widget.Center>
          )}
        </Widget.DialogWrapper>
      </Dialog>
    );
  }

  _Submit = () => {
    let { id, appActions } = this.props;
    let { quantity, operation, reason } = this.state;

    return appActions.momCenter
      .createSparePartRecord(id, quantity, operation, reason)
      .then(() => this.setState({ showSpinner: true }))
      .then(() => this.setState({ showSuccessMsg: "新增成功！" }))
      .catch(() => this.setState({ errorMessage: "新增失敗，請再試一次！" }))
      .then(() => this.setState({ showSpinner: false }));
  };
}

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #777777;
  padding: 10px;
`;

const LabelText = styled.label`
  margin-right: 15px;
  width: 50px;
`;

const Text = styled.div`
  margin-bottom: 20px;
  color: ${props => props.err && "red"};
`;

export default connect(
  null,
  ActionCreator
)(SparePartAddRecordDialog);
