import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../../../src/ActionCreator";
import Dialog from "../../../src/Components/Dialog";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";

const Wrapper = styled.div`
  padding: 10px;
  position: relative;
`

const Close = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`

class MomCheckinoutRecordDetailDialog extends React.Component {
  constructor(props){
    super(props);
    let { mother } = this.props;
    let record = {
      mother: mother.id,
      type: 'check-in',
    }
    this.state = {
      record: record,
      showSpinner: false,
      errorMsg: false,
    }
  }

  render(){
    let { record, showSpinner, errorMsg } = this.state;
    let { requestClose } = this.props;
    console.log('record',record )
    return(
      <Dialog>
        <Wrapper>
          <Widget.FormField>
            <label>狀態:</label>
            <select value={record.type} onChange={e => this.setState({ record:{ ...record, type: e.target.value } })}>
              <option value='check-in'>check in</option>
              <option value='check-out'>check out</option>
            </select>
          </Widget.FormField>
          {
            showSpinner && <Widget.Spinner/>
          }
          {
            errorMsg && <div style={{color: 'red', fontWeight: 'bold'}}>請注意媽媽當下入住狀態!</div>
          }
          <Widget.Row>
              <Widget.Button onClick={this._confirmCreate}>確認新增</Widget.Button>
            </Widget.Row>
          <Close onClick={requestClose}>
            <Icon.Close />
          </Close>
        </Wrapper>
      </Dialog>
    )
  }

  _confirmCreate = () => {
    let {appActions,requestRefresh, requestClose } = this.props;
    let {record} = this.state;

    this.setState({showSpinner: true});
    appActions.momCenter.createMomCheckinoutRecord(record)
      .then(() => {
        this.setState({showSpinner: false});
        requestRefresh();
        requestClose();
      })
      .catch(err => {
        console.warn(err)
        this.setState({showSpinner: false, errorMsg: true})
      })
  }
}

export default connect(
  null, ActionCreator
)(MomCheckinoutRecordDetailDialog);