import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import ActionCreator from "../../../src/ActionCreator";
import Selectors from "../../../src/Selectors";
import Dialog from "../../../src/Components/Dialog";
import { d2s } from "../../../src/Utils/DateUtil";

const Wrapper = styled.div`
  padding: 10px;
  position: relative;
`;

const Close = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

const Error = styled.h3`
  color: red;
`;

class MomCaringRecordDetailDialog extends React.Component {
  constructor(props) {
    super(props);

    let { mother, record, appActions, users } = this.props;

    // dialog has two state: edit / create, depneds on whether record is null
    let isCreatView = record == null;
    let defaultRecord = {};

    if (isCreatView) {
      defaultRecord = {
        mother: mother.id,
        note: "",
        living_days: null,
        care_date: d2s(new Date()),
        staff: users[0].id
      };
    } else {
      defaultRecord = {
        ...record
      };
    }

    this.state = {
      record: defaultRecord,
      showSpinner: false,
      livingRecords: null,
      isCreatView,
      fromDate: null
    };
  }

  componentDidMount() {
    this._fetchMomLivingRecords();
  }

  render() {
    let { requestClose, users } = this.props;
    let { record, isCreatView, livingRecords } = this.state;

    return (
      <Dialog>
        <Wrapper>
          <Widget.FormField>
            <label>入住天數：</label>
            <label>{this.state.record.living_days}</label>
          </Widget.FormField>
          <Widget.FormField>
            <label>關懷日期：</label>
            <input
              type="date"
              value={d2s(record.care_date)}
              required
              onChange={e => {
                this.setState({
                  record: {
                    ...record,
                    care_date: e.target.value,
                    living_days: this._getLivingDate({
                      from: this.state.fromDate,
                      to: new Date(e.target.value)
                    })
                  }
                });
              }}
            />
          </Widget.FormField>
          <Widget.FormField>
            <label>關懷員工：</label>
            <select
              value={record.staff}
              onChange={e =>
                this.setState({ record: { ...record, staff: e.target.value } })
              }
            >
              {users.map(user => (
                <option key={user.id} value={user.id}>
                  {user.user.username}
                </option>
              ))}
            </select>
          </Widget.FormField>
          <Widget.FormField>
            <label>備註:</label>
            <input
              value={record.note}
              onChange={e =>
                this.setState({ record: { ...record, note: e.target.value } })
              }
            />
          </Widget.FormField>

          <div style={{ paddingLeft: 5, color: "red" }}>
            {this._getFormErrorHint()}
          </div>

          {isCreatView ? (
            <Widget.Row>
              <Widget.Button onClick={this._confirmCreate}>
                確認新增
              </Widget.Button>
            </Widget.Row>
          ) : (
            <Widget.Row>
              <Widget.Button onClick={this._confirmUpdate}>
                確認修改
              </Widget.Button>
            </Widget.Row>
          )}
          <Close onClick={requestClose}>
            <Icon.Close />
          </Close>
        </Wrapper>
      </Dialog>
    );
  }

  _fetchMomLivingRecords = () => {
    let { mother, appActions } = this.props;
    let { livingRecords, record } = this.state;

    this.setState({ showSpinner: true });
    appActions.momCenter
      .searchMomLivingRecords({ mother: mother.id })
      .then(livingRecords => {
        let lastRecord = livingRecords[livingRecords.length - 1];

        let living_days = this._getLivingDate({
          from: new Date(lastRecord.from_date),
          to: new Date(record.care_date)
        });

        this.setState({
          record: { ...this.state.record, living_days },
          showSpinner: false,
          fromDate: new Date(lastRecord.from_date),
          endDate: new Date(lastRecord.end_date)
        });
      })
      .catch(err => console.warn(err));
  };

  _confirmCreate = () => {
    let { appActions, requestClose, requestRefresh } = this.props;
    let { record } = this.state;

    if (this._getFormErrorHint()) {
      this.forceUpdate();
    }

    this.setState({ showSpinner: true });
    appActions.momCenter
      .creatMomCaringRecord(record)
      .then(() => this.setState({ showSpinner: false }))
      .then(requestClose)
      .then(requestRefresh)
      .catch(err => console.warn(err));
  };

  _confirmUpdate = () => {
    let { appActions, requestClose, requestRefresh } = this.props;
    let { record } = this.state;

    if (this._getFormErrorHint()) {
      this.forceUpdate();
    }

    this.setState({ showSpinner: true });
    appActions.momCenter
      .updateMomCaringRecord(record)
      .then(() => this.setState({ showSpinner: false }))
      .then(requestClose)
      .then(requestRefresh)
      .catch(err => console.warn(err));
  };

  _getLivingDate = ({ from, to }) => {
    if (!from || !to) {
      return "-";
    }

    let ret = parseInt((to.getTime() - from.getTime()) / (86400 * 1000));
    return ret < 0 ? "尚未入住" : ret;
  };

  _getFormErrorHint = () => {
    let { record, fromDate, endDate } = this.state;

    // care_date >= from_date
    if (fromDate && new Date(record.care_date).getTime() < fromDate.getTime()) {
      return `日期不能早於 ${d2s(fromDate)}`;
    }

    // care_date <= end_date
    if (endDate && new Date(record.care_date).getTime() > endDate.getTime()) {
      return `日期不能晚於 ${d2s(endDate)}`;
    }

    return null;
  };
}
export default connect(
  (state, ownProps) => ({
    users: Selectors.momCenter.getUsers(state)
  }),
  ActionCreator
)(MomCaringRecordDetailDialog);
