import React from "react";
import { connect } from "react-redux";
import ActionCreator from "../../../src/ActionCreator";
import styled from "styled-components";
import Dialog from "../../../src/Components/Dialog";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import { Absolute } from "../../../src/Components/Css";

class MomMealMenuDetailDialog extends React.Component {
  constructor(props) {
    super(props);

    let { record, mother, appActions } = this.props;
    let isCreateView = false;

    if (!record) {
      isCreateView = true;
      record = {
        mother: mother.id
      };
    }

    this.state = {
      isCreateView,
      record: { ...record }
    };

    this.createApi = appActions.momCenter.createMomMealMenu;
    this.updateApi = appActions.momCenter.updateMomMealMenu;
  }

  render() {
    let { onClose } = this.props;
    let { isCreateView, record, errorMessage } = this.state;

    return (
      <Dialog>
        <Widget.DialogWrapper>
          {isCreateView ? (
            <Widget.DialogTitle>新增膳食關懷紀錄</Widget.DialogTitle>
          ) : (
            <Widget.DialogTitle>修改膳食關懷紀錄</Widget.DialogTitle>
          )}
          <Widget.FieldContainer>
            <Widget.FormField stacked inputWidth={"440px"}>
              <label>預計關懷日期: </label>
              <input
                type="date"
                value={record.expect_care_date}
                onChange={event =>
                  this.setState({
                    record: {
                      ...record,
                      expect_care_date: event.target.value
                    }
                  })
                }
              />
            </Widget.FormField>

            <Widget.FormField stacked>
              <label>日期: </label>
              <input
                type="date"
                value={record.date}
                onChange={event =>
                  this.setState({
                    record: {
                      ...record,
                      date: event.target.value
                    }
                  })
                }
              />
            </Widget.FormField>

            <Widget.FormField stacked>
              <label>早餐: </label>
              <input
                value={record.breakfast}
                onChange={event =>
                  this.setState({
                    record: {
                      ...record,
                      breakfast: event.target.value
                    }
                  })
                }
              />
            </Widget.FormField>

            <Widget.FormField stacked>
              <label>午餐: </label>
              <input
                value={record.lunch}
                onChange={event =>
                  this.setState({
                    record: {
                      ...record,
                      lunch: event.target.value
                    }
                  })
                }
              />
            </Widget.FormField>

            <Widget.FormField stacked>
              <label>晚餐: </label>
              <input
                value={record.dinner}
                onChange={event =>
                  this.setState({
                    record: {
                      ...record,
                      dinner: event.target.value
                    }
                  })
                }
              />
            </Widget.FormField>

            <Widget.FormField stacked>
              <label>午點心: </label>
              <input
                value={record.bsnack_lunchoys}
                onChange={event =>
                  this.setState({
                    record: {
                      ...record,
                      snack_lunch: event.target.value
                    }
                  })
                }
              />
            </Widget.FormField>

            <Widget.FormField stacked>
              <label>晚點心: </label>
              <input
                value={record.snack_dinner}
                onChange={event =>
                  this.setState({
                    record: {
                      ...record,
                      snack_dinner: event.target.value
                    }
                  })
                }
              />
            </Widget.FormField>

            <Widget.FormField stacked>
              <label>關懷內容: </label>
              <input
                type="text"
                value={record.meal_caring_content}
                onChange={event =>
                  this.setState({
                    record: {
                      ...record,
                      meal_caring_content: event.target.value
                    }
                  })
                }
              />
            </Widget.FormField>

            <Widget.FormField stacked>
              <label>備註: </label>
              <input
                type="text"
                value={record.note}
                onChange={event =>
                  this.setState({
                    record: {
                      ...record,
                      note: event.target.value
                    }
                  })
                }
              />
            </Widget.FormField>
          </Widget.FieldContainer>
          {errorMessage && (
            <div style={{ color: "red", padding: "10px" }}>{errorMessage}</div>
          )}
          <div
            style={{ margin: 10, display: "flex", justifyContent: "flex-end" }}
          >
            {isCreateView ? (
              <Widget.Button onClick={this._submitCreate}>
                確認新增
              </Widget.Button>
            ) : (
              <Widget.Button onClick={this._submitEdit}>確認修改</Widget.Button>
            )}
            <div onClick={onClose}>
              <Widget.Button
                color={"#ffffff"}
                textColor={"#777777"}
                css={"border: 1px solid #DFDFDF"}
              >
                取消
              </Widget.Button>
            </div>
          </div>
        </Widget.DialogWrapper>
      </Dialog>
    );
  }

  _submitCreate = () => {
    let { requestUpdate, onClose } = this.props;
    let { record } = this.state;

    return this.createApi(record)
      .then(onClose)
      .then(requestUpdate)
      .catch(() => this.setState({ errorMessage: "新增失敗！請再試一次！" }));
  };

  _submitEdit = () => {
    let { requestUpdate, onClose } = this.props;
    let { record } = this.state;

    return this.updateApi(record)
      .then(onClose)
      .then(requestUpdate)
      .catch(() => this.setState({ errorMessage: "修改失敗！請再試一次！" }));
  };
}

export default connect(
  null,
  ActionCreator
)(MomMealMenuDetailDialog);
