import React from "react";
import styled from "styled-components";
import moment from "moment";
import { connect } from "react-redux";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import Selectors from "../../../src/Selectors";
import ActionCreator from "../../../src/ActionCreator";
import ConfirmDialog from "./ConfirmDialog";
import RepairmentRecordDetailDialog from "./RepairmentRecordDetailDialog";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  position: relative;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  color: #777777;
`;

const Status = styled.div`
  display: flex;
  align-items: center;

  & > .round {
    margin-right: 10px;
    border-radius: 50px;
    width: 15px;
    height: 15px;
    background-color: ${props => props.color || "gray"};
  }

  & > .label {
    margin-right: ${props => (props.last ? "5" : "30")}px;
    color: #777777;
    font-size: 14px;
  }
`;

const RepairmentRecord = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
  border-radius: 6px;
  padding: 0px 5px;
  min-width: 800px;
  background-color: ${props => props.bgColor || "#f1ba3e"};
  font-weight: thin;
  font-size: 14px;
  color: #777777;
`;

class RepairmentHistoryRecordList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records: null,
      showSpinner: false,
      showConfirmDialog: false,
      showDetailDialog: false,
      selectedRecord: null
    };
  }

  componentDidMount() {
    this._fetchRecords();
  }

  render() {
    let {
      showSpinner,
      records,
      selectedRecord,
      showConfirmDialog,
      showDetailDialog
    } = this.state;
    let { goBack, room, appActions } = this.props;

    return (
      <Wrapper>
        {showSpinner ? (
          <Widget.Spinner />
        ) : (
          <>
            <Widget.Row align="center">
              <Title>
                房號
                {room.name} 修繕歷史紀錄
              </Title>
              <Widget.Button
                className="white-button"
                css="width: 100px; margin-left: 20px;"
                onClick={goBack}
              >
                返回管理
              </Widget.Button>
            </Widget.Row>

            <Widget.Row justify="flex-end" css="margin: 10px 0px;">
              <Status color="#F3C7AE">
                <div className="round" />
                <div className="label">未完成</div>
              </Status>
              <Status color="#B0CFDB">
                <div className="round" />
                <div className="label">處理中</div>
              </Status>
              <Status color="#E6E6E6" last>
                <div className="round" />
                <div className="label">已完成</div>
              </Status>
            </Widget.Row>

            <Widget.Table>
              <Widget.TableContent>
                <Widget.Fields css="padding: 0px 15px; min-width: 800px;">
                  <div className="field">物品</div>
                  <div className="field">登記日期</div>
                  <div className="field">修繕日期</div>
                  <div className="field">修繕時間</div>
                  <div className="field" style={{ width: 200 }}>
                    備註
                  </div>
                  <div className="field">進度</div>
                  <div className="field-padding" />
                  <div className="field" />
                  <div className="field" />
                </Widget.Fields>

                {records &&
                  records.map((record, i) => (
                    <>
                      <RepairmentRecord
                        key={i}
                        bgColor={(() => {
                          if (record.status === "processing") {
                            return "#B0CFDB";
                          } else if (record.status === "completed") {
                            return "#E6E6E6";
                          }
                          return "#F3C7AE";
                        })()}
                      >
                        <div className="row-item">{record.summary}</div>
                        <div className="row-item">
                          {moment(record.created).format("YYYY-MM-DD")}
                        </div>
                        <div className="row-item">{record.estimated_date}</div>
                        <div className="row-item">{record.estimated_time}</div>
                        <div className="row-item" style={{ width: 200 }}>
                          {record.detail}
                        </div>
                        <div className="row-item">{record.status}</div>
                        <div className="row-item-padding" />

                        <div className="row-item">
                          <div
                            style={{
                              cursor: "pointer",
                              color: "#4485D1",
                              display: "flex",
                              alignItems: "center"
                            }}
                            css="width: 30px;"
                            onClick={() =>
                              this.setState({
                                showDetailDialog: true,
                                selectedRecord: record
                              })
                            }
                          >
                            <Icon.Edit color="#4485D1" />
                            編輯
                          </div>
                        </div>

                        <div className="row-item">
                          <div
                            style={{
                              cursor: "pointer",
                              color: "#4485D1",
                              display: "flex",
                              alignItems: "center"
                            }}
                            css="width: 30px;"
                            onClick={() =>
                              this.setState({
                                showConfirmDialog: true,
                                selectedRecord: record
                              })
                            }
                          >
                            <Icon.Delete color="#4485D1" />
                            刪除
                          </div>
                        </div>
                      </RepairmentRecord>
                    </>
                  ))}

                {showConfirmDialog && (
                  <ConfirmDialog
                    text="確定要刪除嗎？此動作無法還原。"
                    record={selectedRecord}
                    requestClose={() =>
                      this.setState({ showConfirmDialog: false })
                    }
                    confirmAction={() => {
                      appActions.momCenter
                        .deleteRepairment(selectedRecord.id)
                        .then(this._fetchRecords)
                        .then(() => this.setState({ showConfirmDialog: false }))
                        .catch(err => console.warn(err));
                    }}
                  />
                )}

                {showDetailDialog && (
                  <RepairmentRecordDetailDialog
                    record={selectedRecord}
                    requestClose={() =>
                      this.setState({ showDetailDialog: false })
                    }
                    requestRefresh={this._fetchRecords}
                  />
                )}
              </Widget.TableContent>
            </Widget.Table>
          </>
        )}
      </Wrapper>
    );
  }

  _fetchRecords = () => {
    let { appActions, room } = this.props;

    this.setState({ showSpinner: true });
    appActions.momCenter
      .fetchRepairmentRecord(room.id)
      .then(records => this.setState({ records }))
      .then(() => this.setState({ showSpinner: false }))
      .catch(err => console.warn(err));
  };
}

export default connect(
  null,
  ActionCreator
)(RepairmentHistoryRecordList);
