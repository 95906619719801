import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Selectors from "../../../src/Selectors";
import ActionCreator from "../../../src/ActionCreator";
import { withPage } from "../../../src/Page";
import * as Widget from "../../../src/Components/Widget2";
import Dashboard from "./Dashboard";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

class ProfilePage extends React.Component {
  render() {
    let { profile } = this.props;

    if (profile) {
      return <Dashboard />;
    }

    return (
      <Wrapper>
        <Widget.Spinner />
      </Wrapper>
    );
  }
}

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state)
    }),
    ActionCreator
  )(ProfilePage)
);
