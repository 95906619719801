import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as Widget from "../../../src/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import ActionCreator from "../../../src/ActionCreator";
import Dialog from "../../../src/Components/Dialog";
import Selectors from "../../../src/Selectors";

const Wrapper = styled.div`
  padding: 10px;
  position: relative;
`;

const Close = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

class BabyLivingRecordDetailDialog extends React.Component {
  constructor(props) {
    super(props);

    let { record, selectedBaby, incubators } = this.props;
    let isCreateView = false;

    if (!record) {
      isCreateView = true;
      record = {
        incubator: incubators[0].id,
        baby: selectedBaby.id,
        from_date: "",
        duration: "",
        note: ""
      };
    } else {
      record = {
        id: record.id,
        incubator: record.incubator.id,
        baby: record.baby.id,
        from_date: record.from_date,
        duration: record.duration,
        note: record.note
      };
    }

    this.state = {
      record: record,
      showSpinner: false,
      errMessage: "",
      isCreateView
    };
  }

  render() {
    let { requestClose, incubators } = this.props;
    let { record, showSpinner, isCreateView, errMessage } = this.state;

    return (
      <Dialog>
        <Wrapper>
          <Widget.FormField>
            <label>保溫箱:</label>
            <select
              value={record.incubator}
              onChange={e =>
                this.setState({
                  record: { ...record, incubator: e.target.value }
                })
              }
            >
              {incubators &&
                incubators.map(incubator => (
                  <option key={incubator.id} value={incubator.id}>
                    {incubator.incubator_type.name} - {incubator.name}
                  </option>
                ))}
            </select>
          </Widget.FormField>

          <Widget.FormField>
            <label>起始日期:</label>
            <input
              type="date"
              value={record.from_date}
              onChange={e =>
                this.setState({
                  record: { ...record, from_date: e.target.value }
                })
              }
            />
          </Widget.FormField>

          <Widget.FormField>
            <label>天數:</label>
            <input
              type="number"
              value={record.duration}
              onChange={e =>
                this.setState({
                  record: { ...record, duration: e.target.value }
                })
              }
            />
          </Widget.FormField>

          <Widget.FormField>
            <label>備註:</label>
            <textarea
              value={record.note}
              onChange={e =>
                this.setState({
                  record: { ...record, note: e.target.value }
                })
              }
            />
          </Widget.FormField>

          {errMessage && <p style={{ color: "red" }}>{errMessage}</p>}

          {showSpinner ? (
            <Widget.Spinner />
          ) : isCreateView ? (
            <Widget.Button onClick={this._confirmCreate}>
              確認新增
            </Widget.Button>
          ) : (
            <Widget.Button onClick={this._confirmUpdate}>
              確認修改
            </Widget.Button>
          )}

          <Close onClick={requestClose}>
            <Icon.Close />
          </Close>
        </Wrapper>
      </Dialog>
    );
  }

  _confirmUpdate = () => {
    let { appActions, requestRefresh, requestClose } = this.props;
    let { record } = this.state;

    this.setState({ showSpinner: true });
    appActions.momCenter
      .updateBabyLivingRecord(record)
      .then(() => this.setState({ showSpinner: false }))
      .then(requestRefresh)
      .then(requestClose)
      .catch(err => {
        if (err.status === 400) {
          return this.setState({
            errMessage: "入住時間重複",
            showSpinner: false
          });
        } else {
          return this.setState({
            errMessage: "發生錯誤，請再試一次",
            showSpinner: false
          });
        }
      });
  };

  _confirmCreate = () => {
    let { appActions, requestRefresh, requestClose } = this.props;
    let { record } = this.state;

    this.setState({ showSpinner: true });
    appActions.momCenter
      .createBabyLivingRecord(record)
      .then(() => this.setState({ showSpinner: false }))
      .then(requestRefresh)
      .then(requestClose)
      .catch(err => {
        if (err.status === 400) {
          return this.setState({
            errMessage: "入住時間重複",
            showSpinner: false
          });
        } else {
          return this.setState({
            errMessage: "發生錯誤，請再試一次",
            showSpinner: false
          });
        }
      });
  };
}
export default connect(
  (state, ownProps) => ({
    incubators: Selectors.momCenter.getIncubators(state)
  }),
  ActionCreator
)(BabyLivingRecordDetailDialog);
